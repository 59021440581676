import React, { useEffect, useState, useRef } from 'react'

import { AiOutlinePrinter, AiOutlineSearch } from 'react-icons/ai'
import moment from 'moment'
import {
  Box,
  HStack,
  Text,
  Icon,
  VStack,
  Flex,
  InputRightElement,
} from '@chakra-ui/react'
import ReactToPrint, { useReactToPrint } from 'react-to-print'
import CustomButton from '@/components/Button'
import { TextInput } from '@/components/Input'
import DataTable from '@/components/DataTable'
import { createColumnHelper } from '@tanstack/react-table'

interface IActivities {
  activities: IActivity
  isLoading: boolean
  isSuccess: boolean
  handleFilter: (value: string) => void
  handleSetDateRange: (value: string | any) => void
  handleActivityPageChange: (page: number) => void
}

function ActivityPage({
  activities,
  isLoading,
  isSuccess,
  handleFilter,
  handleSetDateRange,
  handleActivityPageChange,
}: IActivities) {
  const [search, setSearch] = useState<string>('')
  const [dates, setDates] = useState<Date | any>({
    created_at_after: '',
    created_at_before: '',
  })

  let componentRef = useRef(null)

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  })

  const handleSearch = (event: any) => {
    setSearch(event.target.value)
  }
  useEffect(() => handleFilter(search), [search])
  useEffect(() => handleSetDateRange(dates), [dates])

  const handleSelectDates = (e: any) => {
    setDates({ ...dates, [e.target.name]: e.target.value })
  }

  const columnHelper = createColumnHelper<IActivityLog>()
  const columns = React.useMemo(
    () => [
      columnHelper.accessor('created_at', {
        header: 'Date',
        cell: info => moment(info.getValue()).format('YYYY-MMMM-Do'),
      }),
      columnHelper.accessor('time', {
        header: 'Time',
        cell: ({ row: { original: data } }: any) =>
          moment(data.created_at).format('h:mm:ss A'),
      }),
      columnHelper.accessor('description', {
        header: 'Description',
        cell: info => info.getValue(),
      }),
    ],
    []
  )

  return (
    <Box>
      <HStack justifyContent="space-between">
        <Box>
          <Text fontSize="1.25rem" fontWeight="medium">
            Activity Log
          </Text>
        </Box>
        <CustomButton
          w="6.625rem"
          h="2.75rem"
          mt="2.688rem"
          borderRadius="5px"
          fontSize="0.938rem"
          fontWeight="medium"
          onClick={handlePrint}
        >
          <Icon mr="0.875rem" as={AiOutlinePrinter} />
          Print
        </CustomButton>
      </HStack>
      <Box ref={componentRef}>
        <VStack>
          <Flex
            mt="1.25rem"
            mb="1.25rem"
            gap="2.3rem"
            flexDirection={['column', 'row']}
            alignItems={['center', '']}
          >
            <TextInput
              labelProps={{
                fontSize: '0.88rem',
                fontWeight: 'medium',
                mb: '0.2rem',
              }}
              formControlProps={{
                maxW: '20.4rem',
              }}
              TextInputProps={{
                h: '2.188rem',
                borderRadius: '2.3px',
              }}
              placeholder="Search"
              type="search"
              value={search}
              handleChange={(e: any) => handleSearch(e)}
              TextInputElement={
                <InputRightElement>
                  <AiOutlineSearch />
                </InputRightElement>
              }
            />
          </Flex>
        </VStack>
        <Flex
          alignItems="center"
          gap={'2rem'}
          justifyContent="center"
          flexDir={['column', 'row']}
        >
          <Flex
            mt="1.25rem"
            mb="1.25rem"
            gap="2.3rem"
            flexDirection={['column', 'row']}
          >
            <TextInput
              labelProps={{
                fontSize: '0.88rem',
                fontWeight: 'medium',
                mb: '0.2rem',
              }}
              formControlProps={{
                maxW: '10.1rem',
              }}
              TextInputProps={{
                h: '2.188rem',
                borderRadius: '2.3px',
                onFocus: e => {
                  e.target.type = 'date'
                },
                onBlur: e => {
                  e.target.type = 'text'
                },
              }}
              placeholder="From"
              type="text"
              name="created_at_after"
              value={dates.name}
              handleChange={(e: any) => handleSelectDates(e)}
            />
            <TextInput
              labelProps={{
                fontSize: '0.88rem',
                fontWeight: 'medium',
                mb: '0.2rem',
              }}
              formControlProps={{
                maxW: '10.1rem',
              }}
              TextInputProps={{
                h: '2.188rem',
                borderRadius: '2.3px',
                onFocus: e => {
                  e.target.type = 'date'
                },
                onBlur: e => {
                  e.target.type = 'text'
                },
              }}
              placeholder="To"
              type="text"
              name="created_at_before"
              value={dates.name}
              handleChange={(e: any) => handleSelectDates(e)}
            />
          </Flex>
        </Flex>
        <Box mb="2.2rem"></Box>
        <DataTable
          isLoading={isLoading}
          variant="lg-table"
          columns={columns}
          data={activities.rows}
          totalPages={activities.total_pages}
          handlePageChange={handleActivityPageChange}
          isAllowSelection={false}
          showPagination={false} // Will come back to fix what @umarah did on pagination, that is not working, i off it for now
        />
      </Box>
    </Box>
  )
}

export default ActivityPage
