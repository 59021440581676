import {
  Dispatch,
  SetStateAction,
  useCallback,
  useState,
  useEffect,
} from 'react'
import { useQuery, useQueryClient } from 'react-query'

import { axiosInstance } from '@/lib/helpers'
import { queryKeys } from '@/lib/react-query/constants'
import { useDebounce } from '@/hooks/useDebounce'

// common options for both useQuery and prefetchQuery
const commonOptions = {
  staleTime: 0,
  cacheTime: 300000, // 5 minutes
}

async function getUsers(options: any): Promise<any[]> {
  const {
    data: { data },
  } = await axiosInstance.get('/organization/admin', { params: options })
  return data
}

interface useUser {
  users: IUserList
  isLoading: boolean
  activePage: number
  setActivePage: Dispatch<SetStateAction<number>>
  filter: string
  setFilter: Dispatch<SetStateAction<string>>
  role: string
  setRole: Dispatch<SetStateAction<string>>
  setSearchTerm: Dispatch<SetStateAction<string>>
  searchTerm: string
}

const filterCandidates = (data: ICandidateList, filter: string) => {
  if (!filter) return data
  return {
    ...data,
    rows: data.rows.filter(candidate =>
      candidate.name.toLowerCase().includes(filter.toLowerCase())
    ),
  }
}

export function useUser(): useUser {
  const queryClient = useQueryClient()

  const [filter, setFilter] = useState<string>('')
  const [activePage, setActivePage] = useState<number>(1)
  const [role, setRole] = useState<string | any>()
  const [searchTerm, setSearchTerm] = useState<string>('')

  const debouncedSearchTerm = useDebounce(searchTerm, 500)

  useEffect(() => {
    const nextPage = activePage + 1
    queryClient.prefetchQuery(
      [queryKeys.users, nextPage],
      () => getUsers({ page: nextPage, role }),
      commonOptions
    )
  }, [queryClient, role, activePage])

  useEffect(() => {
    setFilter('')
  }, [role])

  const selectFn = useCallback(data => filterCandidates(data, filter), [filter])

  const fallback = {
    count: 0,
    received: 0,
    total_pages: 0,
    current_page: 0,
    rows: [],
  } as any

  const { data: users = fallback, isLoading } = useQuery(
    [queryKeys.users, role, activePage, debouncedSearchTerm, filter],
    () => getUsers({ page: activePage, role, search: debouncedSearchTerm }),
    {
      ...commonOptions,
      // enabled: !!group,
      refetchOnMount: true,
      refetchOnReconnect: true,
      refetchOnWindowFocus: true,
      select: !filter ? undefined : selectFn,
    }
  )

  return {
    users,
    isLoading,
    activePage,
    setActivePage,
    filter,
    setFilter,
    role,
    setRole,
    setSearchTerm,
    searchTerm,
  }
}
