import React from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Box,
  Center,
  Text,
  Flex,
} from '@chakra-ui/react'
import moment from 'moment-timezone'

import CustomButton from '@/components/Button'

interface IExamModal {
  isOpen: boolean
  onClose: () => void
  onClick: () => void
  data: IExam | any
}
type Status = 'expired' | 'active' | 'upcoming'

export default function ExamUserModal({
  data,
  isOpen,
  onClose,
  onClick,
}: IExamModal) {
  if (!data) return <></>
  const {
    status,
    name,
    total_questions,
    duration,
    timezone,
    start_date,
    end_date,
    currentOrganizationName,
  } = data

  const modalSize: any = {
    sm: '319px',
    base: '700px',
    md: '600px',
    lg: '1200px',
  }

  const getTimeDateByStatus = (status: Status) => {
    switch (status) {
      case 'expired':
        return end_date
      case 'active':
        return end_date
      case 'upcoming':
        return start_date
      default:
        return end_date
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      size={['sm', 'base', 'md', 'lg']}
    >
      <ModalOverlay onClick={onClose} />
      <ModalContent p="0.6rem" maxW={modalSize}>
        <ModalHeader
          borderBottom="1px solid black"
          fontSize={['1.2rem', '1.5rem']}
          fontWeight="bold"
          color="primary"
          textAlign="center"
          h="5rem"
        >
          <Text>{currentOrganizationName}</Text>
          {status === 'expired' && (
            <ModalCloseButton _focus={{ focus: 'none' }} onClick={onClose} />
          )}
        </ModalHeader>
        <ModalBody>
          {status === 'active' ? (
            <>
              <Center>
                <Text
                  fontSize={['0.8rem', '0.8rem', '1.5rem', '1.5rem']}
                  fontWeight="medium"
                  margin={['0.8rem 0', '2.8rem 0']}
                >
                  {name} Examination
                </Text>
              </Center>
              <Box
                as="div"
                maxW="33.7rem"
                m="0 auto"
                fontSize={['0.6rem', '0.6rem', '1.13rem', '1.13rem']}
                fontWeight="medium"
              >
                <Flex
                  display={['none', 'flex']}
                  justifyContent="space-between"
                  mb={['0.4rem', '0.813rem']}
                >
                  <Text>Active Time:</Text>
                  <Text>
                    {moment(start_date).tz(timezone).format('hh:mm a')}
                  </Text>
                </Flex>
                <Flex
                  display="flex"
                  justifyContent="space-between"
                  mb={['0.4rem', '0.813rem']}
                >
                  <Text>Total Questions:</Text>
                  <Text>{total_questions}</Text>
                </Flex>
                <Flex
                  display="flex"
                  justifyContent="space-between"
                  mb={['0.4rem', '0.813rem']}
                >
                  <Text>Time Limit:</Text>
                  <Text>{duration} Minutes</Text>
                </Flex>
                <Center>
                  <CustomButton
                    mt={['1.2rem', '3.75rem']}
                    maxW={{ base: '9rem', md: '30rem' }}
                    onClick={onClick}
                    fontSize="1rem"
                    fontWeight={'medium'}
                    h="2.5rem"
                    borderRadius={'5px'}
                  >
                    Start
                  </CustomButton>
                </Center>
              </Box>
            </>
          ) : status === 'upcoming' ? (
            <Box
              maxW="34.563rem"
              m="0 auto"
              fontSize={['0.8rem', '1.13rem']}
              fontWeight="medium"
            >
              <Center>
                <Text
                  mt={['1rem', '3rem']}
                  textAlign="center"
                  fontSize={['1rem', '1.5rem']}
                  fontWeight="normal"
                >
                  Sorry, This Exam has not been activated yet. Please check back
                  on{' '}
                  <Text color="secondary" as="span">
                    {moment(start_date).tz(timezone).format('Do-MMMM-YYYY')}{' '}
                    {moment(start_date).tz(timezone).format('hh:mm a')}
                  </Text>
                </Text>
              </Center>
              <Center>
                <CustomButton
                  onClick={onClose}
                  maxW={{ base: '9rem', md: '28rem' }}
                  mt={['1rem', '3.75rem']}
                  fontSize="1rem"
                  fontWeight={'medium'}
                  h="2.5rem"
                  borderRadius={'5px'}
                >
                  Ok
                </CustomButton>
              </Center>
            </Box>
          ) : (
            <Box
              maxW="34.563rem"
              m="0 auto"
              fontSize={['0.8rem', '1.13rem']}
              fontWeight="medium"
            >
              <Center>
                <Text
                  mt={['1rem', '3rem']}
                  textAlign="center"
                  fontSize={['1rem', '1.3rem']}
                  fontWeight="normal"
                >
                  Sorry, This Exam has expired. Expired On{' '}
                  <Text color="secondary" as="span">
                    {moment(end_date).format('Do-MMMM-YYYY')}{' '}
                    {moment(end_date).format('hh:mm a')}
                  </Text>
                </Text>
              </Center>
              <Center>
                <CustomButton
                  onClick={onClose}
                  maxW={{ base: '9rem', md: '28rem' }}
                  mt={['1rem', '3.75rem']}
                  fontSize="1rem"
                  fontWeight={'medium'}
                  h="2.5rem"
                  borderRadius={'5px'}
                >
                  Ok
                </CustomButton>
              </Center>
            </Box>
          )}
        </ModalBody>

        <ModalFooter></ModalFooter>
      </ModalContent>
    </Modal>
  )
}
