import { Box, useColorMode, BoxProps } from '@chakra-ui/react'
import BarChart from './components/BarChart'
import PieChart from './components/PieChart'
import { bgThemeColor, primaryThemeColor } from '@/lib/constants/colorConstants'

type DataSetObj = {
  label: string
  data: number[]
  backgroundColor: string | string[]
  borderRadius?: number
  barThickness?: number
}

type option = {
  responsive: boolean
  plugins: {
    legend: {
      display: boolean
      position: string
      fullSize: boolean
      labels?: {
        boxWidth: number
      }
    }
  }
}
type style = {
  maxHeight?: string
  minHeigth?: string
}

interface IChartBox {
  chartStyle?: style
  typeofChart: string
  chartData: {
    labels: string[] | any[]
    datasets: DataSetObj[]
  }
  noOfUsers?: number
  title?: string
  options?: option
}

export default function ChartBox(props: IChartBox) {
  return (
    <>
      {props.typeofChart === 'barchart' && (
        <BarChart dataChart={{ ...props }} chartStyle={props.chartStyle} />
      )}
      <>
        {props.typeofChart === 'piechart' && (
          <PieChart chartOptions={props.options} dataChart={{ ...props }} />
        )}
      </>
    </>
  )
}

export const WrapBox = (props: BoxProps) => {
  const { colorMode } = useColorMode()

  return (
    <>
      <Box
        borderLeft={`10px solid ${primaryThemeColor[colorMode]}`}
        maxW="full"
        borderRadius="10px"
        boxShadow="0px 5.11828px 12.7957px rgba(0, 0, 0, 0.05)"
        overflow="auto"
        bg={bgThemeColor[colorMode]}
        {...props}
      >
        {props.children}
      </Box>
    </>
  )
}
