import {
  AiOutlineBook,
  AiOutlineInfoCircle,
  AiOutlineQuestion,
  AiOutlineSetting,
  AiOutlineUserAdd,
} from 'react-icons/ai'
import { FaUserGraduate } from 'react-icons/fa'

export const quickActions = [
  {
    id: 1,
    title: 'Create Exams',
    icon: AiOutlineBook,
    color: '#FF00C7',
    path: '/exams/create',
  },
  {
    id: 2,
    title: 'Add Candidates',
    icon: FaUserGraduate,
    color: '#00FF38',
    path: '/candidates/create',
  },
  {
    id: 3,
    title: 'Add New Users',
    icon: AiOutlineUserAdd,
    color: '#009BFF',
    path: '/users/create',
  },
  {
    id: 4,
    title: 'Upload Questions',
    icon: AiOutlineQuestion,
    color: '#E1E50E',
    path: '/question-bank',
  },
  {
    id: 5,
    title: 'Create new roles',
    icon: AiOutlineInfoCircle,
    color: '#D31717',
    path: '/roles/create',
  },
  {
    id: 6,
    title: 'Log',
    icon: AiOutlineBook,
    color: '#008B94',
    path: '/activity',
  },
  {
    id: 7,
    title: 'Personalize View',
    icon: AiOutlineSetting,
    color: '#3622B2',
    path: '/settings/personalize',
  },
]
