import React from 'react'
import { Box, Flex, Center, Text, HStack, Divider } from '@chakra-ui/react'
import { AiOutlineCheck } from 'react-icons/ai'

import ExamTime from './ExamTime'
import QuestionTag from './QuestionTag'
import TitleQuestionTime from './TitleQuestionTime'

type Status = 'expired' | 'active' | 'upcoming'

type IExamCardStatus = {
  data: IExam
  status: Status
  handleSelectExam: (e: any) => void
  isExamCompleted?: boolean
}

export default function ExamStatusCard({
  data,
  status,
  isExamCompleted,
  handleSelectExam,
}: IExamCardStatus) {
  const {
    name,
    question_categories,
    question_types,
    duration,
    total_questions,
    start_date,
    end_date,
    timezone,
  } = data

  const getTimeDateByStatus = (status: Status) => {
    switch (status) {
      case 'expired':
        return end_date
      case 'active':
        return end_date
      case 'upcoming':
        return start_date
      default:
        return end_date
    }
  }

  return (
    <Box
      maxW={'31.75rem'}
      p="1rem"
      border={status === 'active' ? '1px solid #009BFF' : '1px solid black'}
      borderRadius="10px"
      position="relative"
      overflow="auto"
      cursor={isExamCompleted ? 'default' : 'pointer'}
      onClick={handleSelectExam}
    >
      <Flex justifyContent="space-between" columnGap="0.2rem">
        <Box>
          <TitleQuestionTime
            course={name}
            numOfQuestion={total_questions}
            time={duration}
          />
          <Box display={['block', 'block', 'block', 'none']}>
            <ExamTime
              status={status}
              timeDate={getTimeDateByStatus(status)}
              timezone={timezone}
              startDate={start_date}
              expireDate={end_date}
            />
          </Box>
        </Box>
        <Box display={['none', 'none', 'none', 'block']}>
          <ExamTime
            status={status}
            timeDate={getTimeDateByStatus(status)}
            timezone={timezone}
            startDate={start_date}
            expireDate={end_date}
          />
        </Box>
      </Flex>
      <Flex
        display={['none', 'none', 'none', 'flex']}
        flexDir="column"
        w="full"
      >
        <QuestionTag title="Categories:" data={question_categories} />
        <QuestionTag title="Types:" data={question_types} />
      </Flex>

      {isExamCompleted && <BasicOverLay />}
    </Box>
  )
}

function BasicOverLay() {
  return (
    <>
      <Box
        w="100%"
        h="100%"
        position="absolute"
        top={0}
        left={0}
        background="rgba(255,255,255, 0.8)"
      >
        <Flex
          flexDir="column"
          display="flex"
          textAlign="center"
          justifyContent="center"
          alignItems="center"
          top={0}
          left={0}
          transform="translateY(50%)"
        >
          <Center
            w="3.75rem"
            h="3.75rem"
            background="#049650"
            borderRadius="full"
          >
            <AiOutlineCheck color="#fff" size="2.4rem" />
          </Center>
          <Text fontSize={['1rem', '1.375rem']} fontWeight="medium">
            Concluded!!
          </Text>
        </Flex>
      </Box>
    </>
  )
}
