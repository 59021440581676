import { ListItem } from '@chakra-ui/react'

export const PictureFormat = (PictureProps: { title: string; id: number }) => {
  const { title, id } = PictureProps
  return (
    <>
      <ListItem key={id} mb="0.5rem">
        {title}
      </ListItem>
    </>
  )
}
