import { SideBarOptionMenu } from '@/types/sidebar'
import { TbCheckupList } from 'react-icons/tb'

export const transactionsMenu: SideBarOptionMenu = {
  name: 'Transactions',
  icon: TbCheckupList,
  path: '/transactions',
  order: 1,
  permission: { name: 'settings', can: 'visible' },
  isShown: true,
  isActive: false,
  child: [],
}
