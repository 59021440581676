import React from 'react'
import { Text } from '@chakra-ui/react'

interface ICountdownFuture {
  date: string | Date
}
let interval: any = null

function CountdownFuture({ date }: ICountdownFuture) {
  const [time, setTime] = React.useState<any>(new Date(date))

  React.useEffect(() => {
    interval = setInterval(() => {
      setTime((prev: any) => prev - 1)
      if (time <= 0) {
        clearInterval(interval)
      }
    }, 1000)

    return () => {
      clearInterval(interval)
    }
  }, [])

  const [days, hours, mins, secs] = React.useMemo(() => {
    const formattedGivenDate = new Date(time) as any
    const today = new Date() as any
    const msDiff = formattedGivenDate - today
    const days = parseInt((msDiff / (24 * 3600 * 1000)) as any)
    const hours = parseInt((msDiff / (3600 * 1000) - days * 24) as any)
    const mins = parseInt(
      (msDiff / (60 * 1000) - days * 24 * 60 - hours * 60) as any
    )
    const secs = parseInt(
      (msDiff / 1000 - mins * 60 - days * 24 * 60 * 60 - hours * 60 * 60) as any
    )
    if (days <= 0 && hours <= 0 && mins <= 0 && secs <= 0) {
      clearInterval(interval)

      return [0, 0, 0, 0]
    }

    return [days, hours, mins, secs]
  }, [time])
  return (
    <>
      <Text
        display={['none', 'none', 'none', 'block']}
        color="primary"
        fontSize={['1rem', '1.125rem']}
        fontWeight="medium"
      >
        <Text as="span">
          {days}D:{hours}Hr:{mins}M:{secs}S
        </Text>
      </Text>
    </>
  )
}

export default CountdownFuture
