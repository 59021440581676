import { adminsMenu } from './Admins'
import { appMenu } from './Dashboard'
import { userDashboardMenu } from './UserDashboard'
import { userResultMenu } from './UserResult'
import { settingsMenu } from './Settings'
import { examsAdminMenu } from './ExamsAdmin'
import { questionBankMenu } from './QuestionBank'
import { usersMenu } from './Users'
import { rolesMenu } from './Roles'
import { activityMenu } from './Activity'
import { candidatesMenu } from './Candidates'
import { revenuMenu } from './Revenue'
import { notificationMenu } from './Notification'
import { unitMenu } from './Unit'
import { transactionsMenu } from './Transactions'
import { totalAdminMenu } from './TotalAdmin'

let hostname: string

if (typeof window !== 'undefined') {
  hostname = window.location.host.split('.')[0]
}

export const useSideBarMenuOptions = () => {
  if (hostname === 'app') {
    return {
      appMenu,
      unitMenu,
      examsAdminMenu,
      candidatesMenu,
      questionBankMenu,
      usersMenu,
      rolesMenu,
      activityMenu,
      transactionsMenu,
      settingsMenu,
      adminsMenu,
      revenuMenu,
      notificationMenu,
      totalAdminMenu,
    }
  }

  return {
    userDashboardMenu,
    userResultMenu,
  }
}

export const SideBarMenuOptions = useSideBarMenuOptions()
