import { SideBarOptionMenu } from '@/types/sidebar'
import { FaQuestion } from 'react-icons/fa'

export const questionBankMenu: SideBarOptionMenu = {
  name: 'Question Bank',
  icon: FaQuestion,
  path: '/question-bank',
  order: 1,
  permission: { name: 'question-bank', can: 'visible' },
  isShown: true,
  isActive: false,
  child: [],
}
