import { NUMBER_REGEX } from '../regexConstants'

export default [
  {
    name: 'amount',
    label: 'Amount',
    errorMessage: 'Enter amount',
    required: true,
    type: 'number',
    pattern: NUMBER_REGEX,
  },
]
