import React from 'react'
import PaygTab from './PaygTab'

export default function Payg() {
  return (
    <>
      <PaygTab />
    </>
  )
}
