import React from 'react'
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  FormLabelProps,
  Select,
  SelectProps,
  Box,
  useColorMode,
  FormErrorIcon,
  FormControlProps,
  Tooltip,
} from '@chakra-ui/react'
import LEColorConstants from '@/lib/constants/colorConstants'
import { InfoIcon } from '@chakra-ui/icons'

export type DropdownSelectOption = {
  label: string
  value: string
  style?: any
  disabled?: boolean
}

interface DropdownSelectProp {
  size?: string
  label?: string
  options: DropdownSelectOption[]
  onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void
  inputId?: string
  labelProps?: FormLabelProps
  formControlProps?: FormControlProps
  selectProps?: SelectProps
  error?: string
  extraLabel?: string
  name?: string
  placeholder?: string
  selected?: string
  info?: string
  noValue?: boolean
}

const DropdownSelect = ({
  size = 'md',
  label,
  options,
  onChange,
  inputId = '',
  labelProps,
  selectProps,
  error,
  extraLabel,
  formControlProps,
  name,
  placeholder,
  selected,
  noValue = true,
  info,
}: DropdownSelectProp) => {
  const { colorMode } = useColorMode()

  return (
    <FormControl isInvalid={!!error} {...formControlProps}>
      {!!label && (
        <FormLabel
          color={
            colorMode === 'light'
              ? LEColorConstants.black
              : LEColorConstants.white
          }
          htmlFor={inputId}
          {...labelProps}
        >
          {label}
          {extraLabel && (
            <Box as="span" fontSize="md" mx="4">
              {extraLabel}
            </Box>
          )}
          {info && (
            <Tooltip hasArrow placement="auto" label={info} fontSize="sm">
              <InfoIcon color="red.700" ml="2" />
            </Tooltip>
          )}
        </FormLabel>
      )}
      <Select
        onChange={onChange}
        name={name}
        size={size}
        border="1px solid #D5D5DC"
        borderRadius="lg"
        id={inputId}
        data-test-id="select-component"
        defaultValue={selected}
        value={selected}
        {...(!noValue && { value: '' })}
        {...selectProps}
      >
        <option value="" disabled>
          {`Select ${label || placeholder || '---'}`}
        </option>
        {options.map((option, i) => {
          return (
            <option
              data-testid="select-option"
              key={option.value}
              value={option.value}
              style={option.style || {}}
              {...(option.disabled && { disabled: option.disabled })}
            >
              {option.label}
            </option>
          )
        })}
      </Select>
      {error && (
        <FormErrorMessage
          data-testid="custom-select-error"
          fontSize="md"
          color={colorMode === 'light' ? 'red.500' : 'red.300'}
          mb={typeof error === 'boolean' ? 0 : 5}
        >
          {typeof error !== 'boolean' && <FormErrorIcon />} {error}
        </FormErrorMessage>
      )}
    </FormControl>
  )
}

export default DropdownSelect
