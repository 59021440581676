import React, { useEffect, useRef } from 'react'
import {
  Box,
  BoxProps,
  Center,
  Divider,
  Flex,
  Heading,
  Icon,
  IconButton,
  Text,
  useToast,
  useColorMode,
  Table,
  Th,
  Tr,
  Thead,
  Tbody,
  Td,
  Grid,
  Spinner,
} from '@chakra-ui/react'
import { QRCodeCanvas } from 'qrcode.react'
import moment from 'moment'
import { useRouter } from 'next/router'

import { useAnimation, motion } from 'framer-motion'
import { useInView } from 'react-intersection-observer'
import { AiOutlineBook } from 'react-icons/ai'
import { IoMdCopy } from 'react-icons/io'
import { ImSpinner3 } from 'react-icons/im'
import { observer } from 'mobx-react-lite'
import { BsArrowUpShort } from 'react-icons/bs'
import { MdCancel, MdLibraryBooks } from 'react-icons/md'
import { CgUserList } from 'react-icons/cg'
import { FaRegNewspaper } from 'react-icons/fa'

import LEColorConstants, {
  bgThemeColor,
  borderTransparentThemeColor,
  primaryThemeColor,
} from '@/lib/constants/colorConstants'
import CustomButton from '@/components/Button'
import NotifyTable from '@/components/NotifyTable'
import { CheckCircleIcon, ChevronDownIcon } from '@chakra-ui/icons'
import { quickActions } from '@/lib/constants/quickActionsConstants'
import { useStores } from '@/stores/index'
import ChartBox from '@/components/Chart'
import { TextInput } from '@/components/Input'
import { WrapBox } from '@/components/Chart/ChartBox'
import DropdownSelect from '@/components/DropdownSelect'
import { CustomLink } from '@/components/Link'
import DashBoardCard from '@/components/DashBoardCard'
import { useWallet } from './hooks/useWallet'
import { hasPermission } from '@/lib/helpers'
import { useStatistic } from './hooks/useStatistic'
import { stores } from '@/stores/index'

const adminsData = [
  {
    id: 1,
    logDate: '22nd Mar',
    logTime: '10:35pm',
    logInfo: 'IBBU Lapai',
  },
  {
    id: 2,
    logDate: '23rd Mar',
    logTime: '08:00am',
    logInfo: 'Global Investment Scheme',
  },
  {
    id: 3,
    logDate: '24th Mar',
    logTime: '12:00pm',
    logInfo: 'FUT Minna',
  },
  {
    id: 4,
    logDate: '26th Mar',
    logTime: '04:00pm',
    logInfo: 'SHOLA IJMB SCHOOL',
  },
]

const AppPage: React.FC = observer(() => {
  const { auth } = useStores()
  const router = useRouter()
  const { colorMode } = useColorMode()
  const controls = useAnimation()
  const { inView, ref } = useInView()
  const { wallet } = useWallet()
  const { stats, isLoading } = useStatistic()

  const currentCandidateRole = auth.currentAdmin?.role

  const hasAdminChartPerm = hasPermission(
    { name: 'admin chart', can: 'visible' },
    auth.getMyPermissions()
  )

  const hasLatestExamPerm = hasPermission(
    { name: 'latest exams', can: 'visible' },
    auth.getMyPermissions()
  )

  const hasLatestUserPerm = hasPermission(
    { name: 'latest users', can: 'visible' },
    auth.getMyPermissions()
  )

  const hasActivityLogsPerm = hasPermission(
    { name: 'latest activities', can: 'visible' },
    auth.getMyPermissions()
  )

  useEffect(() => {
    if (inView) {
      controls.start('visible')
    }
  }, [controls, inView])

  const toast = useToast()

  const handleCopyClipboard = (text: string) => {
    if (typeof window !== 'undefined') {
      const ta = document.createElement('textarea')
      ta.innerText = text
      document.body.appendChild(ta)
      ta.select()
      navigator.clipboard.writeText(`${ta.textContent}`)
      ta.remove()
      toast({
        title: 'Copied',
        duration: 2000,
        isClosable: true,
        containerStyle: {
          backgroundColor: 'green',
          borderRadius: '5px',
        },
      })
    }
  }

  const downloadQRCode = () => {
    const canvas: HTMLCanvasElement | any = document.getElementById('qr')
    const pngUrl = canvas
      .toDataURL('image/png')
      .replace('image/png', 'image/octet-stream')
    let downloadLink = document.createElement('a')
    downloadLink.href = pngUrl
    downloadLink.download = `${getCandidateUrl()}.png`
    document.body.appendChild(downloadLink)
    downloadLink.click()
    document.body.removeChild(downloadLink)
  }

  const getCandidateUrl = () => {
    return (
      auth.currentOrganization?.custom_domain ||
      (('https://' +
        auth.currentOrganization?.subdomain +
        '.testnotion.com') as string)
    )
  }

  return (
    <>
      <Flex flexDir="column" position="relative" as={motion.div} maxW="72rem">
        <Heading fontWeight="medium" fontSize="1.375rem" mb="30px">
          Welcome Back, {auth.currentAdmin?.name}
        </Heading>

        {/* First View */}
        {hasActivityLogsPerm && (
          <Grid
            w="full"
            gap="0.75rem"
            mb="2rem"
            flexWrap={'wrap'}
            gridTemplateColumns={[
              '1fr',
              'repeat(2, 1fr)',
              'repeat(3, 1fr)',
              'repeat(4, 1fr)',
            ]}
          >
            <DashBoardCard
              title="UNIT BALANCE"
              titleTotal={wallet.balance}
              renderRightBottom={
                <CustomButton
                  variant={'outline'}
                  maxW="5rem"
                  maxH="1.8rem"
                  borderRadius={'5px'}
                  fontSize={'0.6rem'}
                  fontWeight={700}
                  onClick={() => router.push('/unit')}
                >
                  Buy Unit
                </CustomButton>
              }
            />
            <DashBoardCard
              title="Total exams published"
              titleTotal={stats.total_exams}
            />
            <DashBoardCard
              title="total roles created"
              titleTotal={stats.total_roles}
            />
            <DashBoardCard
              title="Total candidates"
              titleTotal={stats.total_candidates}
              renderRightBottom={
                <Text
                  fontSize={'0.7rem'}
                  fontWeight={500}
                  color={LEColorConstants.colorCheck}
                  mt="0.6rem"
                >
                  {/* + 36% <Icon as={BsArrowUpShort} /> */}
                </Text>
              }
            />
          </Grid>
        )}

        {/* Second View */}
        {hasActivityLogsPerm && (
          <Flex
            columnGap={'4.5rem'}
            gap="17px"
            flexDir={['column', 'row']}
            mb="2rem"
          >
            <Box
              h="137px"
              borderRadius="10px"
              boxShadow="lg"
              px="29px"
              border={`1px solid ${borderTransparentThemeColor[colorMode]}`}
              overflowX="scroll"
              overflowY="hidden"
              whiteSpace="nowrap"
              w="full"
              bg={bgThemeColor[colorMode]}
            >
              <Text fontSize="0.75rem" fontWeight="medium" mt="10px">
                Quick Actions
              </Text>
              <Box
                maxW="822px"
                h="137px"
                borderRadius="10px"
                overflowX="scroll"
                overflowY="hidden"
                whiteSpace="nowrap"
              >
                <Flex mt="12px" gap="15px">
                  {quickActions.map(action => (
                    <CustomLink
                      key={`Quick_Action_${action.id}`}
                      href={action.path}
                    >
                      <Flex
                        flex="0 0 auto"
                        w="95px"
                        h="67px"
                        border={`1px solid ${borderTransparentThemeColor[colorMode]}`}
                        borderRadius="10px"
                        flexDir="column"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Center
                          boxSize="25px"
                          borderRadius="5px"
                          bg={`${action.color}10`}
                          mb="7px"
                        >
                          <Icon
                            as={action.icon}
                            color={action.color}
                            h="15px"
                            w="15px"
                          />
                        </Center>
                        <Text fontSize="10px">{action.title}</Text>
                      </Flex>
                    </CustomLink>
                  ))}
                </Flex>
              </Box>
            </Box>

            <QrCodeLinkComponent />
          </Flex>
        )}

        {/* Third View */}
        {hasActivityLogsPerm && (
          <Box maxW="full" mb="2rem">
            <Flex
              flexDir={['column', 'row']}
              gap="31px"
              justifyContent="space-between"
            >
              {stats.exam_monthly_analysis?.length > 0 && (
                <WrapBox width="full">
                  <ChartBox
                    chartStyle={{
                      maxHeight: '100px',
                    }}
                    title="Exams per Month"
                    noOfUsers={stats.total_exams}
                    chartData={{
                      labels:
                        (stats.exam_monthly_analysis?.map(
                          item => item.month
                        ) as string[]) || [],

                      datasets: [
                        {
                          label: 'Exam',
                          backgroundColor: [
                            ` ${primaryThemeColor[colorMode]}`,
                            ` ${primaryThemeColor[colorMode]}`,
                          ],
                          data:
                            (stats.exam_monthly_analysis?.map(
                              item => item.count
                            ) as number[]) || [],
                        },
                      ],
                    }}
                    typeofChart="barchart"
                  />
                </WrapBox>
              )}

              {stats.candidate_monthly_analysis?.length > 0 && (
                <WrapBox width="full">
                  <ChartBox
                    chartStyle={{
                      maxHeight: '100px',
                    }}
                    title="Total Candidates"
                    noOfUsers={stats.total_candidates}
                    chartData={{
                      labels: stats.candidate_monthly_analysis
                        ? stats.candidate_monthly_analysis.map(
                            (item: any) => item.month
                          )
                        : [],
                      datasets: [
                        {
                          label: 'Candidate',
                          backgroundColor: ` ${primaryThemeColor[colorMode]}`,
                          data: stats.candidate_monthly_analysis
                            ? stats.candidate_monthly_analysis.map(
                                (item: any) => item.count
                              )
                            : [],
                        },
                      ],
                    }}
                    typeofChart="barchart"
                  />
                </WrapBox>
              )}
            </Flex>
          </Box>
        )}

        {/* Fourth View */}
        <Flex gap="40px" flexDir={{ base: 'column', lg: 'row' }} mb="2rem">
          {/* Users View */}
          {hasLatestUserPerm && (
            <NotifyTable
              title="Users"
              icon={CgUserList}
              renderRight={
                stats.recent_users?.length > 0 ? (
                  <CustomLink
                    href="/users"
                    color="white"
                    fontSize="10px"
                    fontStyle="italic"
                  >
                    View all
                  </CustomLink>
                ) : (
                  <></>
                )
              }
            >
              <>
                {isLoading ? (
                  <Center mt="2rem">
                    <Spinner color="brand.secondary.400" />
                  </Center>
                ) : (
                  <>
                    {stats.recent_users?.length > 0 ? (
                      <Box overflowX="auto">
                        {stats.recent_users?.length > 0 &&
                          stats.recent_users.map((user, i) => (
                            <Box textAlign="left" key={`user_notify_${i}`}>
                              <Flex
                                justifyContent="space-evenly"
                                py="2"
                                gap="30px"
                                pl="2rem"
                              >
                                <Box flex="1" minW="70px">
                                  <Text fontSize="0.6875rem">{user.name}</Text>
                                </Box>
                                <Box flex="1" minW="70px">
                                  <Text fontSize="0.6875rem">{user.email}</Text>
                                </Box>
                                <Box flex="1" minW="70px">
                                  <Text
                                    fontSize="0.6875rem"
                                    color="brand.secondary.500"
                                    textTransform="capitalize"
                                  >
                                    {user.role_name}
                                  </Text>
                                </Box>
                              </Flex>
                              <Divider
                                orientation="horizontal"
                                variant="solid"
                              />
                            </Box>
                          ))}
                      </Box>
                    ) : (
                      <Center mt="2rem">
                        <Text
                          fontSize="0.6875rem"
                          fontWeight="medium"
                          color="gray"
                        >
                          No data to display
                        </Text>
                      </Center>
                    )}
                  </>
                )}
              </>
            </NotifyTable>
          )}

          {/* Exams View */}
          {hasLatestExamPerm && (
            <NotifyTable
              title="Exams"
              icon={FaRegNewspaper}
              renderRight={
                stats.recent_exams?.length > 0 ? (
                  <CustomLink
                    href="/exams"
                    color="white"
                    fontSize="10px"
                    fontStyle="italic"
                  >
                    View all
                  </CustomLink>
                ) : (
                  <></>
                )
              }
              overflow="scroll"
            >
              <>
                {isLoading ? (
                  <Center mt="2rem">
                    <Spinner color="brand.secondary.400" />
                  </Center>
                ) : (
                  <>
                    {stats.recent_exams?.length > 0 ? (
                      <Table
                        size="sm"
                        variant="simple"
                        fontSize={'6px'}
                        whiteSpace="nowrap"
                      >
                        <Thead>
                          <Tr>
                            <Th
                              fontSize="0.6875rem"
                              color={LEColorConstants.greyLighter}
                            >
                              EXAM ID
                            </Th>
                            <Th
                              fontSize="0.75rem"
                              color={LEColorConstants.greyLighter}
                            >
                              NAME
                            </Th>
                            <Th
                              fontSize="0.75rem"
                              color={LEColorConstants.greyLighter}
                            >
                              DATE
                            </Th>
                            <Th
                              fontSize="0.75rem"
                              color={LEColorConstants.greyLighter}
                            >
                              TIME
                            </Th>
                            <Th
                              fontSize="0.75rem"
                              color={LEColorConstants.greyLighter}
                            >
                              STATUS
                            </Th>
                          </Tr>
                        </Thead>
                        <Tbody>
                          {stats.recent_exams.length > 0 &&
                            stats.recent_exams.map((exam, i) => (
                              <Tr
                                key={`exam_activity_${i}`}
                                fontSize="0.6875rem"
                                mb="10px"
                              >
                                <Td fontSize="0.6875rem">{exam.code}</Td>
                                <Td fontSize="0.6875rem">{exam.name}</Td>
                                <Td fontSize="0.6875rem">
                                  {moment(exam.created_at).format(
                                    'ddd, DD-MM-YYYY'
                                  )}
                                </Td>
                                <Td fontSize="0.6875rem">
                                  {moment(exam.created_at).format('hh:mm A')}
                                </Td>
                                <Td fontSize="0.6875rem">
                                  {exam.status === 'active' ? (
                                    <Icon
                                      as={ImSpinner3}
                                      color={LEColorConstants.secondary}
                                      fontSize="13.33px"
                                    />
                                  ) : exam.status === 'disabled' ? (
                                    <Icon
                                      as={MdCancel}
                                      fontSize="13.33px"
                                      color={LEColorConstants.secondary}
                                    />
                                  ) : (
                                    <Icon
                                      as={CheckCircleIcon}
                                      fontSize="13.33px"
                                      color="green"
                                    />
                                  )}
                                </Td>
                              </Tr>
                            ))}
                        </Tbody>
                      </Table>
                    ) : (
                      <Center mt="2rem">
                        <Text
                          fontSize="0.6875rem"
                          fontWeight="medium"
                          color="gray"
                        >
                          No data to display
                        </Text>
                      </Center>
                    )}
                  </>
                )}
              </>
            </NotifyTable>
          )}
        </Flex>

        {/* Fifth View */}
        {hasActivityLogsPerm && (
          <Flex gap="40px" flexDir={{ base: 'column', lg: 'row' }} mb="2rem">
            {/* Transactions View */}
            <NotifyTable
              title="Transactions"
              icon={MdLibraryBooks}
              renderRight={
                stats.recent_transactions?.length > 0 ? (
                  <CustomLink
                    href="/transactions"
                    color="white"
                    fontSize="10px"
                    fontStyle="italic"
                  >
                    View all
                  </CustomLink>
                ) : (
                  <></>
                )
              }
            >
              <>
                {isLoading ? (
                  <Center mt="2rem">
                    <Spinner color="brand.secondary.400" />
                  </Center>
                ) : (
                  <>
                    {stats.recent_transactions?.length > 0 ? (
                      <Table
                        size="sm"
                        variant="simple"
                        fontSize={'6px'}
                        whiteSpace="nowrap"
                      >
                        <Thead>
                          <Tr>
                            <Th
                              fontSize="0.6875rem"
                              color={LEColorConstants.greyLighter}
                            >
                              TRANSACTION
                            </Th>
                            <Th
                              fontSize="0.75rem"
                              color={LEColorConstants.greyLighter}
                            >
                              INVOICE NUMBER
                            </Th>
                            <Th
                              fontSize="0.75rem"
                              color={LEColorConstants.greyLighter}
                            >
                              AMOUNT
                            </Th>
                            <Th
                              fontSize="0.75rem"
                              color={LEColorConstants.greyLighter}
                            >
                              STATUS
                            </Th>
                          </Tr>
                        </Thead>
                        <Tbody>
                          {stats.recent_transactions?.length > 0 &&
                            stats.recent_transactions.map((item, i) => (
                              <Tr
                                key={`exam_activity_${i}`}
                                fontSize="0.6875rem"
                                mb="10px"
                              >
                                <Td fontSize="0.6875rem">{item.remarks}</Td>
                                <Td fontSize="0.6875rem">
                                  {item.transaction_reference}
                                </Td>
                                <Td fontSize="0.6875rem">{item.amount}</Td>
                                <Td fontSize="0.6875rem">
                                  {item.status === 'pending' ? (
                                    <Icon
                                      as={ImSpinner3}
                                      color={LEColorConstants.secondary}
                                      fontSize="13.33px"
                                    />
                                  ) : item.status === 'failed' ? (
                                    <Icon
                                      as={MdCancel}
                                      fontSize="13.33px"
                                      color={LEColorConstants.secondary}
                                    />
                                  ) : (
                                    <Icon
                                      as={CheckCircleIcon}
                                      fontSize="13.33px"
                                      color="green"
                                    />
                                  )}
                                </Td>
                              </Tr>
                            ))}
                        </Tbody>
                      </Table>
                    ) : (
                      <Center mt="2rem">
                        <Text
                          fontSize="0.6875rem"
                          fontWeight="medium"
                          color="gray"
                        >
                          No data to display
                        </Text>
                      </Center>
                    )}
                  </>
                )}
              </>
            </NotifyTable>

            {/* Activities View */}

            <NotifyTable
              title="Activity Log"
              icon={AiOutlineBook}
              renderRight={
                stats.recent_activity_logs?.length > 0 ? (
                  <CustomLink
                    href="/activity"
                    color="white"
                    fontSize="10px"
                    fontStyle="italic"
                  >
                    View all
                  </CustomLink>
                ) : (
                  <></>
                )
              }
            >
              <>
                {isLoading ? (
                  <Center mt="2rem">
                    <Spinner color="brand.secondary.400" />
                  </Center>
                ) : (
                  <>
                    {stats.recent_activity_logs?.length > 0 ? (
                      <>
                        <Table
                          size="sm"
                          variant="simple"
                          fontSize={'6px'}
                          whiteSpace="nowrap"
                        >
                          <Tbody>
                            {stats.recent_activity_logs.length > 0 &&
                              stats.recent_activity_logs.map((log, i) => (
                                <Tr
                                  key={`log_notify_${i}`}
                                  fontSize="0.6875rem"
                                  mb="10px"
                                >
                                  <Td fontSize="0.6875rem">
                                    {' '}
                                    {moment(log.created_at).format(
                                      'ddd, DD-MM-YYYY'
                                    )}
                                  </Td>
                                  <Td fontSize="0.6875rem">
                                    {moment(log.created_at).format('hh:mm A')}
                                  </Td>
                                  <Td fontSize="0.6875rem">
                                    {log.description}
                                  </Td>
                                </Tr>
                              ))}
                          </Tbody>
                        </Table>
                      </>
                    ) : (
                      <Center mt="2rem">
                        <Text
                          fontSize="0.6875rem"
                          fontWeight="medium"
                          color="gray"
                        >
                          No data to display
                        </Text>
                      </Center>
                    )}
                  </>
                )}
              </>
            </NotifyTable>
          </Flex>
        )}

        {/* <Flex> */}
        {currentCandidateRole?.name === 'superadmin' && <SupperAdmin />}
        {/* </Flex> */}
      </Flex>
    </>
  )
  function QrCodeLinkComponent() {
    return (
      <>
        <Flex
          maxW="245px"
          h="137px"
          boxShadow="md"
          borderLeft={`8px solid ${primaryThemeColor[colorMode]}`}
          borderRadius="lg"
          flexDir="column"
          justifyContent="space-evenly"
          pl={['5px', '13px']}
          bg={bgThemeColor[colorMode]}
        >
          <Text fontSize="0.87rem" fontWeight="normal">
            Share Candidate Login URL
          </Text>
          <Flex paddingRight="1rem" gap={2}>
            <TextInput
              type="text"
              value={getCandidateUrl()}
              TextInputProps={{
                w: '10.188rem',
              }}
              handleChange={() => {}}
            />
            <IconButton
              _hover={{
                background: primaryThemeColor[colorMode],
              }}
              onClick={() => handleCopyClipboard(getCandidateUrl())}
              background={primaryThemeColor[colorMode]}
              aria-label={''}
              icon={<IoMdCopy color="white" />}
            />
          </Flex>
          <Flex paddingRight="2rem" justifyContent="space-between">
            <Text fontSize="0.75rem" fontWeight="normal" maxW="7.813rem">
              Share QR code after saving
            </Text>
            <Box
              as="div"
              position="relative"
              width="3.5rem"
              height="3.5rem"
              className="rcode"
            >
              <QRCodeCanvas
                id="qr"
                width="100%"
                value={getCandidateUrl()}
                size={45}
                imageSettings={{
                  src: '/android-chrome-192x192.png',
                  height: 10,
                  width: 10,
                  excavate: true,
                }}
              />
              <Box className="text">
                <CustomButton
                  fontSize="10px"
                  borderRadius="5px"
                  height="1.5rem"
                  width="3rem"
                  color="white"
                  className="btn"
                  onClick={downloadQRCode}
                >
                  Save
                </CustomButton>
              </Box>
            </Box>
          </Flex>
        </Flex>
      </>
    )
  }

  function SupperAdmin() {
    return (
      <>
        <Box w="full" mb="2rem" mt="2rem">
          <Flex
            flexDir={['column', 'row']}
            gap="31px"
            justifyContent="space-between"
          >
            <WrapBox width="full">
              <ChartBox
                chartStyle={{
                  maxHeight: '100px',
                }}
                title="Total Admins "
                noOfUsers={2345}
                chartData={{
                  labels: ['January', 'February', 'March', 'April', 'May'],
                  datasets: [
                    {
                      label: 'mon',
                      backgroundColor: [` ${primaryThemeColor[colorMode]}`],
                      data: [762, 99, 133, 500, 400],
                    },
                  ],
                }}
                typeofChart="barchart"
              />

              <Text
                mt="1rem"
                ml="0.75rem"
                fontSize="0.875rem"
                fontWeight="medium"
              >
                Latest Admins
              </Text>
              {adminsData.length > 0 &&
                adminsData.map((log, i) => (
                  <Box key={`log_notify_${i}`}>
                    <Flex py="2" gap="42px" pl="47px">
                      <Text flexWrap="wrap" fontSize="0.6875rem">
                        {log.logDate}
                      </Text>
                      <Text flexWrap="wrap" fontSize="0.6875rem">
                        {log.logTime}
                      </Text>
                      <Text flexWrap="wrap" fontSize="0.6875rem">
                        {log.logInfo}
                      </Text>
                    </Flex>
                    <Divider orientation="horizontal" variant="solid" />
                  </Box>
                ))}
            </WrapBox>
            <WrapBox width="full">
              <ChartBox
                chartStyle={{
                  maxHeight: '100px',
                }}
                title="Total Candidates "
                noOfUsers={2348288382325}
                chartData={{
                  labels: ['January', 'February', 'March', 'April', 'May'],
                  datasets: [
                    {
                      label: 'mon',
                      backgroundColor: [` ${primaryThemeColor[colorMode]}`],
                      data: [762, 99, 133, 500, 400],
                    },
                  ],
                }}
                typeofChart="barchart"
              />

              <Text
                mt="1rem"
                ml="0.75rem"
                fontSize="0.875rem"
                fontWeight="medium"
              >
                Latest Candidates
              </Text>
              {adminsData.length > 0 &&
                adminsData.map((log, i) => (
                  <Box key={`log_notify_${i}`}>
                    <Flex py="2" gap="42px" pl="47px">
                      <Text flexWrap="wrap" fontSize="0.6875rem">
                        {log.logDate}
                      </Text>
                      <Text flexWrap="wrap" fontSize="0.6875rem">
                        {log.logTime}
                      </Text>
                      <Text flexWrap="wrap" fontSize="0.6875rem">
                        {log.logInfo}
                      </Text>
                    </Flex>
                    <Divider orientation="horizontal" variant="solid" />
                  </Box>
                ))}
            </WrapBox>
          </Flex>
        </Box>

        <Box w="full" mb="2rem" mt="2rem">
          <Flex
            flexDir={['column', 'row']}
            gap="31px"
            justifyContent="space-between"
            flex="auto"
          >
            <WrapBox width="full">
              <Text mt="1rem" ml="0.75rem" fontSize="0.875rem">
                Volume Plans
              </Text>
              <Box mt="-4rem" ml="2.5rem" maxW="200px" maxH="200px">
                <ChartBox
                  chartStyle={{
                    maxHeight: '100px',
                  }}
                  title="Volume Plans"
                  chartData={{
                    labels: ['PAYG', 'Regular Plan'],
                    datasets: [
                      {
                        label: 'mon',
                        backgroundColor: ['#009BFF', '#FF6E06'],
                        data: [68, 42],
                      },
                    ],
                  }}
                  typeofChart="piechart"
                  options={{
                    responsive: true,
                    plugins: {
                      legend: {
                        display: true,
                        position: 'left' as const,
                        fullSize: false,
                        labels: {
                          boxWidth: 13,
                        },
                      },
                    },
                  }}
                />
              </Box>
            </WrapBox>
            <WrapBox width="full" alignSelf="flex-start">
              <Flex mt="1rem" justifyContent="space-between">
                <Text ml="0.75rem">
                  Total Revenue:{' '}
                  <Text as="span" color="primary">
                    {' '}
                    N259039490395
                  </Text>
                </Text>
                <Box maxW="10.313rem" mr="0.5rem">
                  <DropdownSelect
                    size="sm"
                    onChange={() => {}}
                    options={[
                      { value: 'Month', label: 'Month' },
                      { value: 'Year', label: 'Year' },
                    ]}
                  />
                </Box>
              </Flex>
              <ChartBox
                chartStyle={{
                  maxHeight: '100px',
                }}
                chartData={{
                  labels: ['January', 'February', 'March', 'April', 'May'],
                  datasets: [
                    {
                      label: 'mon',
                      backgroundColor: [` ${primaryThemeColor[colorMode]}`],
                      data: [762, 99, 133, 500, 400],
                    },
                  ],
                }}
                typeofChart="barchart"
              />
            </WrapBox>
          </Flex>
        </Box>
      </>
    )
  }
})

export default AppPage
