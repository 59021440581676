import { FC, ReactElement } from 'react'
import { Box, BoxProps } from '@chakra-ui/react'
import { css } from '@emotion/react'

interface ITruncatedTextProps {
  children: ReactElement
  lines?: number
  styleProps?: BoxProps
}

const TruncatedText: FC<ITruncatedTextProps> = ({
  lines = 1,
  children,
  styleProps,
}: ITruncatedTextProps) => {
  const style = css`
    & {
      display: -webkit-box;
      -webkit-line-clamp: ${lines};
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      hyphens: auto;
    }
  `

  return (
    <Box css={style} as={'span'} {...styleProps}>
      {children}
    </Box>
  )
}

export default TruncatedText
