import { useRouter } from 'next/router'
import React, { useState } from 'react'
import { Tabs, Tab, TabPanels, TabPanel, TabList, Text } from '@chakra-ui/react'
import All from '../all'
import Regular from '../regular'
import Payg from '../payg'

interface IAdminsAuthTab {
  type: AdminsAuthType
}

const pageMap = {
  All: 0,
  Regular: 1,
  Payg: 2,
}

const pageIndexArr = ['all', 'regular', 'payg']

const AdminsAuthTab: React.FC<IAdminsAuthTab> = ({ type }) => {
  const router = useRouter()
  const [tabIndex, setTabIndex] = useState(pageMap[type])

  const handleTabsChange = (index: any) => {
    setTabIndex(index)
    router.push(`/admins/${pageIndexArr[index]}`)
  }

  const tabs = [
    {
      id: 1,
      name: 'All',
    },
    {
      id: 2,
      name: 'Regular',
    },
    {
      id: 3,
      name: 'PAYG',
    },
  ]

  return (
    <>
      <Tabs mt={6} index={tabIndex} onChange={handleTabsChange}>
        <TabList
          overflowX="auto"
          ml={2}
          boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
        >
          {tabs.map(data => (
            <Tab
              fontSize="1rem"
              key={data.id}
              _focus={{}}
              _selected={{
                borderBottom: '0.4rem solid #009BFF',
                fontSize: '1rem',
                color: '#009BFF',
                fontWeight: 'bold',
              }}
            >
              {data.name}
            </Tab>
          ))}
        </TabList>
        <TabPanels position="relative">
          <TabPanel padding={0}>
            <All />
          </TabPanel>
          <TabPanel padding={0}>
            <Regular />
          </TabPanel>
          <TabPanel padding={0}>
            <Payg />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </>
  )
}

export default AdminsAuthTab
