import { SideBarOptionMenu } from '@/types/sidebar'
import { FaMoneyCheckAlt } from 'react-icons/fa'

export const revenuMenu: SideBarOptionMenu = {
  name: 'Revenue',
  icon: FaMoneyCheckAlt,
  path: '/revenue',
  order: 1,
  permission: { name: 'revenue', can: 'visible' },
  isShown: true,
  isActive: false,
  child: [],
}
