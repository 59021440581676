import React, { ReactElement, SyntheticEvent } from 'react'
import dynamic from 'next/dynamic'
import { InfoIcon } from '@chakra-ui/icons'
import {
  Box,
  FormControl,
  FormControlProps,
  FormErrorMessage,
  FormLabel,
  FormLabelProps,
  Input,
  InputProps,
  useColorMode,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Textarea,
  useTheme,
  InputGroup,
  Select,
  Tooltip,
  FormErrorIcon,
} from '@chakra-ui/react'
const RichTextEditor = dynamic(() => import('./RichTextEditor'), {
  ssr: false,
})
import DatePicker from 'react-multi-date-picker'
import TimePicker from 'react-multi-date-picker/plugins/time_picker'

import {
  borderErrorThemeColor,
  borderSuccessThemeColor,
  borderThemeColor,
} from '@/lib/constants/colorConstants'

type TextInputProps = {
  type: string
  label?: string
  info?: string
  labelProps?: FormLabelProps
  TextInputProps?: InputProps
  TextInputElement?: ReactElement | undefined
  placeholder?: string
  value: string | number
  inputId?: string
  handleChange: (event: any) => void
  error?: string | boolean
  formControlProps?: FormControlProps
  name?: string
  extraLabel?: string
  options?: any
  rows?: number
}

const TextInput: React.FC<TextInputProps> = ({
  name,
  type,
  labelProps,
  TextInputProps,
  TextInputElement,
  placeholder = '',
  value,
  handleChange,
  label,
  info,
  inputId = '',
  error,
  formControlProps,
  extraLabel,
  options,
  rows,
}) => {
  const { colors } = useTheme()
  const { colorMode } = useColorMode()
  const format = (val: string) => `$` + val
  const parse = (val: string) => val.replace(/^\$/, '')

  const datepickerInputColor = colorMode === 'light' ? 'white' : 'transparent'

  const setValue = (value: string) => {
    const event = {
      target: {
        name,
        value: value,
        type: 'number',
        checked: false,
      },
    } as unknown as SyntheticEvent

    handleChange(event)
  }
  return (
    <FormControl isInvalid={!!error} {...formControlProps}>
      {!!label && (
        <FormLabel
          color={colorMode === 'light' ? 'black' : 'white'}
          htmlFor={inputId}
          data-testid="textInput-label"
          {...labelProps}
        >
          {label}
          {extraLabel && (
            <Box as="span" fontSize="md" mx="4">
              {extraLabel}
            </Box>
          )}
          {info && (
            <Tooltip hasArrow placement="auto" label={info} fontSize="sm">
              <InfoIcon color="gray.500" ml="2" />
            </Tooltip>
          )}
        </FormLabel>
      )}
      {type === 'amount' ? (
        <NumberInput
          onChange={valueString => setValue(parse(valueString))}
          value={format(value.toString())}
          precision={2}
          step={0.2}
          size="xl"
          placeholder={placeholder}
        >
          <NumberInputField
            border={`1px solid ${borderThemeColor[colorMode]}`}
            p="1rem"
            borderRadius="xl"
          />
          <NumberInputStepper>
            <NumberIncrementStepper />
            <NumberDecrementStepper />
          </NumberInputStepper>
        </NumberInput>
      ) : type === 'textarea' ? (
        <Textarea
          name={name}
          size="sm"
          value={value}
          border={`1px solid ${borderThemeColor[colorMode]}`}
          placeholder={placeholder}
          // @ts-ignore
          onChange={handleChange}
          resize="none"
          rows={rows}
          borderRadius={6}
          {...TextInputProps}
        />
      ) : type === 'datetime' ? (
        <DatePicker
          name={name}
          format="YYYY-MM-DD HH:mm:ss"
          formattingIgnoreList={['Date', 'Time']}
          value={value}
          onChange={(value: any) => {
            const event = {
              target: {
                name,
                value,
                type: 'datetime',
                checked: false,
              },
            } as unknown as SyntheticEvent
            handleChange(event)
          }}
          plugins={[<TimePicker />]}
          style={
            {
              width: '25rem',
              height: '2.5rem',
              borderRadius: '5px',
              border: `${
                !!error ? '2px' : colorMode === 'light' ? '1px' : '0.5px'
              } solid ${
                !!error
                  ? borderErrorThemeColor[colorMode]
                  : borderSuccessThemeColor[colorMode]
              }`,
              padding: '0.5rem 1rem',
              backgroundColor: datepickerInputColor,
              ...TextInputProps,
            } as any
          }
        />
      ) : type === 'select' ? (
        <Select
          name={name}
          onChange={handleChange}
          border={`1px solid ${borderThemeColor[colorMode]}`}
          // p="1rem"
          h="2.5rem"
          borderRadius="xl"
          placeholder={placeholder}
        >
          <option value={value}>{options}</option>
        </Select>
      ) : type === 'rich-text-editor' ? (
        <>
          {/* @ts-ignore */}
          <RichTextEditor
            name={name as string}
            content={value as string}
            placeholder={placeholder}
            handleChange={handleChange}
          />
        </>
      ) : (
        <InputGroup size="md">
          <Input
            name={name}
            type={type}
            value={
              value && type === 'date'
                ? new Date(value).toISOString().substring(0, 10)
                : value
            }
            border={`1px solid  ${borderThemeColor[colorMode]}`}
            onChange={handleChange}
            // p="1rem"
            h="2.5rem"
            borderRadius="5px"
            placeholder={placeholder}
            {...TextInputProps}
          />
          {TextInputElement}
        </InputGroup>
      )}
      {error && (
        <FormErrorMessage
          data-testid="textInput-error"
          fontSize="md"
          mb={typeof error === 'boolean' ? 0 : 5}
        >
          {typeof error !== 'boolean' && <FormErrorIcon />} {error}
        </FormErrorMessage>
      )}
    </FormControl>
  )
}

export default TextInput
