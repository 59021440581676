import React from 'react'
import { useRouter } from 'next/router'
import { FiAlertCircle, FiCircle, FiPlus } from 'react-icons/fi'
import { BsThreeDotsVertical } from 'react-icons/bs'
import { createColumnHelper } from '@tanstack/react-table'
import { ImSpinner3 } from 'react-icons/im'
import { CheckCircleIcon } from '@chakra-ui/icons'
import {
  Box,
  chakra,
  Container,
  Flex,
  FormControl,
  Icon,
  InputRightElement,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  Tooltip,
  useColorMode,
  useDisclosure,
  VStack,
} from '@chakra-ui/react'
import moment from 'moment-timezone'
import { AiOutlineSearch } from 'react-icons/ai'

import HeaderTab from '@/components/HeaderTab'
import DataTable from '@/components/DataTable'
import CustomButton from '@/components/Button'
import TextInput from '@/components/Input/TextInput'
import LEColorConstants, {
  secondaryThemeColor,
} from '@/lib/constants/colorConstants'
import DropdownSelect from '@/components/DropdownSelect'
import DeleteDialog from '@/components/DeleteDialog'

import { useExamAdmin } from './hooks/useExamAdmin'
import { useChangeExamStatus } from './hooks/useChangeExamStatus'
import { useDeleteExam } from './hooks/useExamDelete'

function ExamsPage() {
  const router = useRouter()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { colorMode } = useColorMode()
  const changeExamStatus = useChangeExamStatus()
  const deleteExam = useDeleteExam()

  const {
    exams,
    isLoading,
    setActivePage,
    filter,
    setFilter,
    searchTerm,
    setSearchTerm,
  } = useExamAdmin()

  const [examToDelete, setExamToDelete] = React.useState<any>()

  const handleSearch = (event: any) => {
    event.preventDefault()
    setSearchTerm(event.target.value)
  }

  const handleExamAdminPageChange = (page: number) => {
    setActivePage(page as number)
  }

  const handleChangeExamStatus = (
    id: string,
    status: 'active' | 'disabled' | 'completed'
  ) => {
    changeExamStatus({ exam_id: id, status })
  }

  const handleExamDelete = (id: string) => {
    deleteExam(id)
  }

  const getExamStatus = (status: string) => {
    switch (status) {
      case 'completed':
        return (
          <Icon fontSize="1.2rem" color="whatsapp.400" as={CheckCircleIcon} />
        )
      case 'active':
        return (
          <Icon
            as={ImSpinner3}
            fontSize="1.4rem"
            color={LEColorConstants.secondary}
            verticalAlign="middle"
          />
        )
      case 'active_not_started':
        return (
          <Icon
            as={FiCircle}
            fontSize="1.4rem"
            color="red.500"
            verticalAlign="middle"
          />
        )
      case 'disabled':
        return (
          <Icon as={FiAlertCircle} fontSize="1.4rem" verticalAlign="middle" />
        )
      default:
        return <Icon />
    }
  }

  const columnHelper = createColumnHelper<IExam>()
  const columns = React.useMemo(
    () => [
      columnHelper.accessor('code', {
        header: 'Exam Code',
        cell: info => (
          <chakra.span
            as="button"
            cursor={
              info.row.original.status === 'disabled' ? 'default' : 'pointer'
            }
            _hover={{
              color: info.row.original.status === 'disabled' ? '' : 'secondary',
            }}
            onClick={() => {
              if (info.row.original.status === 'completed') {
                router.push('/exams/completed/' + info.row.original.id)
                return
              }

              if (info.row.original.status === 'active') {
                router.push('/exams/' + info.row.original.id)
                return
              }
            }}
          >
            {info.getValue()}
          </chakra.span>
        ),
      }),
      columnHelper.accessor('name', {
        header: 'Exam Name',
        cell: info => (
          <chakra.span
            as="button"
            cursor={
              info.row.original.status === 'disabled' ? 'default' : 'pointer'
            }
            _hover={{
              color: info.row.original.status === 'disabled' ? '' : 'secondary',
            }}
            onClick={() => {
              if (info.row.original.status === 'completed') {
                router.push('/exams/completed/' + info.row.original.id)
                return
              }

              if (info.row.original.status === 'active') {
                router.push('/exams/' + info.row.original.id)
                return
              }
            }}
          >
            {info.getValue()}
          </chakra.span>
        ),
      }),
      columnHelper.accessor('duration', {
        header: 'Duration',
        cell: info => (
          <Text textTransform="capitalize">{`${info.getValue()}mins`}</Text>
        ),
      }),
      columnHelper.accessor('start_date', {
        header: 'Start Date',
        cell: info =>
          moment(new Date(info.getValue())).format('DD MMM YYYY hh:mm A'),
      }),
      columnHelper.accessor('end_date', {
        header: 'End Date',
        cell: info =>
          moment(new Date(info.getValue())).format('DD MMM YYYY hh:mm A'),
      }),
      columnHelper.display({
        id: 'actions',
        cell: ({ row: { original: data } }) => (
          <Flex gap="35px">
            <Tooltip label={data.status}>
              <chakra.span textTransform="capitalize">
                {getExamStatus(data.status as string)}
              </chakra.span>
            </Tooltip>
            <Menu isLazy placement="auto" flip preventOverflow>
              <MenuButton>
                <Icon
                  aria-label="More"
                  as={BsThreeDotsVertical}
                  color={secondaryThemeColor[colorMode]}
                  _focus={{ boxShadow: 'none' }}
                />
              </MenuButton>
              <MenuList p={0} minW="0" w={'100px'}>
                {moment(data.start_date)
                  .tz(data.timezone)
                  .isAfter(moment().tz(data.timezone)) && (
                  <MenuItem
                  // onClick={() => router.push(`/exams/edit/${data.id}`)}
                  >
                    Edit
                  </MenuItem>
                )}
                <MenuItem
                  onClick={() =>
                    handleChangeExamStatus(
                      data.id,
                      data.status === 'disabled' ? 'active' : 'disabled'
                    )
                  }
                >
                  {data.status === 'disabled' ? 'Publish' : 'Unpublish'}
                </MenuItem>
                {data.status === 'active' && (
                  <MenuItem
                    onClick={() => handleChangeExamStatus(data.id, 'completed')}
                  >
                    Mark as Completed
                  </MenuItem>
                )}
                {data.status !== 'active' && (
                  <MenuItem
                    onClick={() => {
                      setExamToDelete(data)
                      onOpen()
                    }}
                  >
                    Delete
                  </MenuItem>
                )}
                {data.status === 'completed' && (
                  <MenuItem
                    onClick={() => {
                      router.push('/exams/completed/' + data.id)
                    }}
                  >
                    View Report
                  </MenuItem>
                )}
              </MenuList>
            </Menu>
          </Flex>
        ),
      }),
    ],
    []
  )

  return (
    <>
      <HeaderTab>
        <CustomButton
          maxW="12.625rem"
          h="2.5rem"
          borderRadius="5px"
          fontSize="0.938rem"
          fontWeight="medium"
          onClick={() => router.push('/exams/create')}
        >
          <Icon mr="0.875rem" as={FiPlus} />
          Add New Exam
        </CustomButton>{' '}
      </HeaderTab>
      <Box
        flex={1}
        display="flex"
        mt="1.25rem"
        mb="1.25rem"
        gap="2.3rem"
        flexDirection={['column', 'row']}
        alignItems="center"
      >
        <TextInput
          labelProps={{
            fontSize: '0.88rem',
            fontWeight: 'medium',
            mb: '0.2rem',
          }}
          TextInputProps={{
            borderRadius: '2.3px',
          }}
          placeholder="Search Exam"
          type="search"
          value={searchTerm}
          handleChange={handleSearch}
          TextInputElement={
            <InputRightElement>
              <AiOutlineSearch />
            </InputRightElement>
          }
        />
        <FormControl mr="1rem" minW={{ base: '5rem', md: '10.1rem' }} flex={1}>
          <DropdownSelect
            placeholder="Filter"
            selected={filter}
            options={[
              { value: '', label: 'None' },
              { value: 'completed', label: 'Completed' },
              { value: 'active', label: 'Ongoing' },
              { value: 'disabled', label: 'Saved' },
            ]}
            onChange={(e: any) => setFilter(e.target.value)}
          />
        </FormControl>
      </Box>
      {/* </VStack> */}
      <Container ml="0" p="0" maxW="full" overflowX="auto">
        <DataTable
          variant="lg-table"
          data={exams.rows}
          columns={columns}
          totalPages={exams.total_pages}
          totalRecords={exams.count}
          handlePageChange={handleExamAdminPageChange}
          isLoading={isLoading}
        />
      </Container>
      {/* Single Deletion Confirmation */}
      <DeleteDialog
        isOpen={isOpen}
        onClick={() => {
          handleExamDelete(examToDelete.id)
          onClose()
        }}
        onClose={onClose}
        title="Are you sure you want to delete the selected Item?"
        message="This action cannot be undone"
      />
    </>
  )
}

export default ExamsPage
