import { SideBarOptionMenu } from '@/types/sidebar'
import { HiOutlineInformationCircle } from 'react-icons/hi'

export const rolesMenu: SideBarOptionMenu = {
  name: 'Roles',
  icon: HiOutlineInformationCircle,
  path: '/roles',
  order: 1,
  permission: { name: 'roles', can: 'visible' },
  isShown: true,
  isActive: false,
  child: [],
}
