import React from 'react'
import { useRouter } from 'next/router'
import {
  Box,
  Flex,
  HStack,
  Icon,
  IconButton,
  InputRightElement,
  Text,
  useColorMode,
  useDisclosure,
  chakra,
  Container,
} from '@chakra-ui/react'
import { createColumnHelper } from '@tanstack/react-table'
import { BsTrash } from 'react-icons/bs'
import { AiFillEdit, AiOutlinePlus, AiOutlineSearch } from 'react-icons/ai'

import DataTable from '@/components/DataTable'
import CustomButton from '@/components/Button'
import { TextInput } from '@/components/Input'
import { brandPrimayColor, grayBgColor } from '@/lib/constants/colorConstants'
import DropdownSelect from '@/components/DropdownSelect'
import DeleteDialog from '@/components/DeleteDialog'
import { TablePageLoader } from '@/components/SkeletonLoaders'
import { useUser } from './hooks/useUser'
import { handleErrorResponse } from '@/lib/helpers'
import Loader from '@/components/Loader/Loader'
import { useDeleteUser } from './hooks/useDeleteUser'

function UsersPage() {
  const router = useRouter()
  const { colorMode } = useColorMode()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const {
    users,
    isLoading,
    setActivePage,
    filter,
    setFilter,
    searchTerm,
    setSearchTerm,
  } = useUser()
  const deleteUser = useDeleteUser()

  const [isLoadingDeletion, setIsLoadingDeletion] = React.useState(false)
  const [userToDelete, setUserToDelete] = React.useState<IUser | null>(null)

  const randomColor = () => {
    const letters = '0123456789ABCDEF'
    let color = '#'
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)]
    }
    return color
  }

  const columnHelper = createColumnHelper<IUser>()
  const columns = React.useMemo(
    () => [
      columnHelper.accessor('name', {
        header: 'Name',
        cell: info => info.getValue(),
      }),
      columnHelper.accessor('email', {
        header: 'Email',
        cell: info => info.getValue(),
      }),
      columnHelper.accessor('phone', {
        header: 'Phone',
        cell: info => info.getValue(),
      }),
      columnHelper.accessor('role_name', {
        header: 'Role',
        cell: info => (
          <chakra.span color={randomColor()}>{info.getValue()}</chakra.span>
        ),
      }),
      columnHelper.display({
        id: 'actions',
        cell: ({ row }) => (
          <Flex gap={4} alignItems="center">
            {row.original.role_name !== 'admin' && (
              <>
                <IconButton
                  aria-label="edit"
                  icon={<AiFillEdit />}
                  variant="unstyled"
                  color={brandPrimayColor[colorMode]}
                  onClick={() => router.push(`/users/edit/${row.original.id}`)}
                />
                <IconButton
                  display="flex"
                  justifyItems="center"
                  alignItems="center"
                  aria-label="edit"
                  variant="unstyled"
                  w="1.875rem"
                  height="1.875rem"
                  rounded="full"
                  size="4"
                  bgColor={grayBgColor[colorMode]}
                  icon={<BsTrash />}
                  color={brandPrimayColor[colorMode]}
                  onClick={() => {
                    setUserToDelete(row.original)
                    onOpen()
                  }}
                />
              </>
            )}
          </Flex>
        ),
      }),
    ],
    []
  )

  async function handleUserDelete() {
    try {
      setIsLoadingDeletion(true)
      deleteUser(userToDelete?.id as string)
    } catch (err) {
      handleErrorResponse(err)
    } finally {
      setIsLoadingDeletion(false)
      onClose()
    }
  }

  const handleUserPageChange = (page: number) => {
    setActivePage(page as number)
  }

  return (
    <>
      <HStack justifyContent="space-between">
        <Box>
          <Text fontSize="1.25rem">
            Total Users:{' '}
            <Text as="span" color="primary">
              {users.count}
            </Text>
          </Text>
        </Box>
        <CustomButton
          onClick={() => router.push('/users/create')}
          w="6.625rem"
          h="2.75rem"
          mt="2.688rem"
          borderRadius="5px"
          fontSize="0.938rem"
          fontWeight="medium"
        >
          <Icon mr="0.875rem" as={AiOutlinePlus} />
          Add
        </CustomButton>
      </HStack>

      <Flex
        mt="1.25rem"
        mb="1.25rem"
        gap="2.3rem"
        flexDirection={['column', 'row']}
        justifyContent="center"
        alignItems="center"
      >
        <TextInput
          placeholder="Search"
          type="search"
          value={searchTerm}
          handleChange={e => setSearchTerm(e.target.value)}
          TextInputElement={
            <InputRightElement>
              <AiOutlineSearch />
            </InputRightElement>
          }
        />
        {/* <DropdownSelect
          placeholder="All roles"
          selected={''}
          options={[{ value: 'admin', label: 'Admin' }]}
          selectProps={{
            w: '10.063rem',
          }}
          onChange={(e: any) => {}}
        /> */}
      </Flex>

      <Container p="0" ml="0" maxW="full" overflowX="auto">
        <DataTable
          isLoading={isLoading}
          variant="lg-table"
          data={users.rows}
          totalPages={users.total_pages}
          totalRecords={users.count}
          columns={columns}
          isAllowSelection={false}
          handlePageChange={handleUserPageChange}
        />
      </Container>

      <DeleteDialog
        isOpen={isOpen}
        onClose={onClose}
        onClick={handleUserDelete}
        title="Are you sure you want to delete this user?"
        message="This action cannot be undone"
      />
      <Loader isLoading={isLoadingDeletion} />
    </>
  )
}

export default UsersPage
