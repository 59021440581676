import React from 'react'
import {
  Box,
  Container,
  Flex,
  FormControl,
  Icon,
  chakra,
  useColorMode,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  useDisclosure,
  Text,
  List,
  ListItem,
  Stack,
  IconButton,
  HStack,
} from '@chakra-ui/react'
import { AiOutlinePlus } from 'react-icons/ai'
import {
  BsFillCloudUploadFill,
  BsPrinter,
  BsThreeDotsVertical,
} from 'react-icons/bs'
import { BiArrowBack, BiTrash } from 'react-icons/bi'
import { IoMdCheckmark } from 'react-icons/io'
import { useRouter } from 'next/router'
import { createColumnHelper } from '@tanstack/react-table'

import {
  basicTextRgba,
  bgThemeColor,
  primaryThemeColor,
  secondaryThemeColor,
  sideBarThemeColor,
} from '@/lib/constants/colorConstants'
import DataTable from '@/components/DataTable'
import CustomButton from '@/components/Button'
import HeaderTab from '@/components/HeaderTab'
import DropdownSelect from '@/components/DropdownSelect'
import DeleteDialog from '@/components/DeleteDialog'
import {
  getDifficultyTextColor,
  getQuestionTypeInFull,
  hasPermission,
  truncateText,
} from '@/lib/helpers'
import { stores } from '@/stores/index'
import { ChevronDownIcon, ChevronRightIcon } from '@chakra-ui/icons'

import 'suneditor/dist/css/suneditor.min.css'
import 'katex/dist/katex.min.css'
import { useAuthVerify } from '@/hooks/useAuthVerify'

interface IQuestionBankPageProps {
  questions: IQuestionBank
  handleQuestionPageChange: (page: number) => void
  handleQuestionDelete?: (questionId: string) => void
  handleMassQuestionDelete?: (questionIds: string[]) => void
  handleFilter: (value: string) => void
  filter: string
  isLoading: boolean
  viewingMode?: ViewingMode
  selectedQuestions?: IQuestion[]
  handleSelectedQuestion?: (question: IQuestion) => void
}
const QuestionBankPage = ({
  questions,
  isLoading,
  filter,
  handleFilter,
  handleQuestionPageChange,
  handleQuestionDelete,
  handleMassQuestionDelete,
  handleSelectedQuestion,
  selectedQuestions,
  viewingMode = 'page',
}: IQuestionBankPageProps) => {
  const { colorMode } = useColorMode()
  const router = useRouter()
  const hasAddPerm = hasPermission(
    { name: 'question-bank', can: 'add' },
    stores.auth.getMyPermissions()
  )
  const { isFromSchoolNotion } = useAuthVerify()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const {
    isOpen: isOpenMultiple,
    onOpen: onOpenMultiple,
    onClose: onCloseMultiple,
  } = useDisclosure()
  const [selectedQuestionIds, setSelectedQuestionIds] = React.useState<
    string[]
  >([])
  const [questionToDelete, setQuestionToDelete] = React.useState<any>()

  const handleQuestionCheck = (params: IQuestion) => {
    if (!params) return
    if (
      viewingMode === 'modal' &&
      handleSelectedQuestion &&
      typeof handleSelectedQuestion === 'function'
    ) {
      handleSelectedQuestion(params)
    }
  }

  const renderRowSubComponent = React.useCallback(({ row }) => {
    const data = row.original

    return (
      <Box
        mx="10"
        p="5"
        boxShadow="md"
        boxSize="auto"
        rounded="md"
        fontSize="0.875rem"
        fontWeight="normal"
        className="rich-text-table"
      >
        <Text as="span" dangerouslySetInnerHTML={{ __html: data.question }} />
        <List
          display="flex"
          flexWrap="wrap"
          alignItems="center"
          mt="1.813rem"
          gap={4}
        >
          {Object.entries(data.options).map(el => {
            const isOptionArray = Array.isArray(el)
            const option = isOptionArray ? el[0] : el
            return (
              <ListItem
                key={`question_option_${option}`}
                display="flex"
                alignItems="center"
                gap={2}
                color={data.answer?.includes(option) ? 'secondary' : 'initial'}
              >
                {el[0]}:
                <Text
                  as="span"
                  dangerouslySetInnerHTML={{
                    __html: isOptionArray ? el[1] : (el as any),
                  }}
                />
              </ListItem>
            )
          })}
        </List>
      </Box>
    )
  }, [])

  const columnHelper = createColumnHelper<IQuestion>()
  const columns = React.useMemo(
    () => [
      viewingMode &&
        columnHelper.display({
          id: 'expander',
          cell: ({ row }) => (
            <Box
              as="button"
              {...{
                onClick: () => {
                  row.toggleExpanded()
                },
              }}
            >
              {row.getIsExpanded() ? (
                <ChevronDownIcon color="GrayText" boxSize={6} />
              ) : (
                <ChevronRightIcon color="GrayText" boxSize={6} />
              )}
            </Box>
          ),
        }),
      columnHelper.accessor('question', {
        header: 'Question',
        cell: info => (
          <chakra.span
            dangerouslySetInnerHTML={{
              __html: truncateText(info.getValue() as string, 40),
            }}
          />
        ),
      }),
      columnHelper.accessor('difficulty', {
        header: 'Difficulty',
        cell: info => (
          <chakra.span
            color={getDifficultyTextColor(info.getValue() as string)}
            textTransform="capitalize"
          >
            {info.getValue()}
          </chakra.span>
        ),
      }),
      columnHelper.accessor('type', {
        header: 'Type',
        cell: info => getQuestionTypeInFull(info.getValue() as string),
      }),
      columnHelper.accessor('point', {
        header: 'Point',
        cell: info => info.getValue(),
      }),
      viewingMode === 'page'
        ? columnHelper.display({
            id: 'actions',
            cell: ({ row: { original: data } }) => (
              <Flex gap="35px">
                <Menu isLazy placement="bottom" flip preventOverflow>
                  <MenuButton>
                    <Icon
                      aria-label="More"
                      as={BsThreeDotsVertical}
                      color={secondaryThemeColor[colorMode]}
                      _focus={{ boxShadow: 'none' }}
                    />
                  </MenuButton>
                  <MenuList p={0} minW="0" w={'100px'}>
                    <MenuItem onClick={() => handleQuestionEdit(data)}>
                      Edit
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        setQuestionToDelete(data)
                        onOpen()
                      }}
                    >
                      Delete
                    </MenuItem>
                  </MenuList>
                </Menu>
              </Flex>
            ),
          })
        : columnHelper.display({
            id: 'actions',
            cell: ({ row: { original: data } }) => (
              <chakra.span>
                {data.checked && (
                  <Icon as={IoMdCheckmark} color="secondary" fontSize="2rem" />
                )}
              </chakra.span>
            ),
          }),
    ],
    []
  )

  async function handleQuestionEdit(data: any) {
    router.push(`/question-bank/edit/${data?.id}`)
  }

  const comparedQuestions = React.useMemo(() => {
    if (!questions.rows || questions.rows.length === 0) return []

    if (selectedQuestions && selectedQuestions.length === 0) {
      return questions.rows.map(question => ({
        ...question,
        checked: false,
      }))
    }

    const finalQuestions: any[] = []
    questions.rows.forEach((question: any) => {
      const found = selectedQuestions?.find(
        (selectedQuestion: any) => selectedQuestion.id === question.id
      )
      question.checked = !!found
      finalQuestions.push(question)
    })
    return finalQuestions
  }, [selectedQuestions, questions.rows])

  const emptySelectedQuestions = () => {
    // empty selected questions, incase deleted question(s) are still selected
    stores.examadmin.addQuestions([])
  }

  const getQueryKeys = React.useCallback(
    filter =>
      Object.keys(filter)
        .filter(key => filter[key])
        .map(key => `${key}=${filter[key]}`)
        .join('&'),
    []
  )

  const questionCategoryTitle = React.useMemo(() => {
    const query = {
      title: router.query.title,
      class: router.query.class,
      class_arm: router.query.class_arm,
      academic_session: router.query.academic_session,
      academic_term: router.query.academic_term,
    }

    return Object.values(query)
      .filter(value => value !== undefined)
      .join(' | ')
  }, [router.query])

  return (
    <>
      {viewingMode === 'page' && (
        <HStack spacing="1rem" mt="1.25rem" justifyContent="space-between">
          <Box mb="0.5rem">
            <IconButton
              aria-label="back-arrow"
              icon={<BiArrowBack />}
              variant="link"
              onClick={router.back}
              w="1rem"
              h="0.963rem"
            />
            <Text as="span" fontSize="1rem" fontWeight="medium">
              Go Back
            </Text>
          </Box>
          <Box flex="1">
            <Text
              textAlign="center"
              fontSize="lg"
              fontWeight="medium"
              color={secondaryThemeColor[colorMode]}
            >
              {questionCategoryTitle}
            </Text>
          </Box>
        </HStack>
      )}
      <Box bgColor={bgThemeColor[colorMode]}>
        <HeaderTab
          justifyContent={{ base: 'center', md: 'flex-start' }}
          alignItems="center"
          flexWrap="wrap"
          gap={8}
          h={{ base: '7rem', md: '3.938rem' }}
        >
          {hasAddPerm && viewingMode === 'page' && (
            <Stack
              spacing={{ base: 2, md: 6 }}
              direction={{ base: 'column', md: 'row' }}
            >
              <CustomButton
                onClick={() =>
                  router.push(`/question-bank/create/${router.query.id}`)
                }
                maxW="11.25rem"
                h="2.5rem"
                borderRadius="3.95px"
                fontSize="0.875rem"
                fontWeight={{ base: 'medium', md: 'semibold' }}
                bgColor={primaryThemeColor[colorMode]}
              >
                <Icon mr="0.563rem" as={AiOutlinePlus} />
                <Text>Add Question</Text>
              </CustomButton>

              <CustomButton
                onClick={() =>
                  router.push(`/question-bank/import/${router.query.id}`)
                }
                maxW="11.25rem"
                h="2.5rem"
                borderRadius="3.95px"
                fontSize="0.875rem"
                fontWeight={{ base: 'medium', md: 'semibold' }}
                bgColor={primaryThemeColor[colorMode]}
              >
                <Icon mr="0.563rem" as={BsFillCloudUploadFill} />
                <Text> Import Questions</Text>
              </CustomButton>
            </Stack>
          )}
        </HeaderTab>

        <Flex mt="2.25rem" alignItems="center" justifyContent="space-between">
          {viewingMode === 'page' && questions.count > 0 && (
            <HStack gap={5}>
              {selectedQuestionIds?.length > 0 && (
                <CustomButton
                  w="5.063rem"
                  h="1.808rem"
                  borderRadius="2.855px"
                  fontSize="0.75rem"
                  fontWeight="normal"
                  bgColor={sideBarThemeColor[colorMode]}
                  color={'red.500'}
                  border="1px solid red"
                  ml="1.5rem"
                  colorScheme="red"
                  onClick={onOpenMultiple}
                  disabled={selectedQuestionIds?.length === 0}
                >
                  <Icon mr="0.563rem" as={BiTrash} />
                  Delete
                </CustomButton>
              )}

              {isFromSchoolNotion && selectedQuestionIds?.length > 0 && (
                <CustomButton
                  w="5.063rem"
                  h="1.808rem"
                  borderRadius="2.855px"
                  fontSize="0.75rem"
                  fontWeight="normal"
                  bgColor={sideBarThemeColor[colorMode]}
                  color={primaryThemeColor[colorMode]}
                  border={`1px solid ${primaryThemeColor[colorMode]}`}
                  onClick={() => {
                    router.push(
                      `/question-bank/category/${
                        router.query.id
                      }/print?type=selection&${getQueryKeys({
                        title: router.query.title,
                        class: router.query.class,
                        class_arm: router.query.class_arm,
                        academic_session: router.query.academic_session,
                        academic_term: router.query.academic_term,
                      })}`
                    )
                  }}
                >
                  <Icon mr="0.563rem" as={BsPrinter} />
                  Print
                </CustomButton>
              )}
            </HStack>
          )}
          <Box />
          <FormControl
            mr="1rem"
            w={{ base: '5rem', md: '9.625rem' }}
            h="2.125rem"
            alignSelf="end"
          >
            <DropdownSelect
              placeholder="Filter"
              selected={filter}
              options={[
                { value: '', label: 'None' },
                { value: 'multiple_choice', label: 'Multiple Choice' },
                { value: 'multiple_response', label: 'Multiple Response' },
                { value: 'true_false', label: 'True/False' },
                { value: 'passage', label: 'Passage' },
              ]}
              onChange={(e: any) => handleFilter(e.target.value as string)}
            />
          </FormControl>
        </Flex>

        <Box mt="1.25rem"></Box>
        <Container ml="0" maxW="full" overflowX="auto">
          <DataTable
            isLoading={isLoading}
            columns={columns}
            data={viewingMode === 'page' ? questions.rows : comparedQuestions}
            totalPages={questions.total_pages}
            totalRecords={questions.count}
            currentPage={questions.current_page}
            handlePageChange={handleQuestionPageChange}
            onRowSelected={(rows: any[]) => {
              const questionIds = rows.map((row: any) => row.id)
              stores.examadmin.setSelectedQuestionIds(questionIds)
              setSelectedQuestionIds(questionIds)
            }}
            onRowClick={handleQuestionCheck}
            isAllowSelection={viewingMode === 'page'}
            variant={viewingMode === 'page' ? 'lg-table' : 'lg-selectable'}
            {...(viewingMode && { renderRowSubComponent })}
          />
        </Container>
      </Box>
      {/* Single Deletion Confirmation */}
      <DeleteDialog
        isOpen={isOpen}
        onClick={() => {
          handleQuestionDelete && handleQuestionDelete(questionToDelete.id)
          onClose()
          emptySelectedQuestions()
        }}
        onClose={onClose}
        title="Are you sure you want to delete the selected Item?"
        message="This action cannot be undone"
      />
      {/* Multiple Deletion Confirmation */}
      <DeleteDialog
        isOpen={isOpenMultiple}
        onClick={() => {
          handleMassQuestionDelete &&
            handleMassQuestionDelete(selectedQuestionIds as string[])
          onCloseMultiple()
          emptySelectedQuestions()
        }}
        onClose={onCloseMultiple}
        title="Are you sure you want to delete all the selected Items?"
        message="This action cannot be undone"
      />
    </>
  )
}

export default QuestionBankPage
