import React from 'react'
import { Box, BoxProps, Flex, Icon, Text, useColorMode } from '@chakra-ui/react'

import { bgThemeColor, primaryThemeColor } from '@/lib/constants/colorConstants'

interface INotifyTable extends BoxProps {
  title: string
  icon?: any
  renderRight?: React.ReactElement
  children?: React.ReactNode
}
function NotifyTable({
  title,
  icon,
  renderRight,
  children,
  ...rest
}: INotifyTable) {
  const { colorMode } = useColorMode()
  return (
    <Box
      w="full"
      h="207px"
      shadow="md"
      borderBottomStartRadius="md"
      borderBottomEndRadius="md"
      overflowY="auto"
      bg={bgThemeColor[colorMode]}
      overflowX="hidden"
      pos="relative"
      {...rest}
    >
      {/* Header */}
      <Box
        display="flex"
        w="full"
        flex="1"
        borderTopStartRadius="md"
        borderTopEndRadius="md"
        h="40px"
        bg={primaryThemeColor[colorMode]}
        px="20px"
        alignItems="center"
        pos="sticky"
        top="0"
        zIndex="999"
      >
        <Flex flex="1" alignItems="center" justifyContent="space-between">
          <Flex alignItems="center" gap="20px">
            <Icon as={icon} color="white" />
            <Text color="white" fontSize="0.75rem" fontWeight="medium">
              {title}
            </Text>
          </Flex>
          {renderRight}
        </Flex>
      </Box>

      {/* Row */}
      <Box pb="4" overflowX="auto">
        {children}
      </Box>
    </Box>
  )
}

export default NotifyTable
