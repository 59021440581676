import { Dispatch, SetStateAction, useState, useEffect } from 'react'
import { useQuery, useQueryClient } from 'react-query'

import { axiosInstance } from '@/lib/helpers'
import { queryKeys } from '@/lib/react-query/constants'
import { useDebounce } from '@/hooks/useDebounce'

// common options for both useQuery and prefetchQuery
const commonOptions = {
  staleTime: 0,
  cacheTime: 300000, // 5 minutes
}

async function getExam(options: any): Promise<QuestionCategory[]> {
  const {
    data: { data },
  } = await axiosInstance.get('/organization/exam', { params: options })
  return data
}

interface useExamAdmin {
  exams: IExamList
  isLoading: boolean
  activePage: number
  setActivePage: Dispatch<SetStateAction<number>>
  filter: string
  setFilter: Dispatch<SetStateAction<string>>
  group: string
  setGroup: Dispatch<SetStateAction<string>>
  setSearchTerm: Dispatch<SetStateAction<string>>
  searchTerm: string
}

export function useExamAdmin(): useExamAdmin {
  const queryClient = useQueryClient()

  const [filter, setFilter] = useState<string>('')
  const [activePage, setActivePage] = useState<number>(1)
  const [group, setGroup] = useState<string | any>()
  const [searchTerm, setSearchTerm] = useState<string>('')

  const debouncedSearchTerm = useDebounce(searchTerm, 500)

  useEffect(() => {
    const nextPage = activePage + 1
    queryClient.prefetchQuery(
      [queryKeys.examsAdmin, nextPage],
      () => getExam({ page: nextPage, group }),
      commonOptions
    )
  }, [queryClient, group, activePage])

  // Reset `activePage` when group and search term changes
  useEffect(() => {
    setActivePage(1)
  }, [group, debouncedSearchTerm, filter])

  useEffect(() => {
    setFilter('')
  }, [group])

  const fallback = {
    count: 0,
    received: 0,
    total_pages: 0,
    current_page: 0,
    rows: [],
  } as any

  const { data: exams = fallback, isLoading } = useQuery(
    [queryKeys.examsAdmin, group, activePage, debouncedSearchTerm, filter],
    () =>
      getExam({
        page: activePage,
        group,
        search: debouncedSearchTerm,
        status: filter,
      }),
    {
      ...commonOptions,
      // enabled: !!group,
      refetchOnMount: true,
      refetchOnReconnect: true,
      refetchOnWindowFocus: true,
    }
  )

  return {
    exams,
    isLoading,
    activePage,
    setActivePage,
    filter,
    setFilter,
    group,
    setGroup,
    setSearchTerm,
    searchTerm,
  }
}
