export const PictureFormatConstants = [
  {
    id: 1,
    title: 'Supports PNG, JPG and JPEG formats only',
  },
  {
    id: 2,
    title: 'Must not be greater than 20MB',
  },
  {
    id: 3,
    title: 'Size is 1080px by 1080px',
  },
]
