import React from 'react'
import { useRouter } from 'next/router'
import { Center, Text, Box, Flex } from '@chakra-ui/react'
import CustomImage from '@/components/Image'
import AvailableResults from './components/AvailableResults'
import { useResult } from './hooks/useResult'

export default function UserResultPage() {
  const router = useRouter()
  const { results, isLoading } = useResult()

  return (
    <>
      {results.rows.length <= 0 ? (
        <Center display="flex" flexDir="column">
          <CustomImage
            mt="7.5rem"
            width="153.67px"
            height="150px"
            src="/undraw_no_data_re_kwbl 1.svg"
          />
          <Text mt="1.5rem" fontSize={['1rem', '1.25rem']} fontWeight="medium">
            No data available!
          </Text>
        </Center>
      ) : (
        <>
          <Text mb="1.6rem" fontSize="1.25rem" fontWeight="medium">
            Results
          </Text>
          <Flex flexWrap="wrap" gap={4}>
            {results.rows.map(data => (
              <AvailableResults
                key={`result_${data.exam_id}`}
                data={data}
                handleViewResult={() => router.push(`/result/${data.exam_id}`)}
              />
            ))}
          </Flex>
        </>
      )}
    </>
  )
}
