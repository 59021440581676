import { useRouter } from 'next/router'
import { useState } from 'react'
import {
  Tabs,
  TabPanels,
  TabPanel,
  TabList,
  useColorMode,
} from '@chakra-ui/react'
import Company from '../company'
import TabData from './TabData'
import CustomDomain from '../customDomain/cutomDomain'
import Password from '../password/Password'
import { bgThemeColor } from '@/lib/constants/colorConstants'
import Personalize from './PersonalizeTab'

interface ISettingsAuthTab {
  type: SettingPageType
}

const pageMap = {
  Company: 0,
  Personalize: 1,
  Adddomain: 2,
  Password: 3,
}

const pageIndexArr = ['company', 'personalize', 'adddomain', 'password']

const SettingsAuthTab: React.FC<ISettingsAuthTab> = ({ type }) => {
  const router = useRouter()
  const { colorMode } = useColorMode()
  const [tabIndex, setTabIndex] = useState(pageMap[type])

  const handleTabsChange = (index: any) => {
    setTabIndex(index)
    router.push(`/settings/${pageIndexArr[index]}`)
  }

  return (
    <>
      <Tabs
        mt={6}
        index={tabIndex}
        onChange={handleTabsChange}
        bg={bgThemeColor[colorMode]}
        pb="3rem"
      >
        <TabList
          overflowX={{ base: 'auto', md: 'initial' }}
          ml={2}
          boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
        >
          <TabData />
        </TabList>
        <TabPanels position="relative">
          <TabPanel padding={0}>
            <Company />
          </TabPanel>
          <TabPanel padding={0}>
            <Personalize />
          </TabPanel>
          {/* <TabPanel padding={0}>
            <CustomDomain />
          </TabPanel> */}
          <TabPanel padding={0}>
            <Password />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </>
  )
}

export default SettingsAuthTab
