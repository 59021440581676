import { SideBarOptionMenu } from '@/types/sidebar'
import { MdNotificationsNone } from 'react-icons/md'

export const notificationMenu: SideBarOptionMenu = {
  name: 'Notification',
  icon: MdNotificationsNone,
  path: '/notification',
  order: 1,
  permission: { name: 'notification', can: 'visible' },
  isShown: true,
  isActive: false,
  child: [],
}
