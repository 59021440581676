import { useQuery } from 'react-query'

import { axiosInstance } from '@/lib/helpers'
import { queryKeys } from '@/lib/react-query/constants'

// common options for both useQuery and prefetchQuery
const commonOptions = {
  staleTime: 0,
  cacheTime: 300000, // 5 minutes
}

async function getOrgStats(): Promise<any> {
  const {
    data: { data },
  } = await axiosInstance.get('/organization/stats/summary')
  return data
}

interface useStatistic {
  stats: IOrgStatistic
  refetchWallet: () => Promise<any>
  isLoading: boolean
}

export function useStatistic(): useStatistic {
  const fallback = {
    total_exams: 0,
    total_candidates: 0,
    total_roles: 0,
  } as IOrgStatistic

  const {
    data: stats = fallback,
    isLoading,
    refetch,
  } = useQuery([queryKeys.orgStatistic], () => getOrgStats(), {
    ...commonOptions,
    refetchOnMount: true,
    refetchOnReconnect: true,
    refetchOnWindowFocus: true,
  })

  return {
    stats,
    refetchWallet: refetch,
    isLoading,
  }
}
