import {
  Dispatch,
  SetStateAction,
  useCallback,
  useState,
  useEffect,
} from 'react'
import { useQuery, useQueryClient } from 'react-query'

import { axiosInstance } from '@/lib/helpers'
import { queryKeys } from '@/lib/react-query/constants'

// common options for both useQuery and prefetchQuery
const commonOptions = {
  staleTime: 0,
  cacheTime: 300000, // 5 minutes
}

async function getRolesAndPermissions(
  options: any
): Promise<QuestionCategory[]> {
  const {
    data: { data },
  } = await axiosInstance.get('/organization/role_permissions', {
    params: options,
  })
  return data
}

interface useRoleAndPermissions {
  rolesAndPermissions: IDataResponse
  isLoading: boolean
  activePage: number
  setActivePage: Dispatch<SetStateAction<number>>
  searchTerm: string
  setSearchTerm: Dispatch<SetStateAction<string>>
}

const filterRoles = (data: IDataResponse, searchTerm: string) => {
  if (!searchTerm) return data
  return {
    ...data,
    rows: data.rows.filter(role =>
      role.name.toLowerCase().includes(searchTerm.toLowerCase())
    ),
  }
}

export function useRoleAndPermissions(): useRoleAndPermissions {
  const queryClient = useQueryClient()

  const [searchTerm, setSearchTerm] = useState('')
  const [activePage, setActivePage] = useState<number>(1)

  const selectFn = useCallback(
    data => filterRoles(data, searchTerm),
    [searchTerm]
  )

  useEffect(() => {
    const nextPage = activePage + 1
    queryClient.prefetchQuery(
      [queryKeys.rolesAndPermissions, nextPage],
      () => getRolesAndPermissions({ page: nextPage }),
      commonOptions
    )
  }, [queryClient, activePage])

  const fallback = {
    count: 0,
    received: 0,
    total_pages: 0,
    current_page: 0,
    rows: [],
  } as any

  const { data: rolesAndPermissions = fallback, isLoading } = useQuery(
    [queryKeys.rolesAndPermissions, activePage],
    () => getRolesAndPermissions({ page: activePage }),
    {
      ...commonOptions,
      refetchOnMount: true,
      refetchOnReconnect: true,
      refetchOnWindowFocus: true,
      select: !searchTerm ? undefined : selectFn,
    }
  )

  return {
    rolesAndPermissions,
    isLoading,
    activePage,
    setActivePage,
    searchTerm,
    setSearchTerm,
  }
}
