import { SideBarOptionMenu } from '@/types/sidebar'
import { BsJournalText } from 'react-icons/bs'

export const examsAdminMenu: SideBarOptionMenu = {
  name: 'Exams',
  icon: BsJournalText,
  path: '/exams',
  order: 1,
  permission: { name: 'exams', can: 'visible' },
  isShown: true,
  isActive: false,
  child: [],
}
