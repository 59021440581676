import { UseMutateFunction, useMutation, useQueryClient } from 'react-query'
import { useToast } from '@chakra-ui/react'

import { axiosInstance } from '@/lib/helpers'
import { queryKeys } from '@/lib/react-query/constants'

// for when server call is needed
async function deleteExam(exam_id: string | string[]): Promise<void> {
  if (exam_id instanceof Array) {
    await Promise.all(
      exam_id.map(async id => {
        await axiosInstance.delete(`/organization/exam/${id}`)
      })
    )
    return
  }

  await axiosInstance.delete(`/organization/exam/${exam_id}`)
}

export function useDeleteExam(): UseMutateFunction<
  void,
  unknown,
  string | string[],
  unknown
> {
  const queryClient = useQueryClient()
  const toast = useToast()

  const { mutate } = useMutation(deleteExam, {
    onSuccess: () => {
      queryClient.invalidateQueries([queryKeys.examsAdmin])
      toast({
        title: 'You have successfully deleted the exam',
        status: 'warning',
      })
    },
  })

  return mutate
}
