import React from 'react'
import { Flex, Box, Text, useColorMode } from '@chakra-ui/react'
import LEColorConstants, { bgThemeColor } from '@/lib/constants/colorConstants'

type IDashBoardCard = {
  title: string
  titleTotal: number
  renderRightTop?: React.ReactElement
  renderRightBottom?: React.ReactElement
}

function DashBoardCard({
  title,
  renderRightTop,
  titleTotal,
  renderRightBottom,
}: IDashBoardCard) {
  const { colorMode } = useColorMode()
  return (
    <Box
      borderRadius="10px"
      border="1px solid #E4E4E7"
      w="full"
      p="0.8rem 0.5rem"
      bg={bgThemeColor[colorMode]}
      flexDir={'column'}
      role="group"
      _hover={{
        borderColor: LEColorConstants.primary,
      }}
    >
      <Flex justifyContent={'space-between'} mb="1rem">
        <Text
          fontSize={'0.6rem'}
          fontWeight={500}
          letterSpacing={1}
          textTransform="uppercase"
          color={'#71717A'}
          _groupHover={{
            color: LEColorConstants.primary,
          }}
        >
          {title}
        </Text>
        {renderRightTop}
      </Flex>
      <Flex justifyContent={'space-between'}>
        <Text fontSize={'1.2rem'} fontWeight={600}>
          {titleTotal}
        </Text>
        {renderRightBottom}
      </Flex>
    </Box>
  )
}

export default DashBoardCard
