import React from 'react'
import { Chart as ChartJS, ArcElement, Title, Tooltip, Legend } from 'chart.js'
import { Pie } from 'react-chartjs-2'
import { Box } from '@chakra-ui/react'
ChartJS.register(ArcElement, Title, Tooltip, Legend)

type IChart = {
  dataChart: any
  chartOptions: any
}

export default function PieChart({ dataChart, chartOptions }: IChart) {
  const { chartData } = dataChart

  return (
    <Box>
      <Pie
        style={{
          height: '180px',
          width: '180px',
        }}
        options={chartOptions}
        data={chartData}
      />
    </Box>
  )
}
