import React from 'react'
import { Grid, Box, Flex } from '@chakra-ui/react'
import { colorComponentConstants } from '@/lib/constants/colorComponentConstants'
import ColorComponent from './ColorComponent'
import CustomButton from '@/components/Button'

const Personalize = () => {
  const [colorData, setColorData] = React.useState([...colorComponentConstants])

  const handleColorChange = (colorIndex: number, color: any) => {
    if (!color) return

    const newColorData = [...colorData]

    const newColor = newColorData[colorIndex]
    if (newColor) {
      newColor.color = color

      setColorData(newColorData)
    }
  }

  const handleSubmit = () => {}

  return (
    <Box mt="5.4375rem" ml={'2rem'} mr="1rem">
      <Grid columnGap={8} templateColumns={['1fr', 'repeat(2,1fr)']}>
        <ColorComponent
          handleChange={handleColorChange}
          colorProps={colorData}
        />
      </Grid>
      <Flex>
        <CustomButton
          w="6.625rem"
          h="2.75rem"
          m="0 auto"
          mt="2.688rem"
          borderRadius="5px"
          fontSize="0.938rem"
          fontWeight="medium"
          onClick={handleSubmit}
        >
          Save
        </CustomButton>
      </Flex>
    </Box>
  )
}

export default Personalize
