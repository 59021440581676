import { useRouter } from 'next/router'
import CustomButton from '@/components/Button'
import {
  Box,
  Flex,
  FormControl,
  HStack,
  Icon,
  IconButton,
  Text,
  useColorMode,
  useDisclosure,
} from '@chakra-ui/react'
import React from 'react'
import formdata from '@/lib/constants/formData/search'
import { TextInput } from '@/components/Input'

import { AiFillEdit, AiOutlinePlus } from 'react-icons/ai'
import DataTable from '@/components/DataTable'
import {
  basicTextRgba,
  primaryThemeColor,
} from '@/lib/constants/colorConstants'
import { BiTrash } from 'react-icons/bi'
import { useRoleAndPermissions } from './hooks/useRoleAndPermissions'
import { TablePageLoader } from '@/components/SkeletonLoaders'
import DeleteDialog from '@/components/DeleteDialog'
import { useDeleteRoleAndPermissions } from './hooks/useDeleteRoleAndPermissions'
import { createColumnHelper } from '@tanstack/react-table'

function RolesPage() {
  const router = useRouter()
  const { colorMode } = useColorMode()
  const { rolesAndPermissions, isLoading, searchTerm, setSearchTerm } =
    useRoleAndPermissions()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const deleteRoleAndPermissions = useDeleteRoleAndPermissions()

  const [roleToDelete, setRoleToDelete] = React.useState<any>()

  const columnHelper = createColumnHelper<IRole>()
  const columns = React.useMemo(
    () => [
      columnHelper.accessor('name', {
        header: 'Name',
        cell: info => info.getValue(),
      }),
      columnHelper.display({
        id: 'actions',
        cell: ({ row: { original: data } }) => (
          <Flex gap="35px">
            <IconButton
              aria-label="Edit a role"
              icon={<AiFillEdit />}
              color={primaryThemeColor[colorMode]}
              variant="ghost"
              _focus={{ boxShadow: 'none' }}
              onClick={() => handleRoleEdit(data.id)}
            />
            <Flex
              boxSize="30px"
              rounded="full"
              justifyContent="center"
              alignItems="center"
              bg="gray.200"
            >
              <IconButton
                aria-label="Delete a role"
                icon={<BiTrash />}
                color={basicTextRgba[colorMode]}
                variant="link"
                _focus={{ boxShadow: 'none' }}
                onClick={() => {
                  setRoleToDelete(data)
                  onOpen()
                }}
              />
            </Flex>
          </Flex>
        ),
      }),
    ],
    []
  )

  async function handleRoleEdit(role_id: string) {
    router.push(`/roles/edit/${role_id}`)
  }

  function handleRoleDelete(role_id: string) {
    deleteRoleAndPermissions(role_id)
  }

  const handleChange = (e: any) => {
    setSearchTerm(e.target.value)
  }

  if (isLoading) {
    return <TablePageLoader />
  }

  return (
    <>
      <Box>
        <HStack justifyContent="space-between">
          <Box>
            <Text fontSize="1.25rem">
              Total Roles Created:{' '}
              <Text as="span" color="primary">
                {rolesAndPermissions.count}
              </Text>
            </Text>
          </Box>
          <CustomButton
            onClick={() => router.push('/roles/create')}
            w="6.625rem"
            h="2.75rem"
            mt="2.688rem"
            borderRadius="5px"
            fontSize="0.938rem"
            fontWeight="medium"
          >
            <Icon mr="0.875rem" as={AiOutlinePlus} />
            Add
          </CustomButton>
        </HStack>

        <Flex mt="1rem" mb="2.125rem" justify="center">
          <Box>
            <form action="post">
              {formdata.map((data, i) => (
                <FormControl key={`search_${i}`}>
                  <TextInput
                    name={data.name}
                    type={data.type}
                    value={searchTerm}
                    handleChange={handleChange}
                    labelProps={{
                      fontSize: '0.875rem',
                      fontWeight: 'medium',
                    }}
                    formControlProps={{
                      w: { base: 'auto', lg: '21.625rem' },
                      _last: { pb: '1.563rem' },
                    }}
                    TextInputProps={{
                      h: '2.5rem',
                      borderRadius: '5px',
                      mb: '0.188rem',
                    }}
                    placeholder={data.label}
                  />
                </FormControl>
              ))}
            </form>
          </Box>
        </Flex>

        <DataTable
          variant="lg-table"
          data={rolesAndPermissions.rows}
          columns={columns}
          totalPages={rolesAndPermissions.total_pages}
        />
      </Box>
      <DeleteDialog
        isOpen={isOpen}
        onClick={() => {
          handleRoleDelete(roleToDelete.id)
          onClose()
        }}
        onClose={onClose}
        title="Are you sure you want to delete the selected Role?"
        message="This action cannot be undone"
      />
    </>
  )
}

export default RolesPage
