import React from 'react'
import { Box, Flex, Text } from '@chakra-ui/react'
import moment from 'moment-timezone'
import CountdownFuture from '@/components/CountdownFuture/CountdownFuture'
import LEColorConstants from '@/lib/constants/colorConstants'

type timeProps = {
  timeDate: Date | any
  timezone: string
  status: string
  expireDate: string | any
  startDate: string | any
}

export default function ExamTime({
  timeDate,
  status,
  timezone,
  expireDate,
  startDate,
}: timeProps) {
  function capitalizeFirstLetter(leter: string) {
    return leter.charAt(0).toUpperCase() + leter.slice(1)
  }

  return (
    <>
      <Box maxW={['full', 'full', 'full', '10.563rem']} maxH="8.938rem">
        <Flex
          boxShadow={['none', 'none', 'none', 'md']}
          borderRadius="lg"
          flexDir="column"
          justifyContent="space-evenly"
          alignItems={['left', 'left', 'left', 'center']}
          p={['0', '0', '0', '12px']}
        >
          <Box display={['none', 'none', 'none', 'block']}>
            <Box
              fontSize={['0.8rem', '1rem']}
              fontWeight="medium"
              textAlign="center"
              color="secondary"
            >
              {capitalizeFirstLetter(status)}:{' '}
              {moment(timeDate).format('Do/MMM/YYYY')}{' '}
              {moment(timeDate).format('hh:mm a')}
            </Box>
          </Box>
          <Text
            fontSize={'0.8rem'}
            fontWeight="medium"
            color={LEColorConstants.secondary}
            display={['block', 'block', 'block', 'none']}
          >{`${status === 'active' ? 'Expiring' : 'Active'} Date: ${
            status === 'active'
              ? moment(expireDate).format('Do MMM YYYY, h:mm a')
              : moment(startDate).format('Do MMM YYYY, h:mm a')
          }`}</Text>

          <CountdownFuture date={timeDate} />
        </Flex>
      </Box>
    </>
  )
}
