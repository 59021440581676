import React from 'react'
import { useRouter } from 'next/router'
import { ChevronDownIcon, ChevronRightIcon } from '@chakra-ui/icons'
import {
  Box,
  Container,
  chakra,
  Flex,
  FormControl,
  Icon,
  Input,
  useColorMode,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  useDisclosure,
  List,
  ListItem,
  Text,
  Select,
  Stack,
  Tooltip,
  InputRightElement,
} from '@chakra-ui/react'
import { AiOutlinePlus, AiOutlineSearch } from 'react-icons/ai'
import { BiTrash, BiUserPlus } from 'react-icons/bi'
import { BsFillCloudUploadFill, BsThreeDotsVertical } from 'react-icons/bs'
import { FaGraduationCap } from 'react-icons/fa'
import { FiLink2 } from 'react-icons/fi'
import { IoMdCheckmark } from 'react-icons/io'
import { createColumnHelper } from '@tanstack/react-table'

import CustomButton from '@/components/Button'
import DataTable from '@/components/DataTable'
import DeleteDialog from '@/components/DeleteDialog'
import HeaderTab from '@/components/HeaderTab'

import LEColorConstants, {
  basicTextRgba,
  brandPrimayColor,
  brandSecondaryColor,
  primaryThemeColor,
  secondaryThemeColor,
  sideBarThemeColor,
} from '@/lib/constants/colorConstants'
import CustomModal from '@/components/Modal'
import ActivateDialog from '@/components/ActivateDialog/ActivateDialog'
import { TablePageLoader } from '@/components/SkeletonLoaders'
import { useStores } from '@/stores/index'
import { observer } from 'mobx-react-lite'
import { useWallet } from '../Dashboard/hooks/useWallet'
import {
  axiosInstance,
  getStatusTextColor,
  handleErrorResponse,
} from '@/lib/helpers'
import Loader from '@/components/Loader/Loader'
import moment from 'moment-timezone'
import { useAuthVerify } from '@/hooks/useAuthVerify'
import { TextInput } from '@/components/Input'

interface ICandidateListProps {
  candidates: ICandidateList
  candidateGroups?: CandidateGroup[]
  setGroup?: (group: string) => void
  searchTerm: string
  setSearchTerm: (searchTerm: string) => void
  handleCandidatePageChange: (page: number) => void
  handleCandidateDelete?: (candidateId: string) => void
  handleMassCandidateDelete?: (candidateId: string[]) => void
  handleFilter: (value: string) => void
  handleSelectedCandidate?: (candidate: ICandidate) => void
  selectedCandidates?: ICandidate[]
  group?: string
  filter: string
  isLoading: boolean
  allowModification?: boolean
}

function CandidatesPage({
  candidates,
  candidateGroups,
  setGroup,
  searchTerm,
  setSearchTerm,
  group,
  isLoading,
  filter,
  handleFilter,
  handleCandidatePageChange,
  handleCandidateDelete,
  handleMassCandidateDelete,
  handleSelectedCandidate,
  selectedCandidates,
  allowModification = true,
}: ICandidateListProps) {
  const router = useRouter()
  const { config } = useStores()
  const { wallet } = useWallet()
  const { isFromSchoolNotion } = useAuthVerify()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { colorMode } = useColorMode()
  const {
    isOpen: isOpenMultiple,
    onOpen: onOpenMultiple,
    onClose: onCloseMultiple,
  } = useDisclosure()
  const {
    isOpen: isOpenActivate,
    onOpen: onOpenActivate,
    onClose: onCloseActivate,
  } = useDisclosure()
  const {
    isOpen: isOpenActivateConfirmation,
    onOpen: onOpenActivateConfirmation,
    onClose: onCloseActivateConfirmation,
  } = useDisclosure()
  const {
    isOpen: isOpenInsufficientBalance,
    onOpen: onOpenInsufficientBalance,
    onClose: onCloseInsufficientBalance,
  } = useDisclosure()
  const {
    isOpen: isOpenSuccessfulActivation,
    onOpen: onOpenSuccessfulActivation,
    onClose: onCloseSuccessfulActivation,
  } = useDisclosure()

  const [isLoadingRequest, setIsLoadingRequest] = React.useState<boolean>(false)
  const [selectedCandidateIds, setSelectedCandidateIds] = React.useState<
    string[]
  >([])
  const [candidateToDelete, setCandidateToDelete] = React.useState<any>()
  const [selectedCandidateData, setSelectedCandidateData] =
    React.useState<any[]>()
  const [activateData, setActivateData] = React.useState<any>({})

  const handleCreatedMode = (created_mode: string) => {
    switch (created_mode) {
      case 'manual':
        return BiUserPlus
      case 'upload':
        return BsFillCloudUploadFill
      case 'link':
        return FiLink2
      default:
        return FaGraduationCap
    }
  }

  const payg_unit_rate = config.getUnits().payg_unit_per_exam
  const standard_unit_rate = config.getUnits().standard_unit_per_exam

  const getPlanUnit = (plan: string) => {
    switch (plan) {
      case 'payg':
        return payg_unit_rate
      case 'standard':
        return standard_unit_rate
      default:
        return 0
    }
  }

  const getPlanTextColor = (plan: string) => {
    switch (plan) {
      case 'payg':
        return LEColorConstants.primary
      case 'pending':
        return LEColorConstants.secondary
      default:
        return 'black'
    }
  }

  const handleActivateCandidate = async () => {
    try {
      setIsLoadingRequest(true)
      const totalUnitCost =
        selectedCandidateIds?.length * getPlanUnit(activateData?.type)

      if (wallet?.balance < totalUnitCost) {
        onOpenInsufficientBalance()
        return
      }
      await axiosInstance.post('/organization/candidate/activate', {
        candidates: selectedCandidateIds,
        plan: activateData.type,
      })

      onOpenSuccessfulActivation()
    } catch (err) {
      handleErrorResponse(err)
    } finally {
      setIsLoadingRequest(false)
    }
  }

  const handleActiavtePAYG = () => {
    const data = {
      type: 'payg',
      candidates: selectedCandidateData,
    }
    setActivateData(data)
    onCloseActivate()
    onOpenActivateConfirmation()
  }

  const handleActiavteStandard = () => {
    const data = {
      type: 'standard',
      candidates: selectedCandidateData,
    }
    setActivateData(data)
    onCloseActivate()
    onOpenActivateConfirmation()
  }

  const handleCandidateCheck = (params: any) => {
    if (!params) return
    if (
      !allowModification &&
      handleSelectedCandidate &&
      typeof handleSelectedCandidate === 'function'
    ) {
      handleSelectedCandidate(params)
    }
  }

  const comparedCandidates = React.useMemo(() => {
    if (!candidates.rows || candidates.rows.length === 0) return []

    if (selectedCandidates && selectedCandidates.length === 0) {
      return candidates.rows.map(candidate => ({
        ...candidate,
        checked: false,
      }))
    }

    const finalCandidates: any[] = []
    candidates.rows.forEach((candidate: any) => {
      const found = selectedCandidates?.find(
        (selectedCandidate: any) => selectedCandidate.id === candidate.id
      )
      candidate.checked = !!found
      finalCandidates.push(candidate)
    })
    return finalCandidates
  }, [selectedCandidates, candidates.rows])

  // Create a function that will render our row sub components
  const renderRowSubComponent = React.useCallback(({ row }) => {
    const data = row.original
    return (
      <Box mx="10" p="5" pt="0" boxShadow="md" boxSize="auto" rounded="md">
        <List>
          <ListItem p="2">
            <Text as="span" fontWeight="medium" mr="2">
              Id:
            </Text>
            {data.id}
          </ListItem>
          <ListItem p="2">
            <Text as="span" fontWeight="medium" mr="2">
              Candidate Id:
            </Text>
            {data.candidate_id}
          </ListItem>
          <ListItem p="2">
            <Text as="span" fontWeight="medium" mr="2">
              Name:
            </Text>
            {data.name}
          </ListItem>
          <ListItem p="2">
            <Text as="span" fontWeight="medium" mr="2">
              Email:
            </Text>
            {data.email}
          </ListItem>
          <ListItem p="2">
            <Text as="span" fontWeight="medium" mr="2">
              Phone:
            </Text>
            {data.phone}
          </ListItem>
          <ListItem p="2">
            <Text as="span" fontWeight="medium" mr="2">
              Gender:
            </Text>
            {data.gender}
          </ListItem>
          <ListItem p="2">
            <Text as="span" fontWeight="medium" mr="2">
              Group:
            </Text>
            {data.group}
          </ListItem>
          {data.plan_name && (
            <ListItem p="2">
              <Text as="span" fontWeight="medium" mr="2">
                Plan Name:
              </Text>
              {data.plan_name}
            </ListItem>
          )}
          {data.current_period_starts && (
            <ListItem p="2">
              <Text as="span" fontWeight="medium" mr="2">
                Current Plan Starts:
              </Text>
              {moment(data.current_period_starts).format('DD MMM YYYY')}
            </ListItem>
          )}
          {data.current_period_ends && (
            <ListItem p="2">
              <Text as="span" fontWeight="medium" mr="2">
                Current Plan Ends:
              </Text>
              {moment(data.current_period_ends).format('DD MMM YYYY')}
            </ListItem>
          )}
          <ListItem p="2">
            <Text as="span" fontWeight="medium" mr="2">
              Creation Mode:
            </Text>
            {data.created_mode}
          </ListItem>
          <ListItem p="2">
            <Text as="span" fontWeight="medium" mr="2">
              Status:
            </Text>

            <Text
              as="span"
              textTransform="capitalize"
              color={getStatusTextColor(data.status)}
            >
              {data.status}
            </Text>
          </ListItem>
        </List>
      </Box>
    )
  }, [])

  const columnHelper = createColumnHelper<ICandidate>()
  const columns = React.useMemo(
    () =>
      [
        allowModification &&
          columnHelper.display({
            id: 'expander',
            cell: ({ row }) => (
              <Box
                as="button"
                {...{
                  onClick: () => {
                    row.toggleExpanded()
                  },
                }}
              >
                {row.getIsExpanded() ? (
                  <ChevronDownIcon color="GrayText" boxSize={6} />
                ) : (
                  <ChevronRightIcon color="GrayText" boxSize={6} />
                )}
              </Box>
            ),
          }),
        columnHelper.accessor('name', {
          header: 'Name',
          cell: info => (
            <chakra.span textTransform="capitalize">
              {info.getValue()}
            </chakra.span>
          ),
        }),

        !isFromSchoolNotion &&
          columnHelper.accessor('email', {
            header: 'Email',
            cell: info => info.getValue(),
          }),
        isFromSchoolNotion &&
          columnHelper.accessor('candidate_id', {
            header: 'Student ID',
            cell: info => info.getValue(),
          }),
        columnHelper.accessor('group', {
          header: 'Group',
          cell: info => info.getValue(),
        }),
        !isFromSchoolNotion &&
          columnHelper.accessor('plan_name', {
            header: 'Plan',
            cell: info => (
              <Flex gap={2}>
                {info.getValue() ? (
                  <>
                    <Text
                      textTransform="capitalize"
                      color={getPlanTextColor('payg')}
                    >
                      {info.getValue()}
                    </Text>
                    <Tooltip label="Plan usage counts" fontSize="xs">
                      <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        rounded="full"
                        w="1.125rem"
                        h="1.125rem"
                        color="white"
                        fontSize="0.5rem"
                        fontWeight="normal"
                        bgColor="black"
                      >
                        {info.row.original.plan_usage_counts}
                      </Box>
                    </Tooltip>
                  </>
                ) : (
                  <Text textTransform="capitalize" color="red">
                    Not Activated
                  </Text>
                )}
              </Flex>
            ),
          }),

        allowModification
          ? columnHelper.display({
              id: 'actions',
              cell: ({ row: { original: data } }) => (
                <Flex gap="35px">
                  <Menu isLazy placement="bottom" flip preventOverflow>
                    <MenuButton>
                      <Icon
                        aria-label="More"
                        as={BsThreeDotsVertical}
                        color={secondaryThemeColor[colorMode]}
                        _focus={{ boxShadow: 'none' }}
                      />
                    </MenuButton>
                    <MenuList p={0} minW="0" w={'100px'}>
                      <MenuItem
                        onClick={() =>
                          router.push(`/candidates/edit/${data.id}`)
                        }
                      >
                        Edit
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          setCandidateToDelete(data)
                          onOpen()
                        }}
                      >
                        Delete
                      </MenuItem>
                    </MenuList>
                  </Menu>
                </Flex>
              ),
            })
          : columnHelper.display({
              id: 'actions',
              cell: ({ row: { original: data } }) => (
                <chakra.span>
                  {data.checked && (
                    <Icon
                      as={IoMdCheckmark}
                      color="secondary"
                      fontSize="2rem"
                    />
                  )}
                </chakra.span>
              ),
            }),
      ].filter(Boolean),
    []
  )

  return (
    <>
      <Box>
        {allowModification && (
          <HeaderTab
            // flexDir={{ base: 'column', md: 'row' }}
            justifyContent={{ base: 'center', md: 'flex-start' }}
            alignItems="center"
            flexWrap="wrap"
            gap={8}
            h={{ base: '7rem', md: '3.938rem' }}
          >
            <Stack
              spacing={{ base: 2, md: 6 }}
              direction={{ base: 'column', md: 'row' }}
            >
              <CustomButton
                onClick={() => router.push('/candidates/create')}
                maxW="12.625rem"
                h="2.5rem"
                borderRadius="3.95px"
                fontSize="0.875rem"
                fontWeight={{ base: 'medium', md: 'semibold' }}
                bgColor={primaryThemeColor[colorMode]}
              >
                <Icon mr="0.563rem" as={AiOutlinePlus} />
                Add Candidate
              </CustomButton>

              <CustomButton
                onClick={() => router.push('/candidates/batchimport/import')}
                maxW="12.625rem"
                h="2.5rem"
                borderRadius="3.95px"
                fontSize="0.875rem"
                fontWeight={{ base: 'medium', md: 'semibold' }}
                bgColor={primaryThemeColor[colorMode]}
              >
                <Icon mr="0.563rem" as={BsFillCloudUploadFill} />
                <chakra.span>Import Candidates</chakra.span>
              </CustomButton>
            </Stack>
          </HeaderTab>
        )}

        <Flex mt="2.25rem" alignItems="center" gap={8} flexWrap="wrap">
          {allowModification && candidates.count > 0 && (
            <>
              <CustomButton
                w="5.063rem"
                h="1.808rem"
                borderRadius="2.855px"
                fontSize="0.75rem"
                fontWeight="normal"
                bgColor={sideBarThemeColor[colorMode]}
                color={basicTextRgba[colorMode]}
                border="1px solid black"
                onClick={onOpenMultiple}
                disabled={selectedCandidateIds?.length === 0}
              >
                <Icon mr="0.563rem" as={BiTrash} />
                Delete
              </CustomButton>
              <CustomButton
                w="5.063rem"
                h="1.808rem"
                borderRadius="2.855px"
                fontSize="0.75rem"
                fontWeight="normal"
                bgColor={sideBarThemeColor[colorMode]}
                color={basicTextRgba[colorMode]}
                border="1px solid black"
                onClick={onOpenActivate}
                disabled={selectedCandidateIds?.length === 0}
              >
                Activate
              </CustomButton>
            </>
          )}
          <FormControl
            ml="auto"
            mr="1rem"
            w={{ base: '5rem', md: '15.375rem' }}
            h="2.125rem"
            flex={{ base: 1, md: 'auto' }}
          >
            <TextInput
              value={searchTerm}
              handleChange={(e: any) => setSearchTerm(e.target.value as string)}
              placeholder="Search Candidates by Name"
              type="search"
              TextInputElement={
                <InputRightElement>
                  <AiOutlineSearch />
                </InputRightElement>
              }
            />
          </FormControl>
          {candidateGroups && candidateGroups?.length > 0 && (
            <Box
              flex={{ base: 1, md: 'auto' }}
              display="flex"
              justifyContent="flex-end"
              alignItems="center"
              gap={4}
              flexWrap={{ base: 'wrap', md: 'nowrap' }}
            >
              <Text
                as="label"
                htmlFor="group"
                fontSize="0.875rem"
                fontWeight="semibold"
                color={basicTextRgba[colorMode]}
              >
                Filter by {isFromSchoolNotion ? 'Class' : 'Group'}:
              </Text>
              <FormControl
                mr="2rem"
                w={{ base: '5rem', md: '15.375rem' }}
                h="2.125rem"
              >
                <Select
                  onChange={(e: any) =>
                    setGroup && setGroup(e.target.value as string)
                  }
                  value={group}
                >
                  <option value="">
                    All {isFromSchoolNotion ? 'Classes' : 'Groups'}
                  </option>
                  {candidateGroups.map((group: any) => (
                    <option key={group.id} value={group.id}>
                      {group.name}
                    </option>
                  ))}
                </Select>
              </FormControl>
            </Box>
          )}
        </Flex>

        <Box mt="1.25rem"></Box>
        <Container ml="0" p="0" maxW="full" overflowX="auto">
          <DataTable
            isLoading={isLoading}
            variant={allowModification ? 'lg-table' : 'lg-selectable'}
            columns={columns}
            data={allowModification ? candidates.rows : comparedCandidates}
            totalPages={candidates.total_pages}
            totalRecords={candidates.count}
            handlePageChange={handleCandidatePageChange}
            onRowSelected={(selectedRows: any[]) => {
              setSelectedCandidateIds(selectedRows.map((row: any) => row.id))
              setSelectedCandidateData(selectedRows)
            }}
            onRowClick={handleCandidateCheck}
            isAllowSelection={allowModification}
            {...(allowModification && { renderRowSubComponent })}
          />
        </Container>
      </Box>
      {allowModification && (
        <>
          {/* Single Deletion Confirmation */}
          <DeleteDialog
            isOpen={isOpen}
            onClick={() => {
              handleCandidateDelete &&
                handleCandidateDelete(candidateToDelete.id)
              onClose()
            }}
            onClose={onClose}
            title="Are you sure you want to delete the selected Item?"
            message="This action cannot be undone"
          />
          {/* Multiple Deletion Confirmation */}
          <DeleteDialog
            isOpen={isOpenMultiple}
            onClick={() => {
              handleMassCandidateDelete &&
                handleMassCandidateDelete(selectedCandidateIds as string[])
              onCloseMultiple()
            }}
            onClose={onCloseMultiple}
            title="Are you sure you want to delete all the selected Items?"
            message="This action cannot be undone"
          />
          <ActivateDialog
            title={`${selectedCandidateIds?.length} Candidates Selected`}
            message={`Choose Plan to activate`}
            onClose={onCloseActivate}
            isOpen={isOpenActivate}
            mainActiontext={`Standard (${
              selectedCandidateIds.length * standard_unit_rate
            })units`}
            secondaryActionText={`PAYG (${
              selectedCandidateIds.length * payg_unit_rate
            })units`}
            handleClick={handleActiavteStandard}
            handleDefaultButton={handleActiavtePAYG}
          />
          <CustomModal
            type="confirmation"
            message={
              <Text>
                You are about to activate the{' '}
                {
                  <Text
                    as="span"
                    color={LEColorConstants.primary}
                    textTransform="uppercase"
                  >
                    {activateData?.type}
                  </Text>
                }{' '}
                plan for{' '}
                {
                  <Text as="span" color={LEColorConstants.primary}>
                    {activateData?.candidates?.length} candidates
                  </Text>
                }{' '}
                and{' '}
                {
                  <Text as="span" color={LEColorConstants.primary}>
                    {getPlanUnit(activateData?.type) *
                      activateData?.candidates?.length}{' '}
                    units{' '}
                  </Text>
                }{' '}
                will be deducted from your Unit balance?
              </Text>
            }
            isOpen={isOpenActivateConfirmation}
            onClose={onCloseActivateConfirmation}
            handleClick={() => {
              handleActivateCandidate()
              onCloseActivateConfirmation()
            }}
          />
          <CustomModal
            type="error"
            mainActiontext="Buy Unit"
            message={
              <>
                <Text>Insufficient Unit!</Text>
                <Text>
                  Unit Balance:{' '}
                  <Text as="span" color={brandSecondaryColor[colorMode]}>
                    {wallet.balance} Units
                  </Text>
                </Text>
              </>
            }
            isOpen={isOpenInsufficientBalance}
            onClose={onCloseInsufficientBalance}
            handleClick={() => {
              router.push('/unit')
              onCloseInsufficientBalance()
            }}
          />
          <CustomModal
            type="success"
            isAllowMultipleButtons={false}
            message={
              <>
                <Text textAlign="center" mb="1rem">
                  Activation Successful!
                </Text>
                <Text textAlign="center">
                  {activateData?.candidates?.length} candidates have been
                  successfully activated click{' '}
                  <Text
                    as="button"
                    onClick={() => router.push('/transactions')}
                    color={brandPrimayColor[colorMode]}
                  >
                    here
                  </Text>{' '}
                  to view transaction.{' '}
                </Text>
              </>
            }
            isOpen={isOpenSuccessfulActivation}
            onClose={onCloseSuccessfulActivation}
          />
        </>
      )}

      <Loader isLoading={isLoadingRequest} />
    </>
  )
}

export default observer(CandidatesPage)
