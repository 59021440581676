export const tabs = [
  {
    id: 1,
    tablist: 'Company',
  },
  {
    id: 2,
    tablist: 'Personalize',
  },
  // {
  //   id: 3,
  //   tablist: 'Add Domain',
  // },
  {
    id: 4,
    tablist: 'Password',
  },
]
