import React from 'react'
import ChartBox from '@/components/Chart'
import { primaryThemeColor } from '@/lib/constants/colorConstants'
import { Box, Flex, Text, useColorMode } from '@chakra-ui/react'
import moment from 'moment'
import DropdownSelect from '@/components/DropdownSelect'
import { WrapBox } from '@/components/Chart/ChartBox'

const RevenuePage = () => {
  const { colorMode } = useColorMode()
  const handleChange = () => {}

  const getTime = (time: any) => {
    return moment(time).format('hh:mm')
  }

  console.log(getTime(new Date().getTime()))
  return (
    <>
      <Flex justifyContent="space-between">
        <Box>
          <Text>Total Revenue: </Text>
          <Text fontSize="1.625rem" fontWeight="semibold" color="primary">
            {' '}
            N259039490395{' '}
          </Text>
        </Box>
        <Box maxW="12.313rem" mr="0.5rem">
          <DropdownSelect
            size="sm"
            onChange={handleChange}
            options={[
              { value: 'Month', label: 'Month' },
              { value: 'Year', label: 'Year' },
            ]}
          />
        </Box>
      </Flex>

      <Box>
        <ChartBox
          chartStyle={{
            maxHeight: '243px',
          }}
          chartData={{
            labels: ['January', 'February', 'March', 'April', 'May'],
            datasets: [
              {
                label: 'mon',
                backgroundColor: [` ${primaryThemeColor[colorMode]}`],
                data: [762, 1000, 133, 500, 400],
              },
            ],
          }}
          typeofChart="barchart"
        />
      </Box>
      <Box maxW="full" mb="2rem">
        <Flex
          flexDir={['column', 'row']}
          gap="31px"
          justifyContent="space-between"
        >
          <WrapBox width="full">
            <ChartBox
              chartStyle={{
                maxHeight: '160px',
              }}
              title="Top Admins"
              chartData={{
                labels: [
                  'LogicGlide IJMB',
                  'Company 2',
                  'Company 3',
                  'Company 4',
                  'Company 5',
                ],
                datasets: [
                  {
                    label: 'mon',
                    backgroundColor: ` ${primaryThemeColor[colorMode]}`,
                    data: [762, 99, 200, 300, 400],
                  },
                ],
              }}
              typeofChart="barchart"
            />
          </WrapBox>

          <Box
            w="full"
            borderRadius="10px"
            boxShadow="0px 5.11828px 12.7957px rgba(0, 0, 0, 0.05)"
            overflow="scroll"
          >
            <ChartBox
              chartStyle={{
                maxHeight: '160px',
                minHeigth: '140px',
              }}
              title="Daily Gain"
              chartData={{
                labels: [
                  <Box>{''}</Box>,
                  getTime(new Date().getTime()),
                  getTime(new Date().getTime()),
                  getTime(new Date().getTime()),
                  getTime(new Date().getTime()),
                  getTime(new Date().getTime()),
                  getTime(new Date().getTime()),
                  getTime(new Date().getTime()),
                  getTime(new Date().getTime()),
                  getTime(new Date().getTime()),
                  getTime(new Date().getTime()),
                  getTime(new Date().getTime()),
                  getTime(new Date().getTime()),
                  getTime(new Date().getTime()),
                  getTime(new Date().getTime()),
                ],
                datasets: [
                  {
                    label: 'mon',
                    backgroundColor: [
                      ` ${primaryThemeColor[colorMode]}`,
                      ` ${primaryThemeColor[colorMode]}`,
                    ],
                    data: [12.0, 9, 4, 2, 7, 3, 20, 12, 15, 17, 1],
                    barThickness: 6,
                    borderRadius: 20,
                  },
                ],
              }}
              typeofChart="barchart"
            />
          </Box>
        </Flex>
      </Box>
    </>
  )
}

export default RevenuePage
