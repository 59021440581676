import { SideBarOptionMenu } from '@/types/sidebar'
import { FaUserGraduate } from 'react-icons/fa'

export const candidatesMenu: SideBarOptionMenu = {
  name: 'Candidates',
  icon: FaUserGraduate,
  path: '/candidates',
  order: 1,
  permission: { name: 'candidates', can: 'visible' },
  isShown: true,
  isActive: false,
  child: [],
}
