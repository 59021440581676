import React from 'react'
import AdminsAuthTab from './components/AminsAuthTab'
import { AllChart } from './all/AllTab'
import { RegularChart } from './regular/RegularTab'
import { PaygChart } from './payg/PaygTab'

interface IAdminsPage {
  type: AdminsAuthType
}

const AdminsPage = ({ type }: IAdminsPage) => {
  return (
    <>
      {type === 'All' && <AllChart />}
      {type === 'Regular' && <RegularChart />}
      {type === 'Payg' && <PaygChart />}
      <AdminsAuthTab type={type} />
    </>
  )
}

export default AdminsPage
