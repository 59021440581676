import { useQuery } from 'react-query'

import { axiosInstance } from '@/lib/helpers'
import { queryKeys } from '@/lib/react-query/constants'

// common options for both useQuery and prefetchQuery
const commonOptions = {
  staleTime: 0,
  cacheTime: 300000, // 5 minutes
}

async function getExams(): Promise<any[]> {
  const {
    data: { data },
  } = await axiosInstance.get('/candidate/exam/')
  return data
}

interface useExam {
  exams: IExam[]
  isLoading: boolean
}

export function useExam(): useExam {
  const fallback = {
    data: [],
  } as any

  const { data: exams = fallback, isLoading } = useQuery(
    [queryKeys.examsCandidate],
    () => getExams(),
    {
      ...commonOptions,
      refetchOnMount: true,
      refetchOnReconnect: true,
      refetchOnWindowFocus: true,
    }
  )

  return {
    exams,
    isLoading,
  }
}
