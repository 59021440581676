import { brandPrimayColor } from '@/lib/constants/colorConstants'
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Stack,
  Text,
  TextProps,
  useColorMode,
} from '@chakra-ui/react'
import React from 'react'
import CustomButton from '../Button'

interface IProps {
  title?: TextProps | string
  message?: TextProps | string
  isOpen: boolean
  mainActiontext?: string
  secondaryActionText?: string
  isAllowMultipleButtons?: boolean
  onClose: () => void
  handleClick?: () => void
  handleDefaultButton?: () => void
}

const ActivateDialog = ({
  title,
  message,
  isOpen,
  mainActiontext = 'Proceed',
  secondaryActionText = 'Cancel',
  isAllowMultipleButtons = true,
  handleDefaultButton,
  onClose,
  handleClick,
}: IProps) => {
  const { colorMode } = useColorMode()
  const modalSize = {
    base: '300px',
    sm: '400px',
    md: '784px',
  }
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      motionPreset="slideInBottom"
    >
      <ModalOverlay />
      <ModalContent maxW={modalSize} h="344px">
        <ModalCloseButton />
        <ModalBody>
          <Stack alignItems="center">
            <Text
              mt={{ base: '3.813rem', md: '5.813rem' }}
              color="secondary"
              fontSize="1rem"
              fontWeight="medium"
            >
              {title}
            </Text>
            <Text
              mb="2.625rem"
              mt="1rem"
              fontSize="0.875rem"
              fontWeight="medium"
            >
              {message}
            </Text>

            <Stack
              pt="2.625rem"
              direction={{ base: 'column', md: 'row' }}
              spacing={{ base: 4, md: 8 }}
              transition="all .2s ease-in"
            >
              <CustomButton
                onClick={handleDefaultButton}
                py="0.5rem"
                px="2.75rem"
                w="auto"
                h="2.313rem"
                bgColor="transparent"
                fontSize="0.875rem"
                fontWeight="semibold"
                color={brandPrimayColor[colorMode]}
                border="1px"
                borderRadius="4px"
                borderColor={brandPrimayColor[colorMode]}
                _hover={{
                  bgColor: brandPrimayColor[colorMode],
                  color: 'white',
                }}
              >
                {secondaryActionText}
              </CustomButton>
              <CustomButton
                onClick={handleClick}
                py="0.5rem"
                px="2.75rem"
                w="auto"
                h="2.313rem"
                bgColor="transparent"
                fontSize="0.875rem"
                fontWeight="semibold"
                color={brandPrimayColor[colorMode]}
                border="1px"
                borderRadius="4px"
                borderColor={brandPrimayColor[colorMode]}
                _hover={{
                  bgColor: brandPrimayColor[colorMode],
                  color: 'white',
                }}
              >
                {mainActiontext}
              </CustomButton>
            </Stack>
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default ActivateDialog
