import { useQuery } from 'react-query'

import { axiosInstance } from '@/lib/helpers'
import { queryKeys } from '@/lib/react-query/constants'

// common options for both useQuery and prefetchQuery
const commonOptions = {
  staleTime: 0,
  cacheTime: 300000, // 5 minutes
}

async function getWallet(): Promise<any> {
  const {
    data: { data },
  } = await axiosInstance.get('/organization/wallet/balance')
  return data
}

interface useWallet {
  wallet: IWallet
  refetchWallet: () => Promise<any>
  isLoading: boolean
}

export function useWallet(): useWallet {
  const fallback = {
    id: '1',
    balance: 0.0,
  } as IWallet

  const {
    data: wallet = fallback,
    isLoading,
    refetch,
  } = useQuery([queryKeys.wallet], () => getWallet(), {
    ...commonOptions,
    refetchOnMount: true,
    refetchOnReconnect: true,
    refetchOnWindowFocus: true,
  })

  return {
    wallet,
    refetchWallet: refetch,
    isLoading,
  }
}
