import { SideBarOptionMenu } from '@/types/sidebar'
import { MdOutlineAdminPanelSettings } from 'react-icons/md'

export const totalAdminMenu: SideBarOptionMenu = {
  name: 'Total Admin',
  icon: MdOutlineAdminPanelSettings,
  path: '/totaladmin',
  order: 1,
  permission: { name: 'superadmin', can: 'visible' },
  isShown: true,
  isActive: false,
  child: [],
}
