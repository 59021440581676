import React from 'react'

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js'
import { Bar } from 'react-chartjs-2'
import { Box, Text } from '@chakra-ui/react'

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

export const options = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
      position: 'top' as const,
    },
  },
  scales: {
    y: {
      beginAtZero: true,
      ticks: { precision: 0 },
    },
  },
}

type IChart = {
  dataChart: any
  chartStyle: any
}

export default function BarChart({ dataChart, chartStyle }: IChart) {
  const { chartData, noOfUsers, title } = dataChart
  return (
    <Box padding="1rem">
      <Text fontSize="1rem">
        {title}{' '}
        <Text as="span" color="primary" fontWeight="medium">
          {noOfUsers}
        </Text>
      </Text>
      <Bar style={chartStyle} options={options} data={chartData} />
    </Box>
  )
}
