import React, { useRef } from 'react'
import { CheckCircleIcon, SearchIcon } from '@chakra-ui/icons'
import {
  Box,
  chakra,
  Container,
  Flex,
  Icon,
  IconProps,
  Input,
  InputGroup,
  InputLeftElement,
  List,
  ListItem,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Stack,
  Text,
  useColorMode,
  useDisclosure,
} from '@chakra-ui/react'
import { createColumnHelper } from '@tanstack/react-table'
import moment from 'moment'
import { BsFunnel } from 'react-icons/bs'
import { ImSpinner3 } from 'react-icons/im'
import { MdCancel } from 'react-icons/md'

import { useReactToPrint } from 'react-to-print'
import { TablePageLoader } from '@/components/SkeletonLoaders'
import { ngnCurrencyFormatter } from '@/lib/helpers'
import CustomButton from '@/components/Button'
import DataTable from '@/components/DataTable'
import HeaderTab from '@/components/HeaderTab'
import LEColorConstants, {
  basicTextColor,
  bgThemeColor,
  dashboardColor,
  indigoBgColor,
} from '@/lib/constants/colorConstants'

import { useTransactions } from './hooks/useTransactions'
import TransactionModal from './TransactionModal'
import Receipt from './Receipt'

type PaymentStatusProps = {
  success: IconProps
  pending: IconProps
  failed: IconProps
}

const TransactionsPage = () => {
  const { colorMode } = useColorMode()
  const componentRef = useRef(null)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { isLoading, transactions, setActivePage, setFilter, filter } =
    useTransactions()
  const [currentTransactionIndex, setCurrentTransactionIndex] =
    React.useState(0)

  const columnHelper = createColumnHelper<ITransaction>()
  const columns = React.useMemo(
    () => [
      columnHelper.accessor('created_at', {
        header: 'Date',
        cell: info =>
          moment(info.getValue()).format('ddd, DD-MM-YYYY, hh:mm A'),
      }),
      columnHelper.accessor('remarks', {
        header: 'Description',
        cell: info => info.getValue(),
      }),
      columnHelper.accessor('amount', {
        header: 'Amount',
        cell: info => (
          <chakra.span>
            {info.row.original.remarks.includes('Wallet top up')
              ? ngnCurrencyFormatter(info.getValue())
              : `${info.row.original.unit} units`}
          </chakra.span>
        ),
      }),
      columnHelper.accessor('transaction_reference', {
        header: 'Transaction ID',
        cell: info => info.getValue(),
      }),
      columnHelper.accessor('status', {
        header: 'Status',
        cell: info => (
          <chakra.span>{transactionStatus[info.getValue()]}</chakra.span>
        ),
      }),
      columnHelper.display({
        header: 'Action',
        id: 'action',
        cell: ({ row: { original: data, index } }) => (
          <Flex gap={2}>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              w="2.813rem"
              h="1.378rem"
              fontSize="0.75rem"
              fontWeight="medium"
              borderRadius="16px"
              cursor="pointer"
              bgColor={indigoBgColor[colorMode]}
              onClick={() => handleTransactionDetails(index)}
            >
              View
            </Box>
            {data.status === 'failed' && (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                w="2.813rem"
                h="1.378rem"
                fontSize="0.75rem"
                fontWeight="medium"
                borderRadius="16px"
                color="white"
                bgColor="whatsapp.400"
                cursor="pointer"
              >
                Retry
              </Box>
            )}
          </Flex>
        ),
      }),
    ],
    []
  )

  const transactionStatus: PaymentStatusProps | any = {
    successful: (
      <Icon fontSize="1.2rem" color="whatsapp.400" as={CheckCircleIcon} />
    ),
    pending: (
      <Icon
        as={ImSpinner3}
        fontSize="1.4rem"
        color={LEColorConstants.secondary}
        verticalAlign="middle"
      />
    ),
    failed: (
      <Icon
        as={MdCancel}
        fontSize="1.4rem"
        color="red.500"
        verticalAlign="middle"
      />
    ),
  }

  const handleTransactionDetails = (id: number) => {
    setCurrentTransactionIndex(id)
    onOpen()
  }

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  })

  const handleTransactionPageChange = (page: number) => {
    setActivePage(page)
  }

  if (isLoading) {
    return <TablePageLoader />
  }

  return (
    <Box bg={bgThemeColor[colorMode]} pb="2.412rem">
      <HeaderTab>
        <Text fontSize="1.125rem" fontWeight="semibold">
          Transaction History
        </Text>
      </HeaderTab>

      <Box m="1rem" p="0.75rem" mt="1.875rem">
        <Flex
          p="0.75rem"
          bgColor={dashboardColor[colorMode]}
          alignItems="center"
          justifyContent="space-between"
        >
          <InputGroup w="22.25rem" bgColor={bgThemeColor[colorMode]}>
            <Input
              placeholder="search by description or transaction ID"
              value={filter}
              onChange={e => setFilter(e.target.value)}
            />
            <InputLeftElement children={<SearchIcon />} />
          </InputGroup>

          <Popover placement="bottom-start">
            <PopoverTrigger>
              <Box>
                <CustomButton
                  w="6.063rem"
                  h="2.5rem"
                  fontSize="1rem"
                  fontWeight="semibold"
                  borderRadius="5px"
                  color={basicTextColor[colorMode]}
                  bgColor={bgThemeColor[colorMode]}
                >
                  <Icon mr="0.5rem" as={BsFunnel} /> Filter
                </CustomButton>
              </Box>
            </PopoverTrigger>
            <PopoverContent py="2rem" _focus={{ outline: 'none' }}>
              <PopoverBody>
                <Box>
                  <Text>Transaction Status</Text>
                  <Stack mt="1rem">
                    <List>
                      {Object.keys(transactionStatus).map(data => (
                        <ListItem
                          p="0.75rem"
                          textTransform="capitalize"
                          cursor="pointer"
                          key={`transaction_status_filter_${data}`}
                          _hover={{
                            bgColor: 'gray.400',
                          }}
                        >
                          {data}
                        </ListItem>
                      ))}
                    </List>
                  </Stack>
                </Box>
              </PopoverBody>
            </PopoverContent>
          </Popover>
        </Flex>

        <Container p="0" mt="1.75rem" maxW="full" overflowX="scroll">
          <DataTable
            variant="lg-table"
            columns={columns}
            data={transactions.rows}
            totalPages={transactions.total_pages}
            handlePageChange={handleTransactionPageChange}
            isAllowSelection={false}
          />
        </Container>

        <TransactionModal
          isOpen={isOpen}
          onClose={onClose}
          transactionDetail={transactions.rows[currentTransactionIndex]}
          transactionStatus={transactionStatus}
          handlePrint={handlePrint}
        />
        <Box display="none">
          <Box ref={componentRef}>
            <Receipt transaction={transactions.rows[currentTransactionIndex]} />
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default TransactionsPage
