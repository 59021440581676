import { SideBarOptionMenu } from '@/types/sidebar'
import { BsJournalText } from 'react-icons/bs'

export const userResultMenu: SideBarOptionMenu = {
  name: 'Results',
  icon: BsJournalText,
  path: '/results',
  order: 1,
  permission: null,
  isShown: true,
  isActive: false,
  child: [],
}
