import {
  Dispatch,
  SetStateAction,
  useCallback,
  useState,
  useEffect,
} from 'react'
import { useQuery, useQueryClient } from 'react-query'

import { axiosInstance } from '@/lib/helpers'
import { queryKeys } from '@/lib/react-query/constants'

// common options for both useQuery and prefetchQuery
const commonOptions = {
  staleTime: 0,
  cacheTime: 300000, // 5 minutes
}

async function getTransactions(options: any): Promise<any[]> {
  const {
    data: { data },
  } = await axiosInstance.get('/organization/transactions', { params: options })
  return data
}

interface useTransactions {
  transactions: ITransactionList
  isLoading: boolean
  activePage: number
  setActivePage: Dispatch<SetStateAction<number>>
  filter: string
  setFilter: Dispatch<SetStateAction<string>>
}

const filterTransactions = (data: ITransactionList, filter: string) => {
  if (!filter) return data
  return {
    ...data,
    rows: data.rows.filter(transaction =>
      transaction.remarks.toLowerCase().includes(filter.toLowerCase())
    ),
  }
}

export function useTransactions(): useTransactions {
  const queryClient = useQueryClient()

  const [filter, setFilter] = useState<string>('')
  const [activePage, setActivePage] = useState<number>(1)

  useEffect(() => {
    const nextPage = activePage + 1
    queryClient.prefetchQuery(
      [queryKeys.transactions, nextPage],
      () => getTransactions({ page: nextPage }),
      commonOptions
    )
  }, [queryClient, activePage])

  const selectFn = useCallback(
    data => filterTransactions(data, filter),
    [filter]
  )

  const fallback = {
    count: 0,
    received: 0,
    total_pages: 0,
    current_page: 0,
    rows: [],
  } as any

  const { data: transactions = fallback, isLoading } = useQuery(
    [queryKeys.transactions, activePage],
    () => getTransactions({ page: activePage }),
    {
      ...commonOptions,
      refetchOnMount: true,
      refetchOnReconnect: true,
      refetchOnWindowFocus: true,
      select: !filter ? undefined : selectFn,
    }
  )

  return {
    transactions,
    isLoading,
    activePage,
    setActivePage,
    filter,
    setFilter,
  }
}
