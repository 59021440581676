import CustomButton from '@/components/Button'
import DataTable from '@/components/DataTable'
import { TextInput } from '@/components/Input'
import {
  Box,
  Flex,
  HStack,
  Icon,
  IconButton,
  InputRightElement,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Switch,
  Text,
  VStack,
} from '@chakra-ui/react'
import moment from 'moment'
import React, { useState } from 'react'
import { useRouter } from 'next/router'
import { AiOutlinePlus, AiOutlineSearch } from 'react-icons/ai'
import { BsThreeDotsVertical } from 'react-icons/bs'

const data = [
  {
    id: 1,
    date: new Date(),
    name: 'Increased Plan',
    message: 'Ladies and gentlemen, we..',
  },
  {
    id: 2,
    date: new Date(),
    name: 'Maximim S',
    message: 'Have you checked our la...',
  },
  {
    id: 3,
    date: new Date(),
    name: 'Lorem',
    message: 'Ladies and gentlemen, we..',
  },
  {
    id: 4,
    date: new Date(),
    name: 'Lorem',
    message: 'Ladies and gentlemen, we..',
  },
]

export default function NotificationPage() {
  const [search, setSearch] = useState('')
  const [box, setBox] = useState<any>()
  const router = useRouter()

  const handleChangeMessage = (data: any) => {
    alert(data.id)
  }

  const handleShowBox = (boxshow: any) => {
    alert(boxshow.name)
  }

  const handleSearch = (event: any) => {
    event.preventDefault()
    setSearch(event.target.value)
  }

  const columns = React.useMemo(
    () => [
      {
        Header: 'Created',
        accessor: 'date',
        Cell: ({}: any) => moment(new Date()).format('DD/MM/YYYY, hh:mm a'),
      },
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'Message',
        accessor: 'message',
      },
      {
        Header: '',
        accessor: 'id',
        Cell: ({ row: { original: data } }: any) => (
          <Flex gap={['1.2rem', '4.375rem']}>
            <Switch
              alignSelf="center"
              width="2.6rem"
              height="1.25rem"
              variant="lg-switch"
              id="enableResult"
              onChange={() => handleChangeMessage(data)}
            />
            <Box>
              <Menu>
                <MenuButton
                  as={IconButton}
                  aria-label="Options"
                  icon={<BsThreeDotsVertical color="#FF6E06" />}
                  variant="none"
                  _focus={{}}
                />
                <MenuList>
                  <MenuItem>Edit</MenuItem>
                  <MenuItem>Delete</MenuItem>
                </MenuList>
              </Menu>
            </Box>
          </Flex>
        ),
      },
    ],
    []
  )
  return (
    <>
      <HStack justifyContent="space-between" mb="1.563rem">
        <Box>
          <Text fontSize="1.25rem" fontWeight="medium">
            Total Notifications:{' '}
            <Text as="span" color="primary">
              20
            </Text>
          </Text>
        </Box>
        <CustomButton
          w="9.563rem"
          h="2.75rem"
          mt="2.688rem"
          borderRadius="5px"
          fontSize="0.938rem"
          fontWeight="medium"
          onClick={() => router.push('/notification/create')}
        >
          <Icon mr="0.875rem" as={AiOutlinePlus} />
          Create New
        </CustomButton>
      </HStack>
      <VStack mb="1.563rem">
        <TextInput
          labelProps={{
            fontSize: '0.88rem',
            fontWeight: 'medium',
            mb: '0.2rem',
          }}
          formControlProps={{
            maxW: '15.4rem',
          }}
          TextInputProps={{
            h: '2.188rem',
            borderRadius: '2.3px',
          }}
          placeholder="Search"
          type="search"
          value={search}
          handleChange={handleSearch}
          TextInputElement={
            <InputRightElement>
              <AiOutlineSearch />
            </InputRightElement>
          }
        />
      </VStack>
      <Box>
        <DataTable
          variant="notification-table"
          data={data}
          isAllowSelection={false}
          columns={columns}
        />
      </Box>
    </>
  )
}
