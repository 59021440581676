import { TextInput } from '@/components/Input'
import { GridItem, Input } from '@chakra-ui/react'

type colorProps = {
  id: number
  title: string
  color: string
  name: string
}

interface CProps {
  colorProps: colorProps[]
  handleChange: (colorIndex: number, val: string) => void
}

const ColorComponent = ({ colorProps, handleChange }: CProps) => {
  const handleChangeNull = () => {
    return null
  }

  return (
    <>
      {colorProps &&
        colorProps.length > 0 &&
        colorProps.map((colorProp, colorIndex) => (
          <GridItem key={colorProp.id}>
            <TextInput
              label={colorProp.title}
              labelProps={{
                fontSize: '0.88rem',
                opacity: 1,
                mb: '1.2rem',
                fontWeight: 'medium',
              }}
              formControlProps={{
                mb: '3.8rem',
                maxW: '15.63rem',
                position: 'relative',
              }}
              TextInputProps={{
                outline: '1px solid black',
                h: '2.56rem',
                borderRadius: '5px',
                paddingLeft: '4rem',
                isReadOnly: true,
              }}
              type="text"
              name={colorProp.name}
              value={colorProp.color}
              TextInputElement={
                <Input
                  position="absolute"
                  justifyItems="center"
                  alignItems="center"
                  transform="translate(30%, 15%)"
                  borderRadius={'5px'}
                  variant="flushed"
                  w="2rem"
                  h="2rem"
                  zIndex={1}
                  cursor="pointer"
                  type={'color'}
                  value={colorProp.color}
                  onChange={e => handleChange(colorIndex, e.target.value)}
                />
              }
              handleChange={handleChangeNull}
            />
          </GridItem>
        ))}
    </>
  )
}

export default ColorComponent
