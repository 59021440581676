import React from 'react'
import { Text, Flex, Box, HStack, VStack } from '@chakra-ui/react'
import CustomButton from '@/components/Button'

type IExamResult = {
  data: any
  handleViewResult: () => void
}

export default function AvailableResults({
  data,
  handleViewResult,
}: IExamResult) {
  const { exam_name, mark_obtained, total_questions, exam_id } = data
  return (
    <Box
      w={{ base: 'full', md: '48%' }}
      h="10.813rem"
      border="1px solid black"
      p={{ base: '0.825rem', md: '1.25rem' }}
      borderRadius="10px"
      mb="2.313rem"
      _hover={{
        border: '1px solid #009BFF',
      }}
    >
      <Box overflowY={['auto', 'hidden']}>
        <Box>
          <Flex justifyContent="space-between" gap={2}>
            <Box fontSize={{ base: '0.8rem', md: '1rem' }}>
              <Text color="secondary" fontWeight="normal" mb="0.6rem">
                {exam_name}
              </Text>
              <Text fontWeight="medium">
                Total Questions: {total_questions}
              </Text>
            </Box>
            <CustomButton
              fontSize="0.825rem"
              fontWeight="medium"
              w={{ base: '4rem', md: '6rem' }}
              h="2rem"
              borderRadius="5px"
              onClick={handleViewResult}
              variant="outline"
              overflow="hidden"
            >
              View
            </CustomButton>
          </Flex>
        </Box>
        <Box maxW="55.75rem" m="0 auto">
          <Flex
            justifyContent="space-between"
            fontSize={['0.8rem', '1.25rem']}
            gap={2}
          >
            <Box fontWeight="medium" textAlign="center">
              <Text fontSize="1.25rem" fontWeight="medium" color="primary">
                {mark_obtained}
              </Text>
              <Text fontSize="0.825rem">Your Score</Text>
            </Box>
          </Flex>
        </Box>
      </Box>
    </Box>
  )
}
