// import { useSideBarMenuOptions } from '@/modules/'
import { APP_NAME } from '@/lib/constants/appConstants'
import { bgThemeColor, sidebarBg } from '@/lib/constants/colorConstants'
import { hasPermission } from '@/lib/helpers'
import { useSideBarMenuOptions } from '@/modules/index'
import { stores } from '@/stores/index'
import {
  ColorMode,
  Box,
  Divider,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Icon,
  Text,
  useColorMode,
  useDisclosure,
  AccordionItem,
  AccordionPanel,
  AccordionButton,
  Accordion,
} from '@chakra-ui/react'
import { css } from '@emotion/react'
import { useRouter } from 'next/router'
import React, { useState } from 'react'
import { FaAngleDown } from 'react-icons/fa'
import { CustomLink } from '../Link'

import SideMenuTimer from '../MenuTimer'
import TruncatedText from '../TruncatedText'

const NavDrawer = ({
  isOpen,
  onClose,
}: {
  isOpen: any
  onClose: any
  color?: string
}) => {
  const route = useRouter()
  const { colorMode } = useColorMode()
  const { pathname, asPath } = route || { pathname: '/' }
  const SideBarMenuOptions = useSideBarMenuOptions()
  const _sideBarOptions = Object.values(SideBarMenuOptions)
  const [activeIndex, setActiveIndex] = useState<number | undefined>()
  const permissions: any[] = stores.auth.getMyPermissions()

  const navcss = (isActive: boolean) => css`
    & {
      &:hover {
        &::after {
          display: ${isActive ? 'block' : 'none'};
          background: ${isActive && sidebarBg[colorMode]};
          margin-left: 93.5%;
          width: 0;
          height: 0;
          border-top: 20px solid transparent;
          border-bottom: 20px solid transparent;
          border-right: 20px solid white;
        }
      }

      &::after {
        display: ${isActive ? 'block' : 'none'};
        background: ${isActive
          ? sidebarBg[colorMode]
          : bgThemeColor[colorMode]};
        margin-left: 93.5%;
        width: 0;
        height: 0;
        border-top: 20px solid transparent;
        border-bottom: 20px solid transparent;
        border-right: 20px solid white;
      }
    }
  `

  let currentRoute: string
  const paths = pathname.split('/')

  if (paths.length === 2) {
    currentRoute = '/'
  } else if (paths.includes('_sites')) {
    currentRoute = paths[3]
  } else currentRoute = paths[2]

  return (
    <Drawer placement="left" onClose={onClose} isOpen={isOpen}>
      <DrawerOverlay />
      <DrawerContent bgColor="#3F3D56" overflow="hidden">
        <DrawerHeader pt="8">
          <SideMenuTimer />
          <Flex flexDir="column">
            <Text
              textTransform="capitalize"
              fontSize="sm"
              fontWeight="light"
              color="gray.400"
            >
              {stores.auth.currentAdmin?.role.name}
            </Text>
            <TruncatedText>
              <Text fontSize="sm" fontWeight="light" color="gray.400">
                {stores.auth.currentAdmin?.email}
              </Text>
            </TruncatedText>
          </Flex>
          <Divider orientation="horizontal" variant="dashed" mt="1" />
        </DrawerHeader>
        <DrawerBody
          overflowY="scroll"
          p="0"
          pt="6px"
          flexGrow={1}
          css={css`
            &::-webkit-scrollbar {
              display: none;
            }

            & {
              scrollbar-width: none;
              -ms-overflow-style: none;
            }
          `}
        >
          <Accordion
            flex={1}
            allowMultiple
            defaultIndex={activeIndex ? [activeIndex] : undefined}
          >
            {_sideBarOptions.map(
              ({ name, icon, path, isShown, child, permission }, i) => {
                if (!isShown) return

                const currentPath = path.split('/')[1] || '/'
                const isActive = currentRoute === currentPath

                if (isActive && child?.length && activeIndex !== i)
                  setActiveIndex(i)

                if (!hasPermission(permission, permissions)) return
                return (
                  <DrawerComponents
                    key={name}
                    isActive={isActive}
                    colorMode={colorMode}
                    navcss={navcss}
                    path={path}
                    icon={icon}
                    child={child}
                    asPath={asPath}
                    name={name}
                    permissions={permissions}
                  />
                )
              }
            )}
          </Accordion>
        </DrawerBody>
        <DrawerFooter display="flex" justifyContent="center">
          <Flex mt="40px">
            <Text fontSize="14px" color="white">
              &copy; {new Date().getFullYear()} {APP_NAME}
            </Text>
          </Flex>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  )
}

const styles = {
  display: 'flex',
  alignItems: 'center',
  _hover: {
    textDecoration: 'none',
    color: 'inherit',
  },
  _focus: {
    border: 'none',
    textDecoration: 'none',
  },
  fontFamily: 'Poppins',
  fontWeight: 'normal',
  fontSize: '14px',
  p: '17px',
}

const DrawerComponents = ({
  isActive,
  colorMode,
  navcss,
  path,
  icon,
  child,
  asPath,
  name,
  permissions,
}: {
  isActive: boolean
  colorMode: ColorMode
  navcss: any
  path: string
  icon: any
  child: any
  asPath: any
  name: string
  permissions?: IUserPermission[]
}) => {
  return (
    <>
      <AccordionItem border="none">
        <Box
          position="relative"
          css={navcss(isActive)}
          _after={{
            content: '""',
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)',
          }}
          bg={isActive ? sidebarBg[colorMode] : 'transparent'}
        >
          {child.length ? (
            <AccordionButton fontSize="16px" py={0} px={10}>
              {path ? (
                <CustomLink
                  href={path as string}
                  {...styles}
                  width="100%"
                  textAlign="left"
                  color="white"
                  py={3}
                >
                  <Icon
                    as={icon}
                    color="white"
                    style={{ marginRight: '20px' }}
                  />
                  {name}
                </CustomLink>
              ) : (
                <Box {...styles}>
                  <Icon
                    as={icon}
                    color="white"
                    style={{ marginRight: '20px' }}
                  />
                  {name}
                </Box>
              )}

              <Icon
                as={FaAngleDown}
                style={{ marginLeft: '20px', padding: '10px 10px' }}
              />
            </AccordionButton>
          ) : (
            <Box>
              <CustomLink
                href={path as string}
                {...styles}
                width="100%"
                textAlign="left"
                color="white"
                py={3}
                _hover={{ color: 'gray.100' }}
              >
                <Icon as={icon} color="white" style={{ marginRight: '20px' }} />
                {name}
              </CustomLink>
            </Box>
          )}
        </Box>
        <AccordionPanel paddingLeft="35px">
          {child.map((childEl: any) => {
            if (!hasPermission(childEl.permission, permissions)) return
            return (
              <CustomLink
                key={childEl.name}
                href={childEl.path as string}
                color={asPath === childEl.path ? 'red' : undefined}
                {...styles}
                _hover={{
                  textDecoration: 'none',
                  color: asPath === childEl.path ? 'red' : 'inherit',
                }}
                py={3}
                px={10}
                fontSize="14px"
              >
                <TruncatedText>{childEl.name}</TruncatedText>
              </CustomLink>
            )
          })}
        </AccordionPanel>
      </AccordionItem>
    </>
  )
}

export default NavDrawer
