import { SideBarOptionMenu } from '@/types/sidebar'
import { RiAdminFill } from 'react-icons/ri'

export const adminsMenu: SideBarOptionMenu = {
  name: 'Admins',
  icon: RiAdminFill,
  path: '/admins',
  order: 1,
  permission: { name: 'superadmin', can: 'visible' },
  isShown: true,
  isActive: false,
  child: [],
}
