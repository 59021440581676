import React from 'react'
import {
  Flex,
  InputGroup,
  Input,
  InputLeftElement,
  Popover,
  PopoverTrigger,
  Box,
  Icon,
  PopoverContent,
  PopoverBody,
  Stack,
  Text,
  useColorMode,
} from '@chakra-ui/react'
import { BsFunnel, BsDash } from 'react-icons/bs'

import LEColorConstants, {
  bgGrayThemeColor,
  bgThemeColor,
  basicTextColor,
} from '@/lib/constants/colorConstants'
import { SearchIcon } from '@chakra-ui/icons'

import CustomButton from '../Button'

type ISearchFilter = {
  filterElement?: React.ReactElement
  placeholder?: string
  value?: string
  onChange?: (e: any) => void
}

function SearchFilter({
  filterElement,
  placeholder = 'search by candidate ID',
  value,
  onChange,
}: ISearchFilter) {
  const { colorMode } = useColorMode()
  return (
    <Flex
      p="0.75rem"
      mt="1.875rem"
      borderRadius="4px"
      bgColor={bgGrayThemeColor[colorMode]}
      alignItems="center"
      justifyContent="space-between"
      mb="1.5rem"
    >
      <InputGroup
        w="22.25rem"
        bgColor={bgThemeColor[colorMode]}
        borderRadius="4px"
      >
        <Input
          placeholder={placeholder}
          type="search"
          value={value}
          onChange={onChange}
        />
        <InputLeftElement children={<SearchIcon />} />
      </InputGroup>

      <Popover placement="bottom-start">
        {({ onClose }) => (
          <>
            <PopoverTrigger>
              <Box>
                <CustomButton
                  w="6.063rem"
                  h="2.5rem"
                  fontSize="1rem"
                  fontWeight="semibold"
                  borderRadius="5px"
                  color={basicTextColor[colorMode]}
                  bgColor={bgThemeColor[colorMode]}
                >
                  <Icon mr="0.5rem" as={BsFunnel} /> Filter
                </CustomButton>
              </Box>
            </PopoverTrigger>
            <PopoverContent py="2.063rem" _focus={{ outline: 'none' }}>
              <PopoverBody>{filterElement}</PopoverBody>
            </PopoverContent>
          </>
        )}
      </Popover>
    </Flex>
  )
}

export default SearchFilter
