import { SideBarOptionMenu } from '@/types/sidebar'
import { BsFillPersonLinesFill } from 'react-icons/bs'

export const usersMenu: SideBarOptionMenu = {
  name: 'Users',
  icon: BsFillPersonLinesFill,
  path: '/users',
  order: 1,
  permission: { name: 'users', can: 'visible' },
  isShown: true,
  isActive: false,
  child: [],
}
