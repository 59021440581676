import { useEffect, useState } from 'react'
import { useStores } from '../stores'

const useAuthVerify = () => {
  const { auth } = useStores()

  return {
    isFromSchoolNotion:
      auth.currentOrganization?.meta?.subscription_type === 'WhiteLabel' &&
      auth.currentOrganization.category === 'Education',
  }
}

export { useAuthVerify }
