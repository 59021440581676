import React from 'react'
import RegularTab from './RegularTab'

export default function Regular() {
  return (
    <>
      <RegularTab />
    </>
  )
}
