import { UseMutateFunction, useMutation, useQueryClient } from 'react-query'
import { useToast } from '@chakra-ui/react'

import { axiosInstance } from '@/lib/helpers'
import { queryKeys } from '@/lib/react-query/constants'

// for when server call is needed
async function changeExamStatus({ exam_id, status }: any): Promise<void> {
  await axiosInstance.patch(`/organization/exam/${exam_id}/change_status`, {
    status,
  })
}

export function useChangeExamStatus(): UseMutateFunction<
  void,
  unknown,
  { exam_id: string; status: 'active' | 'disabled' | 'completed' },
  unknown
> {
  const queryClient = useQueryClient()
  const toast = useToast()

  const { mutate } = useMutation(changeExamStatus, {
    onSuccess: () => {
      queryClient.invalidateQueries([queryKeys.examsAdmin])
      toast({
        title: 'Exam status changed',
        status: 'success',
      })
    },
  })

  return mutate
}
