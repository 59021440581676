import { brandPrimayColor } from '@/lib/constants/colorConstants'
import {
  Box,
  Flex,
  Icon,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Stack,
  Text,
  TextProps,
  useColorMode,
} from '@chakra-ui/react'
import React from 'react'
import {
  BsCheckCircle,
  BsExclamationCircle,
  BsQuestionCircle,
} from 'react-icons/bs'
import CustomButton from '../Button'

interface IProps {
  type: 'success' | 'confirmation' | 'error'
  title?: TextProps | string
  message?: TextProps | string
  isOpen: boolean
  isAllowButtons?: boolean
  mainActiontext?: string
  secondaryActionText?: string
  isAllowMultipleButtons?: boolean
  onClose: () => void
  handleClick?: () => void
  handleDefaultButton?: () => void
  children?: React.ReactNode
  isLoading?: boolean
}

const CustomModal = ({
  type,
  title,
  message,
  isOpen,
  mainActiontext = 'Proceed',
  secondaryActionText = 'Cancel',
  isAllowMultipleButtons = true,
  isAllowButtons = true,
  handleDefaultButton,
  onClose,
  handleClick,
  isLoading,
  children,
}: IProps) => {
  const { colorMode } = useColorMode()
  const modalSize = {
    base: '319px',
    sm: '319px',
    md: '700px',
    lg: '900px',
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent maxW={modalSize}>
        <ModalBody
          py={{ base: '3.125rem', md: '5.563rem' }}
          px={{ base: '1rem', md: '5.875rem', lg: '10.875rem' }}
        >
          <Flex alignItems="center" flexDirection="column">
            {type === 'success' ? (
              <Icon
                fontSize={{ base: '2.5rem', md: '5rem' }}
                color="whatsapp.400"
                as={BsCheckCircle}
              />
            ) : type === 'confirmation' ? (
              <Icon
                fontSize={{ base: '2.5rem', md: '5rem' }}
                as={BsQuestionCircle}
              />
            ) : (
              <Icon
                fontSize={{ base: '2.5rem', md: '5rem' }}
                color="red.400"
                as={BsExclamationCircle}
              />
            )}
            <Text
              mt="1.75rem"
              fontSize={{ base: '1.125rem', md: '1.5rem' }}
              fontWeight="medium"
              textAlign="center"
            >
              {title}
            </Text>
            <Text
              mt="1.75rem"
              fontSize={{ base: '1rem', md: '1.5rem' }}
              fontWeight="normal"
              textAlign="center"
            >
              {message}
            </Text>
          </Flex>

          {isAllowButtons && (
            <Box mt="3.25rem">
              {isAllowMultipleButtons ? (
                <Stack
                  spacing={{ base: '4', md: '10.875rem' }}
                  direction={{ base: 'column', md: 'row' }}
                  justifyContent="center"
                  alignItems="center"
                >
                  <CustomButton
                    w={{ base: '9.813rem', md: '17.063rem' }}
                    h={{ base: '2.5rem', md: '2.875rem' }}
                    fontSize="0.875rem"
                    fontWeight="semibold"
                    bgColor="transparent"
                    border="1px"
                    borderRadius="5px"
                    color={brandPrimayColor[colorMode]}
                    borderColor={brandPrimayColor[colorMode]}
                    onClick={
                      handleDefaultButton ? handleDefaultButton : onClose
                    }
                    isDisabled={isLoading}
                  >
                    {secondaryActionText}
                  </CustomButton>
                  <CustomButton
                    w={{ base: '9.813rem', md: '17.063rem' }}
                    h={{ base: '2.5rem', md: '2.875rem' }}
                    fontSize="0.875rem"
                    fontWeight="semibold"
                    borderRadius="5px"
                    onClick={handleClick}
                    isDisabled={isLoading}
                  >
                    {mainActiontext}
                  </CustomButton>
                </Stack>
              ) : (
                <Flex justifyContent="center">
                  <CustomButton
                    w={{ base: '9.813rem', md: '17.063rem' }}
                    h={{ base: '2.5rem', md: '2.875rem' }}
                    fontSize="0.875rem"
                    fontWeight="semibold"
                    borderRadius="5px"
                    onClick={onClose}
                  >
                    {'Ok'}
                  </CustomButton>
                </Flex>
              )}
            </Box>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default CustomModal
