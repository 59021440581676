import React from 'react'
import {
  Box,
  chakra,
  Heading,
  Flex,
  Text,
  Avatar,
  FlexProps,
  Menu,
  MenuButton,
  IconButton,
  Stack,
  useColorMode,
  useColorModeValue,
  useDisclosure,
  Image,
  HStack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  VStack,
} from '@chakra-ui/react'
import { useRouter } from 'next/router'
import {
  AiOutlineBell,
  AiOutlineLogout,
  AiOutlineMenu,
  AiOutlineQuestionCircle,
  AiOutlineSwap,
} from 'react-icons/ai'
import { FaMoon, FaSun } from 'react-icons/fa'
import { observer } from 'mobx-react-lite'
import { css } from '@emotion/react'

import { getUser, logout as removeLocalstorageToken } from '@/lib/helpers'
import { APP_NAME } from '@/lib/constants/appConstants'

import { CustomLink } from '../Link'
import NavDrawer from '../NavDrawer'
import { stores, useStores } from '@/stores/index'
import DeleteDialog from '../DeleteDialog'
import TruncatedText from '../TruncatedText'
import { toJS } from 'mobx'
import CustomButton from '../Button'

interface HeaderProps extends FlexProps {
  type?: 'authenticated' | 'unauthenticated'
  open: any
  isRegistration?: boolean
}

const Header = observer(
  ({ open, type = 'authenticated', ...rest }: HeaderProps) => {
    const router = useRouter()
    const { auth, candidate } = useStores()
    const { toggleColorMode: toggleMode } = useColorMode()
    const text = useColorModeValue('dark', 'light')
    const SwitchIcon = useColorModeValue(FaMoon, FaSun)
    const { admin, isExpired } = getUser()

    const { isOpen, onOpen, onClose } = useDisclosure()
    const {
      isOpen: isLogoutModalOpen,
      onOpen: onOpenLogoutModal,
      onClose: onCloseLogoutModal,
    } = useDisclosure()
    const {
      isOpen: isOpenForOrgs,
      onOpen: onOpenForOrgs,
      onClose: onCloseForOrgs,
    } = useDisclosure()

    const hostname = React.useMemo(() => {
      if (typeof window !== 'undefined') {
        return window.location.host.split('.')[0]
      }
      return null
    }, [window.location.host])

    if (type === 'unauthenticated') {
      return (
        <Flex
          as="nav"
          align="center"
          justify="center"
          wrap="wrap"
          padding={6}
          color="black"
          boxShadow="base"
          zIndex="2"
          {...rest}
        >
          <Flex justify="center" mr={5}>
            <CustomLink href="/">
              <Heading as="h1" size="lg" ml={{ base: '0', md: '10px' }}>
                <Flex>
                  <Text color="white" fontWeight="bold" fontSize="19px">
                    {APP_NAME}
                  </Text>
                </Flex>
              </Heading>
            </CustomLink>
          </Flex>
        </Flex>
      )
    }

    // if (isExpired) logout()
    // useEffect(() => {
    //   getUserPermissions()
    // }, [])

    return (
      <>
        <chakra.header
          w="full"
          color="black"
          boxShadow="lg"
          zIndex="999"
          css={css`
            & {
              position: -webkit-sticky;
              position: sticky;
              top: 0;
            }
          `}
          {...rest}
        >
          <chakra.div h="4.5rem" transition="all 1s" mx="auto">
            <Flex
              w="full"
              h="full"
              px="6"
              alignItems="center"
              justifyContent="space-between"
            >
              <Flex align="center">
                <HStack alignItems="center">
                  <CustomLink href="/">
                    <Image
                      boxSize="140px"
                      objectFit="contain"
                      src="/assets/logo/logo-250x150.png"
                      alt="test notion logo"
                      display={{ base: 'none', md: 'block' }}
                    />
                  </CustomLink>
                  {((hostname &&
                    hostname !== 'app' &&
                    candidate.getCandidate()) ||
                    (hostname && hostname === 'app')) && (
                    <IconButton
                      onClick={onOpen}
                      variant="ghost"
                      color="white"
                      fontSize="1.6rem"
                      _focus={{ boxShadow: 'none' }}
                      display={{ base: 'block', md: 'none' }}
                      colorScheme="whiteAlpha"
                      aria-label="menu"
                      icon={<AiOutlineMenu />}
                    />
                  )}
                  <NavDrawer isOpen={isOpen} onClose={onClose} />
                </HStack>
              </Flex>

              {/* {((hostname && hostname !== 'app' && candidate.getCandidate()) ||
                (hostname && hostname === 'app')) && ( */}
              <Flex gap="8px" mr="10px" alignItems="center">
                {hostname && hostname === 'app' && (
                  <Flex alignItems="center">
                    <Box
                      display={{ base: 'none', md: 'block' }}
                      mt={{ base: 4, md: 0 }}
                    >
                      <Menu>
                        <MenuButton onClick={() => router.push('/profile')}>
                          <Stack direction="row" alignItems="center" mr="10px">
                            <Avatar
                              name={
                                auth.currentOrganization &&
                                auth.currentOrganization.name
                              }
                              src={
                                auth.currentOrganization &&
                                auth.currentOrganization.avatar
                              }
                              size="md"
                            />
                            <TruncatedText>
                              <Text fontWeight="bold" color="white">
                                {auth.currentOrganization &&
                                  auth.currentOrganization.name}
                              </Text>
                            </TruncatedText>
                          </Stack>
                        </MenuButton>
                      </Menu>
                    </Box>
                  </Flex>
                )}

                {/* <IconButton
                  display={{ base: 'none', sm: 'inherit' }}
                  variant="ghost"
                  color="white"
                  fontSize="1.6rem"
                  colorScheme="whiteAlpha"
                  icon={<AiOutlineQuestionCircle />}
                  aria-label="question"
                /> */}
                <Flex position="relative">
                  {/* if greater 1 */}
                  {auth?.organizations?.length > 1 && (
                    <IconButton
                      variant="ghost"
                      color="white"
                      fontSize="1.6rem"
                      colorScheme="whiteAlpha"
                      icon={<AiOutlineSwap />}
                      aria-label="Switch Campus"
                      onClick={onOpenForOrgs}
                    />
                  )}

                  {/* <IconButton
                    variant="ghost"
                    color="white"
                    fontSize="1.6rem"
                    colorScheme="whiteAlpha"
                    icon={<AiOutlineBell />}
                    aria-label="notifications"
                  /> */}
                  {/* <Box
                    position="absolute"
                    top="8px"
                    right="9px"
                    rounded="full"
                    bg="red.500"
                    boxSize="6px"
                  /> */}
                </Flex>
                <IconButton
                  variant="ghost"
                  color="white"
                  fontSize="1.6rem"
                  colorScheme="whiteAlpha"
                  icon={<AiOutlineLogout />}
                  aria-label="Signout"
                  onClick={() => {
                    onOpenLogoutModal()
                  }}
                />
                <IconButton
                  size="md"
                  fontSize="lg"
                  aria-label={`Switch to ${text} mode`}
                  variant="ghost"
                  color="current"
                  ml={{ base: '0', md: '3' }}
                  onClick={toggleMode}
                  icon={<SwitchIcon />}
                  _focus={{ boxShadow: 'none' }}
                />
              </Flex>
            </Flex>
          </chakra.div>
        </chakra.header>
        {/* Logout Confirmation */}
        <DeleteDialog
          isOpen={isLogoutModalOpen}
          onClick={() => {
            if (hostname === 'app') {
              removeLocalstorageToken('Admin')
            } else {
              removeLocalstorageToken('Candidate')
            }
            onCloseLogoutModal()
          }}
          onClose={onCloseLogoutModal}
          title="Are you sure you want to logout?"
          message=""
          confirmationText="Yes"
        />

        {/* Modal to list organizations to select from */}
        <Modal
          isOpen={isOpenForOrgs}
          onClose={onCloseForOrgs}
          size="full"
          motionPreset="slideInBottom"
          isCentered
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Select Campus</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              {auth?.organizations?.map(campus => (
                <VStack
                  key={campus?.admin?.organization?.id}
                  spacing="2"
                  alignItems="flex-start"
                >
                  <CustomButton
                    key={campus?.admin?.organization?.id}
                    onClick={() => {
                      auth.switchOrganization(campus?.admin?.organization?.id)
                      // reload page
                      window.location.reload()

                      onCloseForOrgs()
                    }}
                    variant={
                      auth.currentOrganization?.id ===
                      campus?.admin?.organization?.id
                        ? 'default'
                        : 'outline'
                    }
                    size="sm"
                    mt="5px"
                  >
                    {campus?.admin?.organization?.name}
                  </CustomButton>
                </VStack>
              ))}
            </ModalBody>
          </ModalContent>
        </Modal>
      </>
    )
  }
)

export default Header
