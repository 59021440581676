import { SideBarOptionMenu } from '@/types/sidebar'
import { AiOutlineBook } from 'react-icons/ai'

export const activityMenu: SideBarOptionMenu = {
  name: 'Activity Log',
  icon: AiOutlineBook,
  path: '/activity',
  order: 1,
  permission: { name: 'activity', can: 'visible' },
  isShown: true,
  isActive: false,
  child: [],
}
