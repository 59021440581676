import React, { useState } from 'react'
import { Box, Flex, Icon, InputRightElement, Text } from '@chakra-ui/react'
import { BsDownload } from 'react-icons/bs'
import { AiOutlineSearch } from 'react-icons/ai'
import ChartBox from '@/components/Chart'
import DataTable from '@/components/DataTable'
import { TextInput } from '@/components/Input'

export default function PaygTab() {
  const [searchInput, setSearchInput] = useState<any>()

  const handleSearch = (event: any) => {
    event.preventDefault()
    setSearchInput(event.target.value)
  }

  const downloadCandidate = () => {}

  const data = [
    {
      no: 1,
      company: 'Logic Glide IJMB',
      totalcandidates: 2890,
      plan: 'Regular',
    },
    {
      no: 1,
      company: 'Splise Financial Support',
      totalcandidates: 200,
      plan: 'PAYG',
    },
    {
      no: 1,
      company: 'Logic Glide IJMB',
      totalcandidates: 9000,
      plan: 'Regular',
    },
    {
      no: 1,
      company: 'Logic Glide IJMB',
      totalcandidates: 2400,
      plan: 'PAYG',
    },
  ]

  const columns = React.useMemo(
    () => [
      {
        Header: 'No',
        accessor: 'no',
      },
      {
        Header: 'Company',
        accessor: 'company',
      },
      {
        Header: 'Total Candidates',
        accessor: 'totalcandidates',
      },
      {
        Header: 'Plan',
        accessor: 'plan',
      },
    ],
    []
  )
  return (
    <>
      <Box maxW="70rem" mt="3.313rem">
        <Flex justifyContent="space-between" alignItems="center" mb="1.125rem">
          <TextInput
            labelProps={{
              fontSize: '0.88rem',
              fontWeight: 'medium',
              mb: '0.2rem',
            }}
            formControlProps={{
              maxW: '15.4rem',
            }}
            TextInputProps={{
              h: '2.188rem',
              borderRadius: '2.3px',
            }}
            placeholder="Search"
            type="search"
            value={searchInput}
            handleChange={handleSearch}
            TextInputElement={
              <InputRightElement>
                <AiOutlineSearch />
              </InputRightElement>
            }
          />
          <Icon
            width="1.25rem"
            height="1.125rem"
            color="tabBtnColor"
            as={BsDownload}
            cursor="pointer"
            onClick={downloadCandidate}
          />
        </Flex>
        <DataTable
          isAllowSelection={false}
          variant="exams-table"
          columns={columns}
          data={data}
        />
      </Box>
    </>
  )
}

export const PaygChart = () => {
  return (
    <>
      <Flex>
        <Box>
          <Text fontSize="1rem">
            Total Users:{' '}
            <Text as="span" color="primary" fontWeight="medium">
              209882372837282980
            </Text>
          </Text>
        </Box>
        <Box overflow="hidden">
          <ChartBox
            typeofChart="piechart"
            noOfUsers={909}
            chartData={{
              labels: ['PAYG', 'Regular Plan'],
              datasets: [
                {
                  label: '',
                  backgroundColor: ['#68EBAC', '#FF0000'],
                  data: [70, 30],
                },
              ],
            }}
            title=""
            options={{
              responsive: true,
              plugins: {
                legend: {
                  display: true,
                  position: 'bottom' as const,
                  fullSize: false,
                  labels: {
                    boxWidth: 13,
                  },
                },
              },
            }}
          />
        </Box>
      </Flex>
    </>
  )
}
