import { useQuery } from 'react-query'

import { axiosInstance } from '@/lib/helpers'
import { queryKeys } from '@/lib/react-query/constants'

// common options for both useQuery and prefetchQuery
const commonOptions = {
  staleTime: 0,
  cacheTime: 300000, // 5 minutes
}

async function getResults(): Promise<any[]> {
  const {
    data: { data },
  } = await axiosInstance.get('/candidate/exam/results')
  return data
}

interface useResult {
  results: IDataResponse
  isLoading: boolean
}

export function useResult(): useResult {
  const fallback = {
    count: 0,
    received: 0,
    total_pages: 0,
    current_page: 0,
    rows: [],
  } as any

  const { data: results = fallback, isLoading } = useQuery(
    [queryKeys.results],
    () => getResults(),
    {
      ...commonOptions,
      refetchOnMount: true,
      refetchOnReconnect: true,
      refetchOnWindowFocus: true,
    }
  )

  return {
    results,
    isLoading,
  }
}
