import React from 'react'
import moment from 'moment'
import {
  Box,
  Divider,
  Flex,
  Grid,
  GridItem,
  List,
  ListItem,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react'

import LEColorConstants from '@/lib/constants/colorConstants'
import { ngnCurrencyFormatter } from '@/lib/helpers'
import { stores } from '@/stores/index'

type ProductDetailsProps = {
  id: number
  description: string
  unit?: number | any
  price?: number
  qty: number
}

type InvoiceDetailsProps = {
  invoiceId: string
  organization: string
  address: string
  date: string
  totalAmount: number
  status: string
  transactionType: string
  details: ProductDetailsProps[]
}

const INVOICE: InvoiceDetailsProps = {
  invoiceId: '73798939723092',
  organization: 'mypa school, minna',
  address: 'fadukpe, dutsa-kura, minna, niger state',
  date: moment().format('DD MM YYYY, hh:mm A'),
  totalAmount: 43400,
  status: 'success',
  transactionType: 'debit',
  details: [
    {
      id: 1,
      description: 'activate chm102 test',
      unit: 5,
      qty: 560,
    },
    {
      id: 2,
      description: 'email for service for 9 candidates',
      unit: 9,
      qty: 32,
    },
    {
      id: 2,
      description: 'enable proctoring',
      unit: 2,
      qty: 20,
    },
  ],
}

interface IReceipt {
  transaction: ITransaction
}

const Receipt = ({ transaction }: IReceipt) => {
  const totalAmount = React.useMemo(() => {
    return transaction?.invoices?.reduce((acc, curr) => {
      return acc + Number(curr.cost)
    }, 0)
  }, [transaction?.invoices])

  if (!transaction) return <></>

  return (
    <Box w="full" bgColor="white">
      <Flex
        w="full"
        py="1.188rem"
        px="1.875rem"
        color="white"
        alignItems="center"
        justifyContent="space-between"
        bgColor={LEColorConstants.secondary}
      >
        <Text fontSize="2.5rem" fontWeight="semibold">
          Invoice
        </Text>
        <Box>
          <Text>No 25, Asadam Road.</Text>
          <Text>Ilorin, Kwara State, Nigeria.</Text>
          <Text>info@testnotion.com</Text>
        </Box>
      </Flex>

      <Box px="1.875rem" mt="6.5rem">
        <Flex
          fontSize="1.125rem"
          fontWeight="medium"
          textTransform="capitalize"
          justifyContent="space-between"
        >
          <Box>
            <Text color="blackAlpha.600" fontSize="1rem" fontWeight="normal">
              Billed to:
            </Text>
            <Text>{stores.auth.currentOrganization?.name}</Text>
            <Text w="300px">{stores.auth.currentOrganization?.address}</Text>
          </Box>
          <Box>
            <List spacing={2}>
              <ListItem>
                <Text
                  color="blackAlpha.600"
                  fontSize="1rem"
                  fontWeight="normal"
                >
                  Invoice ID:
                </Text>
                <Text>{transaction.transaction_reference}</Text>
              </ListItem>
              <ListItem>
                <Text
                  color="blackAlpha.600"
                  fontSize="1rem"
                  fontWeight="normal"
                >
                  Date of issue:
                </Text>
                <Text>
                  {moment(transaction.created_at).format(
                    'ddd, DD-MM-YYYY, hh:mm A'
                  )}
                </Text>
              </ListItem>
            </List>
          </Box>
          <Box>
            <Text color="blackAlpha.600" fontSize="1rem" fontWeight="normal">
              Total:
            </Text>
            <Text
              fontSize="2.5rem"
              fontWeight="medium"
              color={LEColorConstants.secondary}
            >
              {transaction.remarks.includes('Wallet top up')
                ? ngnCurrencyFormatter(totalAmount || 0)
                : totalAmount + ' Units'}
            </Text>
          </Box>
        </Flex>

        <Divider
          mt="3.563rem"
          border="4px"
          borderColor={LEColorConstants.secondary}
        />

        <TableContainer mt="1.875rem">
          <Table fontSize="1.206rem" variant="unstyled" colorScheme="teal">
            <Thead fontWeight="semibold" color={LEColorConstants.secondary}>
              <Tr>
                <Th>Description</Th>
                <Th isNumeric>Qty</Th>
                <Th isNumeric>Cost</Th>
              </Tr>
            </Thead>
            <Tbody fontWeight="medium">
              {transaction?.invoices?.map(data => (
                <Tr
                  key={`product_details_${data.id}`}
                  textTransform="capitalize"
                >
                  <Td>{data.remarks}</Td>
                  <Td>{data.quantity}</Td>
                  <Td isNumeric>{data.cost}</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>

        <Divider
          mt="8.75rem"
          border="4px"
          borderColor={LEColorConstants.secondary}
        />

        <Flex
          mt="1.875rem"
          fontSize="1.125rem"
          fontWeight="semibold"
          alignItems="end"
          flexDirection="column"
          gap={2}
        >
          {/* <Grid mr="8rem" w="350px" templateColumns="repeat(2, 1fr)" gap={2}>
            <GridItem
              textAlign="end"
              w="100%"
              h="10"
              color={LEColorConstants.secondary}
            >
              Sub Total:
            </GridItem>
            <GridItem textAlign="end" w="100%" h="10">
              {ngnCurrencyFormatter(totalAmount)}
            </GridItem>
          </Grid> */}
          {/* <Grid mr="8rem" w="350px" templateColumns="repeat(2, 1fr)" gap={6}>
            <GridItem
              textAlign="end"
              w="100%"
              h="10"
              color={LEColorConstants.secondary}
            >
              Tax:
            </GridItem>
            <GridItem textAlign="end" w="100%" h="10">
              N{'70'}
            </GridItem>
          </Grid> */}
          <Grid mr="8rem" w="350px" templateColumns="repeat(2, 1fr)" gap={6}>
            <GridItem
              textAlign="end"
              w="100%"
              h="10"
              color={LEColorConstants.secondary}
            >
              Total:
            </GridItem>
            <GridItem textAlign="end" w="100%" h="10">
              {transaction.remarks.includes('Wallet top up')
                ? ngnCurrencyFormatter(totalAmount || 0)
                : totalAmount + ' Units'}
            </GridItem>
          </Grid>
        </Flex>

        <Divider
          mt="1.875rem"
          border="4px"
          borderColor={LEColorConstants.secondary}
        />
      </Box>

      <Text
        mt="8.188rem"
        fontSize="2.5rem"
        fontWeight="semibold"
        textAlign="center"
        textTransform="uppercase"
        color={LEColorConstants.secondary}
      >
        Thank you
      </Text>
    </Box>
  )
}

export default Receipt
