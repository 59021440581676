import React, { useState } from 'react'
import SettingsAuthTab from './Components/SettingAuthsTab'

interface ISettingsPage {
  type: SettingPageType
}

function SettingsPage({ type }: ISettingsPage) {
  return (
    <>
      <SettingsAuthTab type={type} />
    </>
  )
}

export default SettingsPage
