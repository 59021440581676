export const colorComponentConstants = [
  {
    id: 1,
    title: 'Top Nav Background color',
    color: '#C4C4C4',
    name: 'topnavbg',
  },
  {
    id: 2,
    title: 'Top Nav Text color',
    color: '#C4C4C4',
    name: 'topnavtex',
  },
  {
    id: 3,
    title: 'Side Nav Background color',
    color: '#C4C4C4',
    name: 'sidenavbg',
  },
  {
    id: 4,
    title: 'Side Nav Text color',
    color: '#C4C4C4',
    name: 'sidenavtext',
  },
  {
    id: 5,
    title: 'Active page color',
    color: '#68EBAC',
    name: 'activepage',
  },
]
