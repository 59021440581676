import React from 'react'
import { Box, Text, Wrap } from '@chakra-ui/react'

type QuestionTagProps = {
  data?: string[]
  title?: string
}

export default function QuestionCategory({ data, title }: QuestionTagProps) {
  return (
    <Box w="full">
      <Text fontWeight="regular" fontSize="0.9rem" as="span" mr="0.5rem">
        {title}
      </Text>
      <Text
        textTransform="capitalize"
        fontSize="0.8rem"
        fontWeight="regular"
        color="primary"
        as="span"
      >
        {data?.join(` | `)}
      </Text>
    </Box>
  )
}
