import React, { useState } from 'react'

import {
  Grid,
  GridItem,
  Stack,
  Text,
  Box,
  UnorderedList,
  Flex,
  Input,
  Avatar,
  FormLabel,
} from '@chakra-ui/react'
import { BsCloudUpload } from 'react-icons/bs'

import { TextInput } from '@/components/Input'
import { PictureFormatConstants } from '@/lib/constants/pictureFormatConstants'
import { PictureFormat } from './PictureFormat'
import CustomButton from '@/components/Button'

const CompanyTab = () => {
  const [file, setFile] = useState<any>(undefined)

  const initialInputs = { copname: '', category: '' }
  const [stateValue, setStateValue] = useState(initialInputs)
  const { copname, category } = stateValue

  const handleCompanyChange = (event: any) => {
    event?.preventDefault?.()

    setStateValue({ ...stateValue, [event.target.name]: event.target.value })
  }

  const handleChangeFile = (event: any) => {
    setFile(URL.createObjectURL(event.target.files[0]))
  }

  const handleSubmit = () => {}

  return (
    <Box mt="5.4375rem" ml={'2rem'} mr="1rem">
      <Grid columnGap={8} templateColumns={['1fr', 'repeat(2, 1fr)']}>
        <GridItem>
          <TextInput
            label="Company Name"
            labelProps={{
              fontSize: '0.88rem',
              opacity: 1,
              mb: '1.2rem',
              fontWeight: 'medium',
            }}
            formControlProps={{
              mb: '3.8rem',
              maxW: '15.63rem',
            }}
            TextInputProps={{
              outline: '1px solid black',
              h: '2.188rem',
              borderRadius: '5px',
            }}
            placeholder="Company Name"
            type="Text"
            name="copname"
            value={copname}
            handleChange={handleCompanyChange}
          />
        </GridItem>
        <GridItem>
          <TextInput
            label="Category"
            labelProps={{
              fontSize: '0.88rem',
              opacity: 0.3,
              mb: '1.2rem',
              fontWeight: 'medium',
            }}
            formControlProps={{
              mb: '3.8rem',
              maxW: '15.63rem',
            }}
            TextInputProps={{
              outline: '1px solid black',
              h: '2.188rem',
              borderRadius: '5px',
              isDisabled: true,
            }}
            placeholder="School"
            type="Text"
            value={category}
            name="category"
            handleChange={handleCompanyChange}
          />
        </GridItem>
      </Grid>
      <Stack align="top" spacing={6} gap={4} direction={['column', 'row']}>
        <Text fontSize="0.88rem" fontWeight="medium">
          Company Logo
        </Text>
        <FormLabel
          cursor="pointer"
          htmlFor="avatar"
          onChange={handleChangeFile}
        >
          <Avatar
            name={''}
            src={file ? file : 'photo'}
            size="xl"
            w="6.5rem"
            h="6.5rem"
            icon={<BsCloudUpload color="black" />}
            borderRadius="0"
            border={`2px dashed black `}
            bg="none"
          />
          <Input type="file" accept="image/*" id="avatar" display="none" />
        </FormLabel>
        <UnorderedList
          ml="10px"
          fontSize="0.875rem"
          fontWeight="medium"
          opacity={0.4}
        >
          {PictureFormatConstants.map(picsFormate => (
            <PictureFormat key={picsFormate.id} {...picsFormate} />
          ))}
        </UnorderedList>
      </Stack>
      <Flex>
        <CustomButton
          w="6.625rem"
          h="2.75rem"
          m="0 auto"
          mt="2.688rem"
          borderRadius="5px"
          fontSize="1rem"
          fontWeight="medium"
          onClick={handleSubmit}
        >
          Save
        </CustomButton>
      </Flex>
    </Box>
  )
}

export default CompanyTab
