import { Box, Text } from '@chakra-ui/react'
import React from 'react'

type TitleQsTimeProps = {
  course: string | number
  numOfQuestion: number
  time: Date | string | any
}

export default function TitleQuestionTime({
  course,
  numOfQuestion,
  time,
}: TitleQsTimeProps) {
  return (
    <Box>
      <Text
        fontSize={['1rem', '1.2rem']}
        fontWeight="medium"
        mb={['0.5rem', '1rem']}
      >
        {course} EXAMINATION
      </Text>
      <Text
        fontSize={['0.8rem', '1rem']}
        fontWeight="medium"
        mb={['0.5rem', '1rem']}
      >
        {numOfQuestion} Questions
      </Text>
      <Text
        fontSize={['0.8rem', '1rem']}
        fontWeight="medium"
        mb={['0.5rem', '1.25rem']}
      >
        Time: {time}minutes
      </Text>
    </Box>
  )
}
