import { bgThemeColor } from '@/lib/constants/colorConstants'
import { HStack, Icon, StackProps, useColorMode } from '@chakra-ui/react'

const HeaderTab = (props: StackProps) => {
  const { colorMode } = useColorMode()
  return (
    <HStack
      bgColor={bgThemeColor[colorMode]}
      w="full"
      h="3.938rem"
      px="2.412rem"
      boxShadow="0px 4px 10px rgba(0, 0, 0, 0.05)"
      {...props}
    >
      {props.children}
    </HStack>
  )
}

export default HeaderTab
