import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  chakra,
  Flex,
  Text,
  useColorMode,
  Box,
  Center,
  SlideFade,
  TableContainer,
} from '@chakra-ui/react'
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  TriangleDownIcon,
  TriangleUpIcon,
} from '@chakra-ui/icons'
import {
  useReactTable,
  flexRender,
  getCoreRowModel,
  ColumnDef,
  SortingState,
  getSortedRowModel,
  getExpandedRowModel,
  ExpandedState,
  Row,
} from '@tanstack/react-table'
import React from 'react'
import {
  Pagination,
  usePagination,
  PaginationPage,
  PaginationNext,
  PaginationPrevious,
  PaginationPageGroup,
  PaginationContainer,
  PaginationSeparator,
} from '@ajna/pagination'
import { bgThemeColor } from '@/lib/constants/colorConstants'
import { TablePageLoader } from '@/components/SkeletonLoaders'

// constants
const outerLimit = 2
const innerLimit = 2

type TableProps<D extends object = {}> = {
  data: any[]
  totalPages?: number
  totalRecords?: number
  currentPage?: number
  tableHeading?: React.ReactNode
  columns: any[]
  onRowClick?: (row: Row<D>) => void
  onRowSelected?: (rows: any[]) => void
  handlePageChange?: (next: number) => void
  variant?: string
  showPagination?: boolean
  isAllowSelection?: boolean
  isLoading?: boolean
  renderRowSubComponent?: (row: Row<D>) => React.ReactNode
}

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }: { indeterminate: any }, ref) => {
    const defaultRef = React.useRef<any>()
    const resolvedRef: any = ref || defaultRef

    React.useEffect(() => {
      if (typeof indeterminate === 'boolean') {
        resolvedRef.current.indeterminate = indeterminate
      }
    }, [resolvedRef, indeterminate])

    return (
      <>
        <chakra.input type="checkbox" ref={resolvedRef} {...rest} />
      </>
    )
  }
)

const DataTable = <D extends object>({
  columns,
  data,
  totalPages,
  totalRecords,
  currentPage: currentPageProp,
  handlePageChange,
  onRowSelected,
  onRowClick,
  variant,
  showPagination = true,
  isAllowSelection = true,
  isLoading = false,
  renderRowSubComponent,
}: TableProps<D>) => {
  const { colorMode } = useColorMode()

  // Pagination
  const { pages, pagesCount, currentPage, setCurrentPage, isDisabled } =
    usePagination({
      pagesCount: Number(totalPages || 1),
      limits: {
        outer: outerLimit,
        inner: innerLimit,
      },
      initialState: {
        // isDisabled: false,
        currentPage: Number(currentPageProp || 1),
      },
    })

  const [sorting, setSorting] = React.useState<SortingState>([])
  const [rowSelection, setRowSelection] = React.useState({})
  const [expanded, setExpanded] = React.useState<ExpandedState>({})

  const tableColumns = React.useMemo<ColumnDef<any>[]>(
    () =>
      [
        isAllowSelection &&
          ({
            id: 'select',
            header: ({ table }) => (
              <chakra.div
                justifyContent="center"
                alignItems="center"
                display="flex"
                cursor="pointer"
              >
                <IndeterminateCheckbox
                  {...{
                    checked: table.getIsAllRowsSelected(),
                    indeterminate: table.getIsSomeRowsSelected(),
                    onChange: table.getToggleAllRowsSelectedHandler(),
                  }}
                />
              </chakra.div>
            ),
            cell: ({ row }) => (
              <chakra.div
                justifyContent="center"
                alignItems="center"
                display="flex"
                cursor="pointer"
              >
                <IndeterminateCheckbox
                  {...{
                    checked: row.getIsSelected(),
                    indeterminate: row.getIsSomeSelected(),
                    onChange: row.getToggleSelectedHandler(),
                  }}
                />
              </chakra.div>
            ),
          } as ColumnDef<any>),

        ...columns,
      ].filter(Boolean),
    []
  )

  const table = useReactTable({
    columns: tableColumns,
    data,
    onRowSelectionChange: setRowSelection,
    onExpandedChange: setExpanded,
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    getExpandedRowModel: getExpandedRowModel(),

    state: {
      sorting,
      rowSelection,
      expanded,
    },
  })

  const onHandlePageChange = (nextPage: number): void => {
    setCurrentPage(nextPage)
    if (handlePageChange && typeof handlePageChange === 'function') {
      handlePageChange(nextPage)
    }
  }

  const selectedRowData = React.useMemo(
    () => table.getSelectedRowModel().flatRows.map(row => row.original),
    [table.getSelectedRowModel().flatRows]
  )

  React.useEffect(() => {
    if (
      isAllowSelection &&
      selectedRowData &&
      onRowSelected &&
      typeof onRowSelected === 'function'
    ) {
      onRowSelected(selectedRowData)
    }
  }, [selectedRowData])

  return (
    <Box bg={bgThemeColor[colorMode]}>
      {/* <TableContainer> */}
      <Table variant={variant}>
        <Thead>
          {table.getHeaderGroups().map(headerGroup => (
            <Tr key={headerGroup.id}>
              {headerGroup.headers.map(header => {
                const meta: any = header.column.columnDef.meta
                return (
                  <Th
                    key={header.id}
                    onClick={header.column.getToggleSortingHandler()}
                    isNumeric={meta?.isNumeric}
                    fontSize="0.70rem"
                    padding=".8rem"
                    pr=".54rem"
                    fontWeight="semibold"
                  >
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}

                    <chakra.span pl="4">
                      {header.column.getIsSorted() ? (
                        header.column.getIsSorted() === 'desc' ? (
                          <TriangleDownIcon aria-label="sorted descending" />
                        ) : (
                          <TriangleUpIcon aria-label="sorted ascending" />
                        )
                      ) : null}
                    </chakra.span>
                  </Th>
                )
              })}
            </Tr>
          ))}
        </Thead>
        <Tbody>
          {table.getRowModel().rows.map(row => (
            <React.Fragment>
              <Tr
                key={row.id}
                onClick={() => {
                  onRowClick && onRowClick(row.original)
                }}
              >
                {row.getVisibleCells().map(cell => {
                  const meta: any = cell.column.columnDef.meta
                  return (
                    <Td
                      key={cell.id}
                      isNumeric={meta?.isNumeric}
                      px=".8rem"
                      fontSize="0.85rem"
                      fontWeight="normal"
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </Td>
                  )
                })}
              </Tr>
              {/*
                    If the row is in an expanded state, render a row with a
                    column that fills the entire length of the table.
                  */}
              {row.getIsExpanded() ? (
                <Tr>
                  <Td colSpan={table.getVisibleFlatColumns().length}>
                    <SlideFade offsetY="-50px" in={row.getIsExpanded()}>
                      {/*
                          Inside it, call our renderRowSubComponent function. In reality,
                          you could pass whatever you want as props to
                          a component like this, including the entire
                          table instance. But for this example, we'll just
                          pass the row
                        */}
                      {renderRowSubComponent &&
                        typeof renderRowSubComponent === 'function' &&
                        renderRowSubComponent({ row } as any)}
                    </SlideFade>
                  </Td>
                </Tr>
              ) : null}
            </React.Fragment>
          ))}
        </Tbody>
      </Table>
      {/* </TableContainer> */}

      {data.length < 1 && !isLoading && (
        <Center p="4">
          <Text color="GrayText" fontWeight="medium">
            No data available
          </Text>
        </Center>
      )}

      {isLoading && <TablePageLoader />}

      {/* Pagination */}
      {showPagination && data.length > 0 && (
        <Flex
          width="full"
          justifyContent="space-between"
          alignItems="center"
          mt="8"
        >
          <Flex>
            <Text fontSize="sm" mr="6" ml="6">
              Page <b>{currentPage}</b> of {pagesCount}
            </Text>

            {totalRecords && (
              <Text fontSize="sm" mr="6">
                Total Records: <b>{totalRecords}</b>
              </Text>
            )}
          </Flex>
          <Flex>
            <Pagination
              pagesCount={Number(totalPages)}
              currentPage={currentPage}
              isDisabled={isDisabled}
              onPageChange={onHandlePageChange}
            >
              <PaginationContainer
                align="center"
                justify="space-between"
                p={4}
                w="full"
              >
                {/* Jump to First */}
                <Flex>
                  <chakra.button onClick={() => onHandlePageChange(1)}>
                    <ArrowLeftIcon fontSize=".65rem" />
                  </chakra.button>
                </Flex>
                <PaginationPrevious
                  _hover={{
                    bg: 'transparent',
                  }}
                  _focus={{
                    outline: 'none',
                  }}
                  bg="transparent"
                >
                  <ChevronLeftIcon />
                </PaginationPrevious>
                <PaginationPageGroup
                  isInline
                  align="center"
                  separator={
                    <PaginationSeparator
                      // bg="brand.primary.300"
                      fontSize="sm"
                      w={4}
                      jumpSize={11}
                      _focus={{
                        outline: 'none',
                      }}
                    />
                  }
                >
                  {pages.map((page: number) => (
                    <PaginationPage
                      w={4}
                      bg="transparent"
                      key={`pagination_page_${page}`}
                      page={page}
                      fontSize="sm"
                      fontWeight="light"
                      _hover={{
                        color: 'brand.secondary.300',
                      }}
                      _focus={{
                        outline: 'none',
                      }}
                      _current={{
                        color: 'brand.secondary.300',
                        fontSize: 'sm',
                        fontWeight: 'light',
                        w: 4,
                      }}
                    />
                  ))}
                </PaginationPageGroup>
                <PaginationNext
                  _hover={{
                    bg: 'transparent',
                  }}
                  _focus={{
                    outline: 'none',
                  }}
                  bg="transparent"
                >
                  <ChevronRightIcon />
                </PaginationNext>
                {/* Jump to Last */}
                <Flex mr="8">
                  <chakra.button
                    onClick={() => onHandlePageChange(pagesCount as number)}
                  >
                    <ArrowRightIcon fontSize=".65rem" />
                  </chakra.button>
                </Flex>
              </PaginationContainer>
            </Pagination>
          </Flex>
        </Flex>
      )}
    </Box>
  )
}

export default DataTable

DataTable.defaultProps = {
  pageSize: 50,
}
