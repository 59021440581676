import { SideBarOptionMenu } from '@/types/sidebar'
import { AiOutlineSetting } from 'react-icons/ai'

export const settingsMenu: SideBarOptionMenu = {
  name: 'Settings',
  icon: AiOutlineSetting,
  path: '/settings',
  order: 1,
  permission: { name: 'settings', can: 'visible' },
  isShown: true,
  isActive: false,
  child: [],
}
