import { FREE_TEXT_REGEX } from '../regexConstants'

export default [
  {
    name: 'search',
    label: 'Search',
    errorMessage: 'Enter search',
    required: true,
    type: 'text',
    pattern: FREE_TEXT_REGEX,
  },
]
