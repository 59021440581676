import React, { useState } from 'react'
import {
  Box,
  Flex,
  FormControl,
  IconButton,
  InputRightElement,
  Text,
  useToast,
} from '@chakra-ui/react'
import { TextInput } from '@/components/Input'
import formdata from '@/lib/constants/formData/changePassword'
import useForm from '@/hooks/useForm'

import LEColorConstants from '@/lib/constants/colorConstants'
import { FaEyeSlash, FaEye } from 'react-icons/fa'
import CustomButton from '@/components/Button'
import { axiosInstance } from '@/lib/helpers'

const toggle = {
  password: false,
  new_password: false,
  confirm_new_password: false,
}

function Password() {
  const toast = useToast()

  const [showPassword, setShowPassword] = useState<any>(toggle)

  const handleShowPassword = (name: any) => {
    setShowPassword({
      ...showPassword,
      [name]: !showPassword[name],
    })
  }

  const {
    handleChange,
    handleModChange,
    inputTypes,
    handleSubmit,
    errors,
    loading,
  } = useForm({
    inputs: formdata,
    cb: async inputs => {
      if (inputTypes.new_password !== inputTypes.confirm_new_password) {
        return toast({
          title: 'New Passwords do not match',
          description: 'Please check your password',
          status: 'error',
          variant: 'top-accent',
          isClosable: true,
        })
      }

      await axiosInstance.patch('/auth/admin/update_password/', {
        password: inputs.password,
        new_password: inputs.new_password,
      })
      toast({
        title: `Password updated successfully`,
        status: 'success',
        variant: 'top-accent',
        isClosable: true,
      })

      // reset form
      const newFormdata = formdata.reduce((acc: any, curr: any) => {
        acc[curr.name] = ''
        return acc
      }, {})

      handleModChange(newFormdata)
    },
  })
  return (
    <Flex flexDir={'column'} m={'2rem'}>
      <Text fontSize={'1rem'} maxW="25rem" mb="2rem">
        Change your account password often to prevent unauthorized access to
        your account.
      </Text>

      {formdata.map((data, i) => (
        <FormControl
          maxW={'25rem'}
          isInvalid={errors[data.name]}
          key={`change_password${i}`}
        >
          <TextInput
            name={data.name}
            handleChange={handleChange}
            value={inputTypes[data.name]}
            type={
              data.type === 'password' && showPassword[data.name] === true
                ? 'text'
                : data.type
            }
            error={errors[data.name] && data.errorMessage}
            labelProps={{
              fontSize: '1rem',
              opacity: 0.6,
            }}
            formControlProps={{
              _last: { pb: '1.563rem' },
            }}
            TextInputProps={{
              variant: 'filled',
              borderRadius: '5px',
              h: '3rem',
              _focusWithin: {
                border:
                  !errors[data.name] && `2px solid ${LEColorConstants.primary}`,
              },
            }}
            placeholder={data.label}
            TextInputElement={
              data['type'] === 'password' ? (
                <InputRightElement h="3rem" width="3.5rem">
                  <IconButton
                    onClick={() => handleShowPassword(data.name)}
                    aria-label="Toggle password"
                    background="none"
                    _hover={{
                      background: 'none',
                    }}
                    _focus={{ boxShadow: 'none' }}
                    icon={
                      showPassword[data.name] === true ? (
                        <FaEyeSlash />
                      ) : (
                        <FaEye />
                      )
                    }
                  />
                </InputRightElement>
              ) : (
                <Box />
              )
            }
          />
        </FormControl>
      ))}

      <CustomButton
        maxW="8rem"
        maxH="2.8rem"
        fontSize={'1rem'}
        fontWeight="medium"
        borderRadius={'5px'}
        isLoading={loading}
        onClick={handleSubmit}
      >
        Save
      </CustomButton>
    </Flex>
  )
}

export default Password
