import React, { useEffect, useState } from 'react'
import { Flex, Text } from '@chakra-ui/react'

import moment from 'moment'

const SideMenuTimer = () => {
  const [currentTime, setCurrentTime] = useState<any>(() => new Date())

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(new Date())
    }, 1000)

    return () => {
      clearInterval(timer)
    }
  }, [])
  return (
    <>
      <Flex pl={10} flexDir="column" gap={2} mb="40px">
        <Text fontSize="14px" fontWeight="medium" color="white">
          {moment(new Date()).format('Do - MMMM - YYYY')}
        </Text>
        <Text fontSize="20px" fontWeight="medium" color="white">
          {moment(new Date()).format('h:mm:ss A')}
        </Text>
      </Flex>
    </>
  )
}

export default SideMenuTimer
