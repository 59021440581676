import React from 'react'
import moment from 'moment'
import {
  Flex,
  IconButton,
  List,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useColorMode,
} from '@chakra-ui/react'
import { MdPrint } from 'react-icons/md'

import { secondaryThemeColor } from '@/lib/constants/colorConstants'
import { ngnCurrencyFormatter } from '@/lib/helpers'

const TransactionModal = ({
  isOpen,
  onClose,
  transactionDetail,
  transactionStatus,
  handlePrint,
}: {
  isOpen: boolean
  onClose: () => void
  transactionDetail: ITransaction
  transactionStatus: any
  handlePrint: () => void
}) => {
  const { colorMode } = useColorMode()
  const modalSize: any = {
    base: '300px',
    sm: '600px',
    md: '700px',
    lg: '900px',
  }

  if (!transactionDetail) return <></>

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent px="3.875rem" py="3.313rem" maxW={modalSize}>
        <ModalHeader>
          <Flex justifyContent="space-between" alignItems="center">
            <Text
              fontSize="1.5rem"
              fontWeight="semibold"
              color={secondaryThemeColor[colorMode]}
            >
              TRANSACTION DETAILS
            </Text>
            {transactionDetail.status === 'successful' && (
              <IconButton
                aria-label="print"
                icon={<MdPrint />}
                onClick={handlePrint}
              />
            )}
          </Flex>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody mt="1.688rem" textTransform="capitalize">
          <List fontSize="1.125rem" spacing={8}>
            <ListItem fontWeight="semibold">
              Date:{' '}
              <Text fontWeight="normal" as="span">
                {moment(transactionDetail.created_at).format(
                  'ddd, DD-MM-YYYY, hh:mm A'
                )}
              </Text>
            </ListItem>
            <ListItem fontWeight="semibold">
              Description:{' '}
              <Text fontWeight="normal" as="span">
                {transactionDetail.remarks}
              </Text>
            </ListItem>
            {transactionDetail.remarks.includes('Wallet top up') ? (
              <ListItem fontWeight="semibold">
                Amount:{' '}
                <Text fontWeight="normal" as="span">
                  {ngnCurrencyFormatter(transactionDetail.amount)}
                </Text>
              </ListItem>
            ) : (
              <ListItem fontWeight="semibold">
                Unit:{' '}
                <Text fontWeight="normal" as="span">
                  {transactionDetail.unit}
                </Text>
              </ListItem>
            )}
            <ListItem fontWeight="semibold">
              Status:{' '}
              <Text fontWeight="normal" as="span">
                {transactionDetail.status}{' '}
                {transactionStatus[transactionDetail?.status]}
              </Text>
            </ListItem>
            <ListItem fontWeight="semibold">
              Ref ID:{' '}
              <Text fontWeight="normal" as="span">
                {transactionDetail.transaction_reference}
              </Text>
            </ListItem>
          </List>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default TransactionModal
