import React, { useState } from 'react'
import { useRouter } from 'next/router'
import {
  Flex,
  Box,
  Icon,
  Text,
  Input,
  InputGroup,
  InputLeftElement,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Stack,
  useColorMode,
  useDisclosure,
} from '@chakra-ui/react'
import { AiOutlinePlusCircle, AiOutlineDownload } from 'react-icons/ai'
import { SearchIcon } from '@chakra-ui/icons'
import { BsFunnel, BsDash } from 'react-icons/bs'

import CustomButton from '@/components/Button'
import LEColorConstants, {
  basicTextColor,
  bgGrayThemeColor,
  bgThemeColor,
} from '@/lib/constants/colorConstants'
import DataTable from '@/components/DataTable'
import { createColumnHelper } from '@tanstack/react-table'
import CustomImage from '@/components/Image'
import SearchFilter from '@/components/SearchFilter'

const totalAdmin = [
  {
    id: 1,
    name: 'Mohammed Umar',
    email: 'hydrogrn508@gmail.com',
    image: '/server.svg',
    registrationDate: '2/19/21, 12:34PM',
    totalCandidates: 600,
    walletBalance: 1000,
  },
  {
    id: 2,
    name: 'Mohammed Ibrahim',
    email: 'hydrogrn508@gmail.com',
    image: '/paystack.svg',
    registrationDate: '2/19/21, 12:34PM',
    totalCandidates: 800,
    walletBalance: 400,
  },
  {
    id: 3,
    name: 'usman aliyu',
    email: 'umarmdata@gmail.com',
    image: '/server.svg',
    registrationDate: '2/19/21, 12:34PM',
    totalCandidates: 900,
    walletBalance: 555,
  },
  {
    id: 4,
    name: 'Moha Moha',
    email: 'danabucafe@gmail.com',
    image: '/server.svg',
    registrationDate: '2/19/21, 12:34PM',
    totalCandidates: 1600,
    walletBalance: 1500,
  },
  {
    id: 5,
    name: 'Abdullahi Ibrahim',
    email: 'info@gmail.com',
    image: '/server.svg',
    registrationDate: '2/19/21, 12:34PM',
    totalCandidates: 2600,
    walletBalance: 2500,
  },
  {
    id: 6,
    name: 'Umar Ibrahim',
    email: 'logicglide@gmail.com',
    image: '/server.svg',
    registrationDate: '2/19/21, 12:34PM',
    totalCandidates: 3600,
    walletBalance: 3500,
  },
  {
    id: 7,
    name: 'Said Ibrahim',
    email: 'umarahh4u@gmail.com',
    image: '/server.svg',
    registrationDate: '2/19/21, 12:34PM',
    totalCandidates: 4600,
    walletBalance: 4500,
  },
  {
    id: 8,
    name: 'Mohammed Suleiman',
    email: 'hydrogrn@gmail.com',
    image: '/server.svg',
    registrationDate: '2/19/21, 12:34PM',
    totalCandidates: 5600,
    walletBalance: 5500,
  },
]

function TotalAdminPage() {
  const { colorMode } = useColorMode()
  const columnHelper = createColumnHelper<ITotalAdmins>()
  const router = useRouter()
  const [value, setValueSearch] = useState<any>('')

  const handleChange = (e: any) => {
    setValueSearch(e.target.value)
  }

  const handleCreditDebitAdmin = (data: any) => {
    router.push({ pathname: '/totaladmin/creditdebit', query: data })
  }

  const handleViewUser = (data: any) => {
    router.push({
      pathname: `/totaladmin/${data.id}/`,
      query: data,
    })
  }

  const columns = React.useMemo(
    () => [
      columnHelper.accessor('name', {
        header: 'NAME & EMAIL',
        cell: ({ row: { original: data } }) => (
          <Flex>
            <Flex flex={1} alignItems="center" gap="1rem">
              <CustomImage
                src={data.image}
                width="40px"
                height="40px"
                alt={`${data.name} image`}
                borderRadius="50px"
                layout="fixed"
              />
            </Flex>
            <Flex flex={5} fontSize="0.9rem" flexDir={'column'}>
              <Text fontWeight={500}>{data.name}</Text>
              <Text fontWeight={400}>{data.email}</Text>
            </Flex>
          </Flex>
        ),
      }),

      columnHelper.accessor('registrationDate', {
        header: 'REGISTRATION DATE',
        cell: info => info.getValue(),
      }),
      columnHelper.accessor('totalCandidates', {
        header: 'TOTAL CANDIDATES',
        cell: info => info.getValue(),
      }),
      columnHelper.accessor('walletBalance', {
        header: 'WALLET BALANCE',
        cell: ({ row: { original: data } }) => (
          <Text>{data.walletBalance} Units</Text>
        ),
      }),
      columnHelper.display({
        id: 'id',
        header: 'ACTION',
        cell: ({ row: { original: data } }) => (
          <Flex columnGap="1rem">
            <CustomButton
              w="4rem"
              h="1.6rem"
              fontSize="0.75rem"
              p="2px 8px"
              fontWeight={600}
              onClick={() => handleCreditDebitAdmin(data)}
              borderRadius="16px"
              backgroundColor={LEColorConstants.colorCheck}
              color="black"
            >
              Credit
            </CustomButton>

            <CustomButton
              w="4rem"
              h="1.6rem"
              fontSize="0.75rem"
              p="2px 8px"
              fontWeight={600}
              onClick={() => handleCreditDebitAdmin(data)}
              borderRadius="16px"
              backgroundColor={LEColorConstants.secondary}
              color="black"
            >
              Debit
            </CustomButton>
            <CustomButton
              w="4rem"
              h="1.6rem"
              fontSize="0.75rem"
              p="2px 8px"
              fontWeight={600}
              onClick={() => handleViewUser(data)}
              borderRadius="16px"
              backgroundColor={LEColorConstants.greyLighter}
              color="black"
            >
              View
            </CustomButton>
          </Flex>
        ),
      }),
    ],
    []
  )
  return (
    <>
      <Flex
        justifyContent={'space-between'}
        p="1rem 0"
        alignItems={'center'}
        borderBottom="2px solid #E7EAEE"
        gap="1rem"
        flexWrap={'wrap'}
      >
        <Box>
          <Text
            fontSize={{ base: '1.2rem', md: '1.8rem' }}
            fontWeight={600}
            lineHeight="41px"
          >
            Total Admins:{' '}
            <Text as="span" color={LEColorConstants.primary}>
              3889
            </Text>
          </Text>
          <Text
            mb="0.5rem"
            color={LEColorConstants.textLight}
            fontSize={{ base: '1rem', md: '1.1rem' }}
          >
            Manage all your Admin details
          </Text>
        </Box>
        <Flex gap="1rem" flexDirection={'column'}>
          <CustomButton
            variant={'outline'}
            borderRadius="4px"
            h="2.8rem"
            border={`1px solid ${LEColorConstants.textLight}`}
            color="black"
            // onClick={onClick}
            fontSize={'1rem'}
            _hover={{
              border: `1px solid ${LEColorConstants.primary}`,
            }}
          >
            <Icon
              as={AiOutlineDownload}
              fontSize={'1rem'}
              mr="0.5rem"
              // color={whiteBGThemeC[colorMode]}
            />
            Download Report
          </CustomButton>
          <CustomButton
            borderRadius="4px"
            h="2.8rem"
            // onClick={handleSave}
            fontSize={'1rem'}
          >
            <Icon
              as={AiOutlinePlusCircle}
              fontSize={'1rem'}
              mr="0.5rem"
              // color={whiteBGThemeC[colorMode]}
            />
            Add New User
          </CustomButton>
        </Flex>
      </Flex>

      <SearchFilter
        value={value}
        onChange={e => handleChange(e)}
        placeholder="Search by name, email, date and candidate"
        filterElement={
          <Box>
            <Stack direction="row" justifyContent="space-between">
              <Text>WALLET BALANCE</Text>
              <Text cursor="pointer" color={LEColorConstants.secondary}>
                Apply
              </Text>
            </Stack>
            <Stack mt="1rem" direction="row" alignItems="center">
              <Input
                w="7.563rem"
                type="number"
                //ref={firstFieldRef}
              />
              <Icon as={BsDash} />
              <Input w="7.563rem" type="number" />
            </Stack>
            <Text mt="2.188rem">TOTAL CANDIDATES</Text>
            <Stack mt="1rem" direction="row" alignItems="center">
              <Input
                w="7.563rem"
                type="number"
                //ref={firstFieldRef}
              />
              <Icon as={BsDash} />
              <Input w="7.563rem" type="number" />
            </Stack>
          </Box>
        }
      />

      <DataTable
        data={totalAdmin}
        columns={columns}
        variant="lg-selectable"
        isAllowSelection={false}
      />
    </>
  )
}

export default TotalAdminPage
