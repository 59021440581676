import { Tab } from '@chakra-ui/react'

import { tabs } from '@/lib/constants/tabConstants'
const TabData = () => (
  <>
    {tabs.map(data => (
      <Tab
        _first={{
          ml: '2.75rem',
        }}
        key={data.id}
        w="10.25rem"
        _focus={{}}
        _selected={{
          borderBottom: '0.44rem solid #009BFF',
          fontWeight: 'medium',
          fontSize: '1.13rem',
        }}
      >
        {data.tablist}
      </Tab>
    ))}
  </>
)

export default TabData
