import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Image,
  keyframes,
  Box,
} from '@chakra-ui/react'

interface ILoader {
  isLoading: boolean
}

const animationKeyframes = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`

const animation = `${animationKeyframes} 0.5s infinite linear`

export default function Loader({ isLoading }: ILoader) {
  return (
    <>
      <Modal
        isOpen={isLoading}
        onClose={() => {}}
        closeOnOverlayClick={false}
        closeOnEsc={false}
        size="full"
      >
        <ModalOverlay />
        <ModalContent bg="transparent">
          <ModalBody
            display="flex"
            flex={1}
            justifyContent="center"
            alignItems="center"
          >
            <Box w="48px" h="48px">
              <Image
                src="/assets/logo/logo-secondary-48x48.png"
                boxSize={'full'}
                objectFit="contain"
                animation={animation}
              />
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}
