import React, { useState, useCallback, useMemo } from 'react'
import { ChevronDownIcon } from '@chakra-ui/icons'
import {
  Box,
  ColorMode,
  Flex,
  Heading,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useColorMode,
  useDisclosure,
} from '@chakra-ui/react'
import { TbCurrencyNaira } from 'react-icons/tb'
import { BsCheck2Circle } from 'react-icons/bs'
import { useFlutterwave, closePaymentModal } from 'flutterwave-react-v3'
import { usePaystackPayment } from 'react-paystack'

import formData from '@/lib/constants/formData/unit'
import useForm from '@/hooks/useForm'
import HeaderTab from '@/components/HeaderTab'
import { TextInput } from '@/components/Input'
import {
  basicBgColorRight,
  bgThemeColor,
  brandPrimayColor,
  secondaryThemeColor,
} from '@/lib/constants/colorConstants'
import CustomImage from '@/components/Image'
import { conversionKeys } from '@/lib/constants/conversionKeys'
import CustomButton from '@/components/Button'
import { useWallet } from '../Dashboard/hooks/useWallet'
import { useStores } from '@/stores/index'
import {
  axiosInstance,
  handleErrorResponse,
  handleSuccessResponse,
  ngnCurrencyFormatter,
} from '@/lib/helpers'
import { FlutterwaveConfig } from 'flutterwave-react-v3/dist/types'
import { APP_NAME } from '@/lib/constants/appConstants'
import Loader from '@/components/Loader/Loader'

const PAYMENT = [
  {
    id: 1,
    name: 'paystack',
    src: '/',
    img: '/paystack.svg',
  },
  {
    id: 2,
    name: 'flutterwave',
    src: '/',
    img: '/flutterwave.svg',
  },
]

const BuyUnitPage = () => {
  const { colorMode } = useColorMode()
  const { isOpen, onClose, onOpen } = useDisclosure()
  const { config, auth } = useStores()
  const { wallet, refetchWallet } = useWallet()

  const [selectedPackage, setSelectedPacked] = useState<any>()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [formType, setFormType] = useState<string>(conversionKeys.amount)
  const [conversionValues, setConversionValues] = useState<number | null>(null)
  const [conversionMainKey] = useState<string[]>(Object.values(conversionKeys))

  const flutterwavePaymentConfig: FlutterwaveConfig = useMemo(
    () => ({
      public_key: process.env.NEXT_PUBLIC_FLUTTERWAVE_PUBLIC_KEY as string,
      tx_ref: Date.now() as unknown as string,
      amount: selectedPackage?.amount_in_ngn as number,
      currency: 'NGN',
      payment_options: 'card,mobilemoney,ussd',
      customer: {
        email: auth.currentAdmin?.email as string,
        phone_number: auth.currentAdmin?.phone as string,
        name: auth.currentAdmin?.name as string,
      },
      customizations: {
        title: APP_NAME + ' - Buy Unit',
        description: "You're about to buy a unit",
        logo: 'https://testnotion.com/assets/logo/logo-secondary-48x48.png',
      },
    }),
    [selectedPackage]
  )

  const paystackPaymentConfig = useMemo(
    () => ({
      reference: new Date().getTime().toString(),
      email: auth.currentAdmin?.email as string,
      amount: (selectedPackage?.amount_in_ngn * 100) as number, //in kobo
      publicKey: process.env.NEXT_PUBLIC_PAYSTACK_PUBLIC_KEY as string,
    }),
    [selectedPackage]
  )

  const verifyPayment = async (paymentGateway: string, ref: string) => {
    try {
      setIsLoading(true)

      closePaymentModal()
      const { data } = await axiosInstance.get(
        `/organization/payment/${ref}/verify?special_package=${selectedPackage?.amount_in_ngn}&payment_gateway=${paymentGateway}`
      )
      refetchWallet()
      handleSuccessResponse(data.message)
    } catch (err) {
      handleErrorResponse(err)
    } finally {
      setIsLoading(false)
    }
  }

  const handleFlutterPayment = useFlutterwave(flutterwavePaymentConfig)
  const handlePaystackPayment = usePaystackPayment(paystackPaymentConfig)

  const handlePaymentSubmit = useCallback(
    paymentType => {
      if (!paymentType) return

      if (paymentType.name === 'flutterwave') {
        handleFlutterPayment({
          callback: async response => {
            verifyPayment(
              paymentType.name,
              response?.transaction_id as unknown as string
            )
          },
          onClose: () => {},
        })
      }

      if (paymentType.name === 'paystack') {
        handlePaystackPayment(
          // @ts-ignore
          res => {
            verifyPayment(paymentType.name, res.reference)
          },
          () => {}
        )
      }

      onClose()
    },
    [handleFlutterPayment, handlePaystackPayment]
  )

  const { handleChange, inputTypes, handleSubmit, errors } = useForm({
    inputs: formData,
    cb: async inputs => {
      onOpen()
    },
  })

  const handlePaymentPackage = (selectedPackage: any) => {
    setSelectedPacked(selectedPackage)
    onOpen()
  }

  const handleChangeConversionKey = (e: any) => {
    const formType = e.target.textContent
    setFormType(formType)

    if (selectedPackage) {
      // re-calculate selectedPackage based on formType.
      const { unit, amount_in_ngn } = selectedPackage

      if (formType === conversionKeys.unit) {
        setSelectedPacked({
          unit: Number(conversionValues),
          amount_in_ngn:
            Number(conversionValues) * config.getUnits().unit_rate_to_ngn,
        })
      }

      if (formType === conversionKeys.amount) {
        setSelectedPacked({
          unit: Number(conversionValues) / config.getUnits().unit_rate_to_ngn,
          amount_in_ngn: Number(conversionValues),
        })
      }
    }
  }

  return (
    <>
      <Box bgColor={bgThemeColor[colorMode]}>
        <HeaderTab>
          <Flex w="full" justifyContent="space-between" alignItems="center">
            <Text
              fontSize={{ base: '0.75rem', md: '1.125rem' }}
              fontWeight="medium"
              color={brandPrimayColor[colorMode]}
            >
              Purchase Unit
            </Text>
            <Box
              p="0.75rem"
              h="2.688rem"
              display="flex"
              justifyContent="center"
              alignItems="center"
              borderRadius="5px"
              fontSize={{ base: '0.75rem', md: '1.125rem' }}
              fontWeight="semibold"
              color="white"
              bgColor={secondaryThemeColor[colorMode]}
            >
              Unit Bal: {wallet.balance}
            </Box>
          </Flex>
        </HeaderTab>

        <Box mt="1.75rem" px="2.412rem" pb="2.412rem">
          <Text>
            Purchasing Units outside the packages:{' '}
            <Text
              as="span"
              fontSize="1rem"
              fontWeight="medium"
              color={secondaryThemeColor[colorMode]}
            >
              1 Unit cost{' '}
              {ngnCurrencyFormatter(config.getUnits().unit_rate_to_ngn)}
            </Text>
          </Text>

          <Text mt="1.625rem" fontSize="1rem" fontWeight="semibold">
            Manual purchase
          </Text>
          <Flex
            mt="1rem"
            maxW="container.md"
            justifyContent="space-between"
            flexDir={['column', 'row', 'row', 'row']}
          >
            <Box w="full">
              <Box>
                <Menu>
                  <MenuButton>
                    <Text fontSize="1rem" fontWeight="medium">
                      {formType === conversionKeys.amount
                        ? `${conversionKeys.amount}(N)`
                        : conversionKeys.unit}
                      <Icon as={ChevronDownIcon} />
                    </Text>
                  </MenuButton>
                  <MenuList onClick={e => handleChangeConversionKey(e)}>
                    {conversionMainKey.map(data => (
                      <MenuItem key={`conversion_key_${data}`}>{data}</MenuItem>
                    ))}
                  </MenuList>
                </Menu>
              </Box>
              <form action="post">
                {formData.map(data => (
                  <Box key={`conversion_input_${data.name}`}>
                    <Box maxW="full">
                      <TextInput
                        type={data.type}
                        name={data.name}
                        placeholder={
                          formType === conversionKeys.amount
                            ? data.label
                            : 'Unit'
                        }
                        value={inputTypes[data.name]}
                        handleChange={val => {
                          let event: any = {}
                          event.target = {
                            name: data.name,
                            value: val.target.value,
                          }
                          handleChange(event)

                          setSelectedPacked({
                            unit:
                              formType === conversionKeys.amount
                                ? val.target.value /
                                  config.getUnits().unit_rate_to_ngn
                                : val.target.value,
                            amount_in_ngn:
                              formType === conversionKeys.amount
                                ? val.target.value
                                : val.target.value *
                                  config.getUnits().unit_rate_to_ngn,
                          })

                          setConversionValues(val.target.value)
                        }}
                        error={errors[data.name] && data.errorMessage}
                      />
                    </Box>
                  </Box>
                ))}
              </form>
            </Box>
            <Box
              fontSize={'2rem'}
              fontWeight={600}
              m="1.2rem"
              display={['none', 'block', 'block', 'block']}
            >
              =
            </Box>

            <Box w="full" opacity={0.5} mt={['1.5rem', '0', '0', '0']}>
              <Text fontSize="1rem" fontWeight="medium">
                {formType === conversionKeys.amount
                  ? conversionKeys.unit
                  : `${conversionKeys.amount}(N)`}
              </Text>
              <TextInput
                type="number"
                value={
                  formType === conversionKeys.amount
                    ? (
                        Number(conversionValues) /
                        config.getUnits().unit_rate_to_ngn
                      ).toFixed(2)
                    : Number(conversionValues) *
                      config.getUnits().unit_rate_to_ngn
                }
                handleChange={handleChange}
                TextInputProps={{
                  isReadOnly: true,
                }}
              />
            </Box>
            <CustomButton
              mx="1rem"
              mt="1.5rem"
              w="8.125rem"
              h="2.5rem"
              fontSize="1.125rem"
              fontWeight="semibold"
              borderRadius="5px"
              onClick={handleSubmit}
            >
              Pay
            </CustomButton>
          </Flex>

          <Text mt="1.938rem" fontSize="1rem" fontWeight="semibold">
            Special Packages
          </Text>
          <Stack mt="1rem" mb="3rem" spacing={4}>
            {config.getUnits().special_packages &&
              config.getUnits().special_packages.length > 0 &&
              config.getUnits().special_packages.map(data => (
                <Box
                  key={`unit_pacakge_${data.unit}`}
                  maxW="container.md"
                  h="4.063rem"
                  p="0.5rem"
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  cursor="pointer"
                  transition="0.3s ease-in"
                  border="1px"
                  borderRadius="5px"
                  borderColor={
                    selectedPackage?.unit === data.unit
                      ? brandPrimayColor[colorMode]
                      : basicBgColorRight[colorMode]
                  }
                  _hover={{
                    borderColor: brandPrimayColor[colorMode],
                  }}
                  onClick={() => handlePaymentPackage(data)}
                >
                  <Text fontSize="1rem" fontWeight="medium">
                    {data.unit} units
                  </Text>
                  <Box
                    display="flex"
                    flexDirection="column"
                    color={brandPrimayColor[colorMode]}
                  >
                    {selectedPackage?.unit === data.unit && (
                      <Icon ml="auto" as={BsCheck2Circle} />
                    )}
                    <Text fontSize="1rem" fontWeight="semibold">
                      {ngnCurrencyFormatter(data.amount_in_ngn)}
                    </Text>
                  </Box>
                </Box>
              ))}
          </Stack>
        </Box>
      </Box>
      <PaymentModal
        isOpen={isOpen}
        onClose={onClose}
        colorMode={colorMode}
        paymentType={PAYMENT}
        paymentObject={selectedPackage as any}
        handlePayment={handlePaymentSubmit}
      />
      <Loader isLoading={isLoading} />
    </>
  )
}

type PaymentProps = {
  id: number
  name: string
  src: string
  img: string
}

const modalSize = {
  base: '300px',
  sm: '300px',
  md: '686px',
}

const PaymentModal = ({
  isOpen,
  onClose,
  colorMode,
  paymentObject,
  paymentType,
  handlePayment,
}: {
  isOpen: boolean
  onClose: () => void
  colorMode: ColorMode
  paymentObject: {
    unit: number
    amount_in_ngn: number
  }
  paymentType: PaymentProps[]
  handlePayment: (paymentType: any) => void
}) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        handlePayment(null)
        onClose()
      }}
      isCentered
    >
      <ModalOverlay />
      <ModalContent maxW={modalSize} h={{ base: '530px', md: '357px' }}>
        <ModalCloseButton />
        <Heading
          mt="4.688rem"
          as="h3"
          fontSize="1rem"
          fontWeight="medium"
          textAlign="center"
        >
          Purchase {paymentObject?.unit} Units for{' '}
          {ngnCurrencyFormatter(paymentObject?.amount_in_ngn)}?
        </Heading>
        <Heading
          mt="1.813rem"
          as="h3"
          fontSize="1.25rem"
          fontWeight="semibold"
          textAlign="center"
        >
          Select Payment Gateway
        </Heading>
        <ModalBody>
          <Flex
            mt="1.813rem"
            justifyContent="center"
            alignItems="center"
            flexDirection={{ base: 'column', md: 'row' }}
            gap={8}
          >
            {paymentType.map(data => (
              <Box
                key={`payment_type_${data.id}`}
                p="0.5rem"
                w="12.5rem"
                h="5.813rem"
                display="flex"
                justifyContent="center"
                alignItems="center"
                border="1px"
                cursor="pointer"
                transition="0.2s ease-in"
                borderRadius="5px"
                borderColor={secondaryThemeColor[colorMode]}
                _hover={{
                  color: 'white',
                  bgColor: secondaryThemeColor[colorMode],
                }}
                onClick={() => {
                  handlePayment(data)
                  close()
                }}
              >
                <CustomImage
                  width="60px"
                  height="60px"
                  src={data.img}
                  alt="payment type"
                />
                <Text ml="0.5rem" textTransform="capitalize">
                  {data.name}
                </Text>
              </Box>
            ))}
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default BuyUnitPage
