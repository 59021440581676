import { UseMutateFunction, useMutation, useQueryClient } from 'react-query'
import { useToast } from '@chakra-ui/react'

import { axiosInstance } from '@/lib/helpers'
import { queryKeys } from '@/lib/react-query/constants'

// for when server call is needed
async function deleteUser(user_id: string): Promise<void> {
  await axiosInstance.delete(`/organization/admin/${user_id}`)
}

export function useDeleteUser(): UseMutateFunction<
  void,
  unknown,
  string,
  unknown
> {
  const queryClient = useQueryClient()
  const toast = useToast()

  const { mutate } = useMutation(deleteUser, {
    onSuccess: () => {
      queryClient.invalidateQueries([queryKeys.users])
      toast({
        title: 'You have successfully deleted the user!',
        status: 'warning',
      })
    },
  })

  return mutate
}
