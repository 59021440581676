export const queryKeys = {
  classArms: 'classArms',
  classes: 'classes',
  questionBanks: 'questionBanks',
  questions: 'questions',
  rolesAndPermissions: 'rolesAndPermissions',
  deleteQuestion: 'deleteQuestion',
  questionCategories: 'questionCategories',
  candidateGroups: 'candidateGroups',
  questionGroups: 'questionGroups',
  createCandidate: 'createCandidate',
  candidates: 'candidates',
  users: 'users',
  wallet: 'wallet',
  orgStatistic: 'orgStatistic',
  candidateExamResults: 'candidateExamResults',
  exams: 'exams',
  results: 'results',
  examOngoingCandidates: 'examOngoingCandidates',
  examsCandidate: 'examsCandidate',
  examsAdmin: 'examsAdmin',
  examQuestions: 'examQuestions',
  activities: 'activities',
  transactions: 'transactions',
}
