import { SideBarOptionMenu } from '@/types/sidebar'
import { AiOutlineHome } from 'react-icons/ai'

export const appMenu: SideBarOptionMenu = {
  name: 'Dashboard',
  icon: AiOutlineHome,
  path: '/dashboard',
  order: 1,
  permission: { name: 'dashboard', can: 'visible' },
  isShown: true,
  isActive: false,
  child: [],
}
